import React, { useEffect, useState } from "react";
import { Interweave } from "interweave";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { single_sow } from "../../utils";
import { dataToken } from "../config/token";
import { useParams } from "react-router-dom";
import { CardMedia } from "@mui/material";

function SowDetails(props) {
  const [single, setSingle] = useState({});

  const params = useParams();

  useEffect(() => {
    axios
      .get(
        single_sow + `?sow_id=${props.flag ? params.id : props.sow_id}`,
        dataToken
      )
      .then((res) => {
        setSingle(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <div
        className="add_page"
        style={{
          textAlign: "center",
          margin: "10px",
        }}
      >
        <p
          className="text-capatilize"
          style={{
            textTransform: "uppercase",
            fontSize: "24px",
            fontWeight: "400",
            borderBottom: "1px solid lightgrey",
            margin: "10px 238px",
            paddingBottom: "10px",
          }}
        >
          <img
            src={props?.logo}
            alt="profile"
            style={{ marginRight: "25px", width: "60px", height: "60px" }}
          />
          {props.brand}
        </p>
        <Row className="model_row_sow">
          <Col md={2}>
            <h6 className="model_sow1">project ID</h6>
            <h3 className="model_sow2">{single.project_id}</h3>
          </Col>
          <Col md={2}>
            <h6 className="model_sow1">app view</h6>
            <h3 className="model_sow2">{single.app_view_status}</h3>
          </Col>
          <Col md={2}>
            <h6 className="model_sow1">Sow Type</h6>
            <h3 className="model_sow2">
              {single.type == "0"
                ? "Select Type"
                : single.type == "1"
                ? "Merchant Acquisition"
                : single.type == "2"
                ? "Partner Onboarding"
                : single.type == "3"
                ? "Finance"
                : single.type == "4"
                ? "Seller Onboarding"
                : single.type == "5"
                ? "Customer Acquisition"
                : single.type == "6"
                ? "Data Moderation"
                : single.type == "7"
                ? "TeleCalling"
                : ""}
            </h3>
          </Col>
          <Col md={2}>
            <h6 className="model_sow1">Start Date</h6>
            <h3 className="model_sow2">{single.start_date}</h3>
          </Col>
          <Col md={2}>
            <h6 className="model_sow1">End Date</h6>
            <h3 className="model_sow2">{single.end_date}</h3>
          </Col>
          <Col md={2}>
            <h6 className="model_sow1">Vertical</h6>
            <h3 className="model_sow2" style={{ textTransform: "uppercase" }}>
              {single.job_category?.replace("_", "")}
            </h3>
          </Col>
        </Row>
        <div
          className="model_sow_div"
          style={{ margin: "50px auto", display: "flex", width: "60%" }}
        >
          <CardMedia
            component="iframe"
            autoPlay
            controls
            src={single.training_video}
            alt=""
            width={"700px"}
            height={350}
            className="imgs_css"
            style={{ margin: "auto" }}
          />
        </div>
        <div className="model_sow_div">
          <h5 className="model_header_sow">Introduction Text</h5>
          <p className="m-2 mt-4">
            <Interweave content={single.introduction_text} />
          </p>
        </div>

        <div className="model_sow_div">
          <h5 className="model_header_sow">Whom to sell?</h5>
          <p className="m-2 mt-4">
            <Interweave content={single.project_desc} />
          </p>
        </div>
        <div className="model_sow_div">
          <h5 className="model_header_sow">How to sell?</h5>
          <p className="m-2 mt-4">
            <Interweave content={single.payout_criteria} />
          </p>
        </div>
        <div className="model_sow_div">
          <h5 className="model_header_sow">Terms and conditions</h5>
          <p className="m-2 mt-4">
            <Interweave content={single.other_terms} />
          </p>
        </div>

        <button
          style={{ float: "right" }}
          onClick={() => props.setShow(false)}
          className="button_submit1 mt-5"
        >
          Understood
        </button>
      </div>
    </div>
  );
}

export default SowDetails;
