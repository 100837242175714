import React, { useState, useEffect } from "react";
import "../css/AddSow.css";
import { Col, Row, Form, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { dataToken } from "../config/token";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { crm_access, oa_details } from "../../utils";

const CrmAccess = ({ pid }) => {
  const [data, setData] = React.useState({});
  const [userId, setUserId] = useState(undefined);
  const [typeData, setTypeData] = React.useState(undefined);
  const [helpText, setHelpText] = React.useState(false);
  const [checkEmail, setCheckEmail] = React.useState(false);
  const EMAIL_REGEX = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/
  );
  const Mobile_Regex = new RegExp(/^[0]?[56789]\d{9}$/);
  const handleChangeType = (e) => {
    setTypeData(e.target.value);
    data.user_type = e.target.value;
  };
  const handlesubmit = (e) => {
    e.preventDefault();

    data.project_id = pid;
    data.created_by = userId;

    axios
      .post(crm_access, data, dataToken)
      .then((res) => {
        if (res.data.error) {
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: `${res.data.message}`,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          Swal.fire({
            position: "center",
            icon: "success",
            title: `success`,
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: `${err.response.data.message}`,
        });
      });
  };
  useEffect(() => {
    axios.get(oa_details, dataToken).then((res) => {
      setUserId(res.data.data.id);
    });
  }, []);
  return (
    <div>
      <span className="add_heading">Crm Access</span>
      <Form onSubmit={handlesubmit}>
        <Row className="mt-5">
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                fullWidth
                required
                error={data?.full_name?.length === 0 ? true : false}
                value={data.full_name}
                helperText={
                  data?.full_name?.length === 0 ? "Name is required" : null
                }
                onChange={(e) => {
                  data.full_name = e.target.value;

                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <TextField
                id="outlined-basic"
                label="Mobile Number"
                value={data.mobile_number}
                error={!Mobile_Regex.test(data.mobile_number) ? true : false}
                helperText={
                  !Mobile_Regex.test(data.mobile_number)
                    ? "Enter a valid Mobile Number"
                    : ""
                }
                variant="outlined"
                fullWidth
                required
                onChange={(e) => {
                  data.mobile_number = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                fullWidth
                type="email"
                error={!EMAIL_REGEX.test(data.email_id) ? true : false}
                helperText={
                  !EMAIL_REGEX.test(data.email_id) ? "Enter a valid email" : ""
                }
                required
                value={data.email_id}
                onChange={(e) => {
                  data.email_id = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={typeData}
                  label="Type"
                  required
                  onChange={handleChangeType}
                >
                  <MenuItem value={"client"}>client</MenuItem>
                  <MenuItem value={"admin"}>admin</MenuItem>
                </Select>
              </FormControl>
            </Form.Group>
          </Col>
        </Row>
        <Row className="d-flex justify-content-end">
          <Button
            className={"button_submit"}
            type="submit"
            style={{ marginRight: "20px" }}
          >
            Submit
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default CrmAccess;
