import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Modal from "react-bootstrap/Modal";
//from editsow.js page
import "../css/AddSow.css";
import { Col, Row, Form, Button } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FeatherIcon from "feather-icons-react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Card from "react-bootstrap/Card";
import Stories from "react-insta-stories";
import { CardBody, CardTitle } from "reactstrap";
import { UncontrolledCarousel } from "reactstrap";
import {
  app_training_video,
  current_month_sow,
  edit_sow,
  single_sow,
  sow_media_upload,
  update_media_sow,
  sow_qc_note,
  create_qc_note,
  get_sow_qc_notes,
  sow_qc_script,
} from "../../utils";
import axios from "axios";
import Swal from "sweetalert2";
import { dataToken, token } from "../config/token";
import { useParams } from "react-router-dom";
import AddImage from "./AddImage";
import { Interweave } from "interweave";
import { getByDisplayValue } from "@testing-library/react";
import { Button as Mbutton } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EditSowPage = () => {
  const [value, setValue] = React.useState(0);
  const [single, setSingle] = React.useState({});
  const [data, setData] = React.useState({});
  const [show, setShow] = React.useState(false);
  const [show1, setShow1] = React.useState(false);
  const [mediaId, setMediaid] = React.useState("");
  const [customerMedia, setCustomerMedia] = React.useState([]);
  const { id3: sowId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);

  const [sowqc, setSowqc] = React.useState([]);
  const [imgkey, setImgkey] = React.useState("");
  const [intrtext, setIntrtext] = React.useState("");

  const [script, setScript] = React.useState("");
  const [getsowqc, setGetsowqc] = React.useState([]);

  const [subqc, setSubQc] = React.useState(false);
  const [editqc, setEditQc] = React.useState(false);
  const [sowscript, setSowscript] = React.useState("");
  const [id, setId] = React.useState();
  const [editscript, setEditScript] = React.useState(sowscript);
  const [edited, setEdited] = React.useState(false);
  // console.log(sowId, "idddd");
  // console.log(sowqc, "sowqcccc");
  // console.log(intrtext, "textttt");
  console.log(sowscript, "setttttt");
  console.log(editscript, "edittt");
  console.log(sowscript === editscript, "boolean");
  // console.log(getsowqc, "sowqccc222");
  const handleSowDetails = (e) => {
    setShow(true);
  };

  const handleClickOpen = (a) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleOpen3 = () => {
    setOpen3(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
    setScript("");
  };

  const handleClose3 = () => {
    setOpen3(false);
  };
  const handleNo = () => {};
  const handleYes = (id) => {
    // console.log(id, "id");
    const patchData = {
      media_id: mediaId,
      status: "inactive",
    };
    axios
      .patch(update_media_sow, patchData, dataToken)
      .then((res) => {
        Swal.fire({
          title: "Deleted",
          icon: "success",
        });
        window.location.reload();
      })
      .catch((e) => {
        Swal.fire({
          title: "Oops something went wrong..!",
          icon: "warning",
        });
      });
  };
  React.useEffect(() => {
    const data = {
      sow_id: sowId,
    };
    setData({ ...data });
    if (sowId > 0) {
      axios
        .get(single_sow + `?sow_id=${sowId}`, dataToken)
        .then((res) => {
          const dataSample = res.data.data;
          setSingle(dataSample);
          let dataPush = [];
          if (dataSample?.customer.length > 0) {
            for (let i = 0; i < dataSample.customer.length; i++) {
              // dataPush.push({
              //   url: dataSample.customer[i].media_image,
              //   header: {
              //     heading: dataSample.customer[i].created_on,
              //   },
              //   title: dataSample.customer[i].title,
              //   seeMore: ({ close }) => {
              //     return (
              //       <div onClick={close}>
              //         <div className="customer_desc">
              //           <div className="customer_box">
              //             {dataSample.customer[i].description}
              //           </div>
              //         </div>
              //       </div>
              //     );
              //   },
              // });
              dataPush.push({
                header: " ",
                altText: `${dataSample.customer[i].title}`,
                caption: `${dataSample.customer[i].description}`,
                key: `${i}+1`,
                src: dataSample.customer[i].media_image,
                media_id: dataSample.customer[i].media_id,
              });
            }
            setCustomerMedia(dataPush);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  React.useEffect(() => {
    axios
      .get(sow_qc_note + `?sow_id=${sowId}`, dataToken)
      .then((res) => setSowqc(res.data.selectQC))
      .catch((err) => console.log(err));
  }, [subqc]);

  const getSowqcnotes = () => {
    axios
      .get(get_sow_qc_notes + `?sow_id=${sowId}`, dataToken)
      .then((res) => {
        console.log(res.data, "resting");
        setGetsowqc(res?.data?.script);
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    console.log(get_sow_qc_notes + `?sow_id=${sowId}`, "test");

    getSowqcnotes();
  }, [editqc]);

  const fileUpload = (e) => {
    const fileSelect = document.getElementById("resumeSel"),
      fileElem = document.getElementById("resumeEle");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem) {
          fileElem.click();
        }
      },
      false
    );
  };
  const fileUpload1 = (e) => {
    const fileSelect = document.getElementById("resumeSel1"),
      fileElem = document.getElementById("resumeEle1");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem) {
          fileElem.click();
        }
      },
      false
    );
  };

  const imageUpload = (e, type) => {
    const axiosData = new FormData();
    axiosData.append("file", e.target.files[0]);
    axiosData.append("sow_id", sowId);
    axiosData.append("type", type);
    axiosData.append("description", "header_img");
    axiosData.append("link_type", "image");
    axiosData.append("title", "header_img");
    const files = e.target.files;
    let output = "";

    for (const file of files) {
      output += `${file.type || "unknown"}\n`;
    }
    axios
      .post(sow_media_upload, axiosData, dataToken)
      .then((res) => {
        console.log(res.data.results.url);
        const datapush = { media_image: res.data.results.url };
        if (type == "header") {
          single.header.push({ ...datapush });
        } else if (type == "share_media") {
          single.media.push({ ...datapush });
        } else if (type == "customer_media") {
          single.customer.push({ ...datapush });
        }
        setSingle({ ...single });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handlesubmit = (event) => {
    event.preventDefault();

    axios
      .patch(edit_sow, data, dataToken)
      .then((res) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `success`,
          showConfirmButton: false,
          timer: 1500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })

      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: `${err.response.data.message}`,
        });
      });
  };
  const hiddenFileInput = React.useRef(null);
  const handleClickuploadtraining = () => {
    hiddenFileInput.current.click();
  };
  const handleChangeuploadtraining = (e) => {
    // console.log(e.target.files[0], "files000");
    var formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("sow_id", sowId);
    axios
      .post(app_training_video, formData, dataToken)
      .then((res) => {
        // console.log(res);
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  const handleCreateqc = () => {
    const path = create_qc_note;
    const body = {};
    body.sow_id = sowId;
    body.script = script;
    body.image_key = imgkey;
    console.log(body, "bodycreateqc");
    axios
      .post(path, body, dataToken)
      .then((res) => {
        console.log(res.data, "createqc");
        setEditQc(!editqc);
        setSubQc(!subqc);
        handleClose2();
        Swal.fire({
          title: "Submitted successfully",
          icon: "success",
        });
      })
      .catch((err) =>
        Swal.fire({
          title: "Something went wrong",
          icon: "warning",
        })
      );
  };

  const update = () => {
    const body = {};

    body.id = id;
    body.script = editscript;
    console.log(body, "checkkkk");
    axios
      .put(sow_qc_script, body, dataToken)
      .then((res) => {
        console.log(res.data);
        setEditQc(!editqc);
        handleClose3();
        Swal.fire({
          title: "Edited successfully",
          icon: "success",
        });
      })
      .catch((err) =>
        Swal.fire({
          title: "Something went wrong",
          icon: "warning",
        })
      );
  };

  const handleUpdateqc = () => {
    update();
  };

  const remove = (id) => {
    const body = {};
    body.id = id;
    body.status = "inactive";
    console.log(body, "checkkremove");
    axios
      .put(sow_qc_script, body, dataToken)
      .then((res) => {
        console.log(res, "res.data");

        setEditQc(!editqc);
        setSubQc(!subqc);
        Swal.fire({
          title: "Deleted successfully",
          icon: "success",
        });
      })
      .catch((err) =>
        Swal.fire({
          title: "Something is wrong",
          icon: "warning",
        })
      );
  };

  const handleDelete = (id) => {
    remove(id);
  };

  const handleEdit = () => {
    handleOpen3();
  };

  const type = localStorage.getItem("type");
  console.log(type, "1234");

  return (
    <Box sx={{ width: "100%", textTransform: "initial" }}>
      <Form onSubmit={handlesubmit}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Tasker Info" {...a11yProps(0)} />
            <Tab label="Tasker Process" {...a11yProps(1)} />
            <Tab label="Customer Process" {...a11yProps(2)} />

            <Tab label="Image Description" {...a11yProps(3)} />
            <Tab label="QC Script" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div className="allDetailsEditSowPage">
            {/* <label htmlFor="ControlInputAdd" className="label_edit_sow">
              Header Images
            </label> */}
            <Row className="scroll_row d-flex justify-content-center m-2">
              {single?.header?.length < 1 ? (
                <Col sx={12} md={7}>
                  <Form.Group className="mb-4" controlId="ControlInput">
                    <input
                      type="file"
                      id="resumeEle"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        imageUpload(e, "header");
                      }}
                    />
                    <div
                      className="upload_css"
                      id="resumeSel"
                      onClick={() => {
                        fileUpload();
                      }}
                    >
                      <FeatherIcon
                        icon="upload-cloud"
                        style={{ color: "#6C58FB" }}
                        size={40}
                      />
                      <span>Upload Header Image</span>
                    </div>
                  </Form.Group>
                </Col>
              ) : (
                <></>
              )}
              {single?.header
                ?.map((res) => {
                  return (
                    <Col sx={12} md={6}>
                      <Form.Group className="mb-4" controlId="ControlInput">
                        <div className="total_delete">
                          <div
                            className="delete_icon"
                            onClick={() => {
                              handleClickOpen();
                              setMediaid(res.media_id);
                            }}
                            style={{
                              right: "0",
                              marginRight: "-22%",
                              marginTop: "25px",
                            }}
                          >
                            <FeatherIcon
                              icon="trash"
                              style={{ color: "white" }}
                              size={15}
                            />
                          </div>
                          <img
                            src={res.media_image}
                            alt=""
                            height={377}
                            width={720}
                            className="imgs_css"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  );
                })
                .reverse()}
            </Row>
            <Row>
              <Col sx={12} md={6}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>Project Title</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={single.project_title}
                    onChange={(e) => {
                      data.project_title = e.target.value;
                      setData({ ...data });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col sx={12} md={6}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>Vertical</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    size="lg"
                    onChange={(e) => {
                      data.job_category = e.target.value;
                      setData({ ...data });
                    }}
                    style={{ textTransform: "uppercase" }}
                    className={"drop_css"}
                    defaultValue={single.job_category}
                  >
                    <option value={single.job_category}>
                      {single.job_category?.replace("_", "")}
                    </option>
                    <option value="hyp_fot">HYPFOT</option>
                    <option value="hyp_dat">HYPDAT</option>
                    <option value="dig_cxt">DIGCXT</option>
                    <option value="dig_mot">DIGMOT</option>
                    <option value="hyp_vet">HYPVET</option>
                    <option value="dig_ret">DIGRET</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sx={12} md={6}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    defaultValue={single.start_date}
                    onChange={(e) => {
                      data.start_date = e.target.value;
                      setData({ ...data });
                    }}
                    required
                  />
                </Form.Group>
              </Col>
              <Col sx={12} md={6}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>End Date</Form.Label>
                  {/*<Form.Control
                type="text"
                defaultValue={single.end_date}
                onChange={(e) => {
                  data.end_date = e.target.value;
                  setData({ ...data });
                }}
              />*/}
                  <Form.Control
                    type="date"
                    defaultValue={single.end_date}
                    onChange={(e) => {
                      data.end_date = e.target.value;
                      setData({ ...data });
                    }}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sx={12} md={6}>
                {/* <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>QC Number</Form.Label>

              <Form.Control
                type="text"
                // placeholder={single.end_date}
                defaultValue={single.qc_number}
                onChange={(e) => {
                  data.qc_number = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group> */}
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>Trending</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    size="lg"
                    onChange={(e) => {
                      data.is_trending = e.target.value;
                      setData({ ...data });
                    }}
                    className={"drop_css"}
                    defaultValue={single.is_trending}
                  >
                    <option value={single.is_trending}>
                      {single.is_trending}
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sx={12} md={6}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>Payout Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    size="lg"
                    onChange={(e) => {
                      data.pay_type = e.target.value;
                      setData({ ...data });
                    }}
                    className={"drop_css"}
                  >
                    <option
                      value={single.pay_type}
                      style={{ textTransform: "capitalize" }}
                    >
                      {single.pay_type}
                    </option>
                    <option value="weekly">Weekly</option>
                    <option value="fortnightly">Fort Nightly</option>
                    <option value="monthly">Monthly</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sx={12} md={6}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>CPL</Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder={single.end_date}
                    defaultValue={single.xleads}
                    // onChange={(e) => {
                    //   data.xleads = e.target.value;
                    //   setData({ ...data });
                    // }}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col sx={12} md={6}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>Enable Status</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    size="lg"
                    onChange={(e) => {
                      data.enable_for = e.target.value;
                      setData({ ...data });
                    }}
                    className={"drop_css"}
                    required
                    defaultValue={single.enable_for}
                  >
                    <option
                      value={single.enable_for}
                      style={{ textTransform: "capitalize" }}
                    >
                      {single.enable_for}
                    </option>
                    <option value="">None</option>
                    <option value="open_network">Open Network</option>
                    <option value="closed_network">Close Network</option>
                    {/* <option value="both">Both</option> */}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {/* <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Category</Form.Label>
              <Form.Select
                aria-label="Default select example"
                size="lg"
                onChange={(e) => {
                  data.vertical = e.target.value;
                  setData({ ...data });
                }}
                className={"drop_css"}
                required
              >
                <option
                  value={single.vertical}
                  style={{ textTransform: "capitalize" }}
                  disabled
                >
                  {single.vertical}
                </option>
                <option value="field">Field</option>
                <option value="remote">Remote</option>
              </Form.Select>
            </Form.Group>
          </Col> */}

              <Col sx={12} md={6}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>Active Sow Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    size="lg"
                    onChange={(e) => {
                      data.type = e.target.value;
                      setData({ ...data });
                    }}
                    className={"drop_css"}
                    defaultValue={single.type}
                  >
                    <option value="">
                      {single.type == "0" ? "Select Type" : ""}
                      {single.type == "1" ? "Merchant Acquisition" : ""}
                      {single.type == "2" ? "Partner Onboarding" : ""}
                      {single.type == "3" ? "Finance" : ""}
                      {single.type == "4" ? "Seller Onboarding" : ""}
                      {single.type == "5" ? "Customer Acquisition" : ""}
                      {single.type == "6" ? "Data Moderation" : ""}
                      {single.type == "7" ? "TeleCalling" : ""}
                    </option>
                    <option value="1">Merchant Acquisition</option>
                    <option value="2">Partner Onboarding</option>
                    <option value="3">Finance</option>
                    <option value="4">Seller Onboarding</option>
                    <option value="5">Customer Acquisition</option>
                    <option value="6">Data Moderation</option>
                    <option value="7">TeleCalling</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sx={12} md={6}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>App View Status</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    size="lg"
                    onChange={(e) => {
                      data.app_view_status = e.target.value;
                      setData({ ...data });
                    }}
                    className={"drop_css"}
                    defaultValue={single.app_view_status}
                  >
                    <option
                      value={single.app_view_status}
                      style={{ textTransform: "capitalize" }}
                    >
                      {single.app_view_status}
                    </option>
                    <option value="none">None</option>
                    <option value="show">Show</option>
                    <option value="hide">Hide</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sx={12} md={6}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>Target Audience</Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder={single.end_date}
                    defaultValue={single.target_audience}
                    onChange={(e) => {
                      data.target_audience = e.target.value;
                      setData({ ...data });
                    }}
                    maxLength={60}
                  />
                </Form.Group>
              </Col>

              <Col sx={12} md={6}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>Compulsory Training</Form.Label>
                  <Form.Select
                    aria-label="compulsary training"
                    size="lg"
                    onChange={(e) => {
                      data.training_compulsory = e.target.value;
                      setData({ ...data });
                    }}
                    className={"drop_css"}
                    defaultValue={single.training_compulsory}
                  >
                    <option
                      value={single.training_compulsory}
                      style={{ textTransform: "capitalize" }}
                    >
                      {single.training_compulsory}
                    </option>

                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </div>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <div className="allDetailsEditSowPage">
            <Row>
              <Col sx={12} md={6}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>Training Link</Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder={single.end_date}
                    defaultValue={single.training_link}
                    onChange={(e) => {
                      data.training_link = e.target.value;
                      setData({ ...data });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col sx={12} md={6}>
                {/* <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>Introduction Video Link</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={single.introduction_video}
                    onChange={(e) => {
                      data.introduction_video = e.target.value;
                      setData({ ...data });
                    }}
                  />
                </Form.Group> */}
              </Col>
              <Col sx={12} md={6}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>Introduction video</Form.Label>

                  <Form.Control
                    type="text"
                    defaultValue={single.training_video}
                  />
                  <div>
                    <input
                      type="file"
                      htmlFor="lablelab"
                      style={{ display: " none" }}
                      onChange={handleChangeuploadtraining}
                      ref={hiddenFileInput}
                    />
                    <Button
                      id="lablelab"
                      sx={12}
                      md={4}
                      onClick={handleClickuploadtraining}
                      style={{
                        position: "sticky",
                        marginTop: "-100px",
                        marginRight: "-760px",
                      }}
                    >
                      <img src="/upload.svg" alt="add item" width="20" />{" "}
                      &nbsp;upload
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sx={12} md={12}>
                <Form.Group
                  className="text-align-start my-1"
                  controlId="ControlInputAdd"
                >
                  <label htmlFor="ControlInputAdd" className="label_edit_sow">
                    Introduction Text
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={single.introduction_text}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      // editor.getData();
                      // console.log(editor.getData(), "editor.getData");
                      data.introduction_text = editor.getData();
                      console.log(editor.getData(), "checkfasak");
                      setData({ ...data });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sx={12} md={12}>
                <Form.Group className="my-1" controlId="ControlInputAdd">
                  <label htmlFor="ControlInputAdd" className="label_edit_sow">
                    whom to sell?
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={single.project_desc}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      // editor.getData();
                      // console.log(editor.getData(), "editor.getData");
                      data.project_desc = editor.getData();
                      setData({ ...data });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sx={12} md={12}>
                <Form.Group className="my-1" controlId="ControlInputAdd">
                  <label htmlFor="ControlInputAdd" className="label_edit_sow">
                    How to sell?
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={single.payout_criteria}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      data.payout_criteria = editor.getData();
                      setData({ ...data });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sx={12} md={12}>
                <Form.Group className="my-1" controlId="ControlInputAdd">
                  <label htmlFor="ControlInputAdd" className="label_edit_sow">
                    Terms and conditions
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={single.other_terms}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      // editor.getData();
                      // console.log(editor.getData(), "editor.getData");
                      data.other_terms = editor.getData();
                      setData({ ...data });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className="allDetailsEditSowPage">
            {/* <Row>
              <Col sx={12} md={6}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>Customer Download Link</Form.Label>

                  <Form.Control
                    type="text"
                    // placeholder={single.end_date}
                    defaultValue={single.utm_link}
                    onChange={(e) => {
                      data.utm_link = e.target.value;
                      setData({ ...data });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row> */}
            <Row>
              <Col sx={12} md={12}>
                {/* <Form.Group className="my-1" controlId="ControlInputAdd">
                  <label htmlFor="ControlInputAdd" className="label_edit_sow">
                    Customer Journey
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={single.customer_journey}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      // editor.getData();
                      // console.log(editor.getData(), "editor.getData");
                      data.customer_journey = editor.getData();
                      setData({ ...data });
                    }}
                  />
                </Form.Group> */}
              </Col>
            </Row>

            <label htmlFor="ControlInputAdd" className="label_edit_sow">
              Share Media Images
            </label>
            <Row className="scroll_row">
              {single?.media?.length < 1 ? (
                <Col sx={12} md={4}>
                  <Form.Group className="mb-4" controlId="ControlInput">
                    <div className="total_delete1">
                      <div
                        className="upload_css1"
                        id="resumeSel1"
                        onClick={(e) => {
                          handleSowDetails();
                        }}
                      >
                        <FeatherIcon
                          icon="file-plus"
                          style={{ color: "#6C58FB" }}
                          size={40}
                        />
                        <span>Add Image/Video</span>
                      </div>
                    </div>
                  </Form.Group>
                </Col>
              ) : (
                <></>
              )}

              {single?.media
                ?.map((res) => {
                  return (
                    <Card className="p-3">
                      <Row>
                        <Col sx={12} md={5}>
                          <div
                            className="delete_icon1"
                            style={{ zIndex: "100" }}
                            onClick={() => {
                              // console.log("clicked video");
                              handleClickOpen();
                              // console.log(res, "res");
                              setMediaid(res.media_id);
                            }}
                          >
                            <FeatherIcon
                              icon="trash"
                              style={{ color: "white" }}
                              size={15}
                            />
                          </div>
                          {res.link_type == "image" ? (
                            <img
                              src={res.media_image}
                              alt=""
                              height={400}
                              style={{ boxShadow: "0 0 3px gray" }}
                              className="imgs_css"
                            />
                          ) : (
                            <video
                              src={res.media_image}
                              alt=""
                              height={400}
                              className="imgs_css"
                              controls
                            />
                          )}
                        </Col>
                        <Col md={7}>
                          <CardBody className="d-flex flex-column align-items-start">
                            <h4 className="card-title mb-2">{res.title}</h4>
                            <div
                              style={{
                                textAlign: "left",
                                fontSize: "12px",
                                lineHeight: "normal",
                                display: "table-caption",
                              }}
                            >
                              <Interweave content={res.description} />
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  );
                })
                .reverse()}
            </Row>
            <Row className="scroll_row">
              <Col sx={12} md={4} className="p-2 mx-2">
                {customerMedia.length > 0 ? (
                  <>
                    <UncontrolledCarousel
                      dark={true}
                      interval={false}
                      items={customerMedia}
                      style={{
                        height: "600px",
                        width: "350px",
                        // border: "1px solid black",
                        boxShadow: "0 0 3px gray",
                        margin: "0 25px",
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Col>
              <Col sx={12} md={8} style={{ height: "600px" }}>
                <div
                  className="total_delete1 m-2"
                  style={{
                    height: "600px",
                    contain: "content",
                    overflow: "scroll",
                    padding: "20px",
                  }}
                >
                  <Form.Group className="mb-4" controlId="ControlInput">
                    <Form.Label>Customer Download Link</Form.Label>

                    <Form.Control
                      type="text"
                      // placeholder={single.end_date}
                      defaultValue={single.utm_link}
                      onChange={(e) => {
                        data.utm_link = e.target.value;
                        setData({ ...data });
                      }}
                      style={{ height: "30px", width: "75%", fontSize: "14px" }}
                    />
                  </Form.Group>
                  <Form.Label>Customer Process</Form.Label>

                  <div className="d-flex justify-content-end m-4">
                    <img
                      src={window.location.origin + "/images/add.svg"}
                      alt="add"
                      style={{ width: "30px", height: "30px" }}
                      onClick={() => {
                        setShow1(true);
                      }}
                    />
                  </div>
                  <div>
                    {customerMedia.map((cus, index) => (
                      <div key={index}>
                        <Card
                          body
                          style={{ textAlign: "left", marginTop: "20px" }}
                        >
                          <div className="total_delete">
                            <div
                              className="delete_icon"
                              onClick={() => {
                                handleClickOpen();
                                setMediaid(cus.media_id);
                              }}
                              style={{ top: "0" }}
                            >
                              <FeatherIcon
                                icon="trash"
                                style={{ color: "white" }}
                                size={15}
                              />
                            </div>
                          </div>
                          <div
                            style={{ fontSize: "14px", paddingRight: "44px" }}
                          >
                            Step {index + 1} : {cus.caption}
                          </div>
                        </Card>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div className="allDetailsEditSowPage">
            <Card>
              <div style={{ padding: "12px" }}>
                <CardTitle
                  tag="h5"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "0px",
                  }}
                >
                  Title
                </CardTitle>
                <Row>
                  {sowqc.map((sowqc) => {
                    let str1 = sowqc.split("_");
                    let str = str1.splice(0, str1.length - 1).join(" ");
                    str = str
                      .toLowerCase()
                      .split(" ")
                      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      .join(" ");
                    return (
                      <Col sm="3" style={{ padding: "12px 12px 0px 12px" }}>
                        <div
                          className="img_title"
                          onClick={() => {
                            handleOpen2();
                            setIntrtext(str);
                            setImgkey(sowqc);
                          }}
                        >
                          <p style={{ margin: "0px", padding: "8px " }}>
                            {str}
                          </p>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Card>
            <h5
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "24px",
              }}
            >
              Completed
            </h5>

            {getsowqc.map((getsowqc) => {
              let str2 = getsowqc.image_key.split("_");
              let imgkey = str2.splice(0, str2.length - 1).join(" ");
              imgkey = imgkey
                .toLowerCase()
                .split(" ")
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(" ");
              return (
                <>
                  <Card style={{ marginTop: "8px" }}>
                    <div
                      style={{
                        padding: "18px",
                      }}
                    >
                      <div
                        onClick={() => {
                          setSowscript(getsowqc.script);
                          setId(getsowqc.id);
                        }}
                        style={{ marginBottom: "24px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            marginBottom: "8px",
                          }}
                        >
                          <h6
                            style={{
                              margin: "0px",
                              fontWeight: "550",
                              textDecoration: "underline",
                            }}
                          >
                            {imgkey}
                          </h6>
                          <div>
                            <Mbutton
                              variant="contained"
                              size="small"
                              onClick={handleEdit}
                            >
                              Edit
                            </Mbutton>
                            <Mbutton
                              onClick={() => handleDelete(getsowqc.id)}
                              variant="contained"
                              size="small"
                              style={{ marginLeft: "8px" }}
                            >
                              Delete
                            </Mbutton>
                          </div>
                        </div>
                        <div
                          style={{
                            marginTop: "21px",
                            display: "block",
                            textAlign: "start",
                          }}
                        >
                          <Interweave content={getsowqc.script} />
                        </div>
                      </div>
                    </div>
                  </Card>
                </>
              );
            })}
          </div>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <div className="allDetailsEditSowPage">
       
              <div style={{ padding: "12px" }}>
                <CardTitle
                  tag="h6"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "10px",
                  }}
                >QC Script</CardTitle>
    
                <CKEditor
                    editor={ClassicEditor}
                    data={single.qc_script}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      // editor.getData();
                      // console.log(editor.getData(), "editor.getData");
                      data.qc_script = editor.getData();
                      console.log(editor.getData(), "checkfasak");
                      setData({ ...data });
                    }}
                  />
          
              </div>
       
          </div>
        </TabPanel>

        <Row className="d-flex justify-content-end">
          <Button
            className={"button_submit btn_pos"}
            type="submit"
            style={{ marginRight: "50px" }}
          >
            Save
          </Button>
        </Row>
      </Form>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to Delete
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleNo();
              handleClose();
            }}
            color="primary"
            autoFocus
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              // console.log("clicked 2");
              handleYes();
              handleClose();
            }}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Modal show={show} onHide={() => setShow(false)}>
        <div className="add_page1">
          <AddImage dataType={"add_share_media"} />
        </div>
      </Modal>
      <Modal show={show1} onHide={() => setShow1(false)}>
        <div className="add_page1">
          {/* <AddCustomerMedia /> */}
          <AddImage dataType={"add_customer_media"} />
        </div>
      </Modal>
      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>script</DialogTitle>
        <DialogContent>
          <Form.Group
            className="text-align-start my-1"
            controlId="ControlInputAdd"
          >
            <label htmlFor="ControlInputAdd" className="label_edit_sow">
              {intrtext}
            </label>
            <CKEditor
              editor={ClassicEditor}
              data={""}
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "height",
                    "120px",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onChange={(event, editor) => {
                console.log(editor, "testing");
                setScript(editor.getData());
              }}
            />
          </Form.Group>
        </DialogContent>
        <DialogActions style={{ marginRight: "14px" }}>
          <Mbutton variant="contained" size="small" onClick={handleClose2}>
            cancel
          </Mbutton>
          <Mbutton
            onClick={handleCreateqc}
            variant="contained"
            size="small"
            disabled={script === "" ? true : false}
          >
            submit
          </Mbutton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open3}
        onClose={handleClose3}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Edit</DialogTitle>
        <DialogContent>
          <Form.Group
            className="text-align-start my-1"
            controlId="ControlInputAdd"
          >
            <div className="ck">
              <CKEditor
                editor={ClassicEditor}
                data={sowscript}
                className="editor"
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "120px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  const edit = editor.getData();
                  console.log(edit, "123456");
                  setEditScript(edit);
                  setEdited(true);
                }}
              />
            </div>
          </Form.Group>
        </DialogContent>
        <DialogActions style={{ marginRight: "14px" }}>
          <Mbutton
            variant="contained"
            size="small"
            onClick={handleUpdateqc}
            disabled={editscript === sowscript || !edited ? true : false}
          >
            Submit
          </Mbutton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EditSowPage;
