import React, { useState } from "react";
import "../css/addsowsp.css";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import { ABHI_OFFER_LETTER, bank_status, search_fse } from "../../utils";
import { dataToken } from "../config/token";
import Swal from "sweetalert2";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

function FseSearch({ setShow }) {
  const [type, setType] = useState(["Mobile Number", "mobile_number"]);
  const [data, setData] = useState("");
  const [fse, setFse] = useState([]);
  const [userId, setUserid] = useState("");
  const [dailog, setDailog] = useState("0");
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (a) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNo = () => {};
  const handleYes = () => {
    const dataEntery = {
      user_id: userId,
    };
    if (dailog == "2") {
      axios
        .post(ABHI_OFFER_LETTER, dataEntery)
        .then((res) => {
          if (res.data.error) {
            Swal.fire({
              icon: "error",
              title: "Oops!",
              text: `${res.data.message}`,
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `Sent to this ${userId}`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: `Something went wrong`,
          });
        });
    } else if (dailog == "1") {
      axios
        .put(bank_status, dataEntery, dataToken)
        .then((res) => {
          if (res.data.error) {
            Swal.fire({
              icon: "error",
              title: "Oops!",
              text: `${res.data.message}`,
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "success",
              title: `Updated`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: `Something went wrong`,
          });
        });
    }
  };

  const handleSearch = () => {
    const postData = {};
    postData[type[1]] = data;
    axios
      .post(search_fse, postData, dataToken)
      .then((res) => {
        if (res.data.error) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          setFse(res.data.search);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="total_fse_search">
      <div className="heading_addspsow">
        <div className="d-flex align-items-center">
          <span className="heading_addsp">FSE Search</span>
          {/* <span className="sow_css_right">{sowId[0]}</span> */}
        </div>
        <div className="d-flex">
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {type[0]}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <span
                className="dropdown-item"
                onClick={() => setType(["Mobile Number", "mobile_number"])}
              >
                Mobile Number
              </span>
              <span
                className="dropdown-item"
                onClick={() => setType(["Aadhar Number", "aadhar_number"])}
              >
                Aadhar Number
              </span>
              <span
                className="dropdown-item"
                onClick={() => setType(["P-ID", "user_id"])}
              >
                P-ID
              </span>
              <span
                className="dropdown-item"
                onClick={() => setType(["Email-ID", "email_id"])}
              >
                Email-ID
              </span>
            </div>
          </div>
          <div className="input-group">
            <div className="form-outline">
              <input
                type="search"
                id="form1"
                className="form-control"
                placeholder="Search"
                onChange={(e) => {
                  setData(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
            </div>
            <button
              type="button"
              className="btn btn-light"
              onClick={handleSearch}
            >
              <FeatherIcon icon="search" className="text-secondary" />
            </button>
          </div>
        </div>
      </div>
      <div className="total_grid w-100">
        {fse
          .map((res) => {
            // console.log(res,'res')
            return (
              <div className="user_card_total m-3 col-3" key={res.user_id}>
                <div className="status">
                  <span
                    className="text_bg"
                    style={
                      res.onboard_status == "none"
                        ? { backgroundColor: "#E2F9FD", color: "#3EA2B4" }
                        : res.onboard_status == "inactive"
                        ? { backgroundColor: "#FFE1E1", color: "red" }
                        : null
                    }
                  >
                    {res.onboard_status === "none" ? "new" : res.onboard_status}
                  </span>
                </div>
                <div>
                  <img
                    alt="ott"
                    className="profile_img"
                    src={
                      res?.profile_image?.length > 5
                        ? res?.profile_image.substr(0, 4) === "http"
                          ? res.profile_image
                          : `https://app.backend.taskmo.co/fieldon_images/${res.profile_image}`
                        : window.location.origin +
                          "/images/svg/default_profile.svg"
                    }
                  />
                </div>
                <span className="user_name">{res.full_name}</span>
                <div className="position_outline">
                  <span className="pos_text">{res.user_id}</span>
                </div>
                <span className="loc_css">{res.email_id}</span>
                <span className="desc_fse_css">{res.city}</span>
                <span className="desc_fse_css">
                  <span className="heading_fse_search">Aadhar:</span>
                  {res.aadhar_number}
                </span>
                <span className="desc_fse_css">
                  <span className="heading_fse_search">Number:</span>
                  {res.mobile_number}
                </span>
                <span className="desc_fse_css">
                  <span className="heading_fse_search">Tsm Name:</span>
                  {res.asm_name}
                </span>
                <span className="desc_fse_css">
                  <span className="heading_fse_search">TSM Number:</span>
                  {res.asm_number}
                </span>
                <span className="desc_fse_css">
                  <span className="heading_fse_search">CM Name:</span>
                  {res.cm_name}
                </span>
                <span className="desc_fse_css">
                  <span className="heading_fse_search">CM Number:</span>
                  {res.cm_number}
                </span>
                <span className="desc_fse_css">
                  <span className="heading_fse_search">Account Name:</span>
                  {res.account_name}
                </span>
                <span className="desc_fse_css">
                  <span className="heading_fse_search">Account Number:</span>
                  {res.account_number}
                </span>
                <span className="desc_fse_css">
                  <span className="heading_fse_search">Bank Name:</span>
                  {res.bank_name}
                </span>
                <span className="desc_fse_css">
                  <span className="heading_fse_search">IFSC Code:</span>
                  {res.ifsc_code}
                </span>
                <div className="line"></div>
                {/* <div
                  className="view_outline mt-0"
                  onClick={() => {
                    handleClickOpen();
                    setDailog("1");
                    setUserid(res.user_id);
                  }}
                >
                  <span className="view_text">Restore bank details</span>
                </div> */}
                <div
                  className="view_outline m-2"
                  onClick={() => {
                    handleClickOpen();
                    setDailog("2");
                    setUserid(res.user_id);
                  }}
                >
                  <span className="view_text">Send Offer Letter</span>
                </div>
              </div>
            );
          })
          .reverse()}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to{" "}
            <span
              className="base-card card-green"
              style={{ marginLeft: "0px" }}
            >
              {dailog === "1" ? "Restore " : ""}
              {dailog === "2" ? "Send " : ""}
            </span>
            {dailog === "1" ? "Bank Details " : ""}
            {dailog === "2" ? "Offer Letter " : ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleNo();
              handleClose();
            }}
            color="primary"
            autoFocus
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleYes();
              handleClose();
            }}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FseSearch;
