import React, { useState, useEffect } from "react";
import "../css/AddSow.css";
import { Col, Row, Form, Button } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { overall_projects, create_sow } from "../../utils";
import axios from "axios";
import Swal from "sweetalert2";
import { dataToken } from "../config/token";
function AddSow() {
  const [projects, setProjects] = useState([]);
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [card, setCard] = useState({});

  useEffect(() => {
    axios
      .get(overall_projects, dataToken)
      .then((res) => {
        setProjects(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleProject = (e) => {
    setData({
      project_id: e[0],
      project_title: e[1],
      ref_table_name: e[4],
      add_lead_status: "none",
    });

    setCard({
      card_logo: e[2],
      card_color: e[3],
    });
    setShow(true);
  };

  const handlesubmit = (event) => {
    event.preventDefault();
    axios
      .post(create_sow, data, dataToken)
      .then((res) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `success`,
          showConfirmButton: false,
          timer: 1500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: `${err.response.data.message}`,
        });
      });
  };
  //     console.log(add_vendor, dataEnter, dataToken, "dataToken");
  //     axios
  //       .post(add_vendor, dataEnter, dataToken)
  //       .then((res) => {
  //         console.log(res.data, "data");
  //         Swal.fire({
  //           position: "center",
  //           icon: "success",
  //           title: `User ID ${res.data.vendor_id}`,
  //           showConfirmButton: false,
  //           timer: 1500,
  //         });
  //         setTimeout(() => {
  //           navigate("/onboarding");
  //           window.location.reload();
  //         }, 1500);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         Swal.fire({
  //           icon: "error",
  //           title: "Oops!",
  //           text: `${err.response.data.message}`,
  //         });
  //       });
  //   };
  //   console.log(data, "data");

  ClassicEditor.create(document.querySelector("#editor"), {
    fontFamily: {
      options: [
        "default",
        "Ubuntu, Arial, sans-serif",
        "Ubuntu Mono, Courier New, Courier, monospace",
      ],
    },
    toolbar: [
      "heading",
      "bulletedList",
      "numberedList",
      "fontFamily",
      "undo",
      "redo",
    ],
  });
  // .then( ... )
  // .catch( ... );

  return (
    <div>
      <span className="add_heading">Sow Add Detials</span>
      <Form onSubmit={handlesubmit}>
        <Row className="mt-5">
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Project Title</Form.Label>
              {/* <Form.Control
                type="text"
                onChange={(e) => {
                  data.project_title = e.target.value;
                  setData({ ...data });
                }}
              /> */}
              <Form.Select
                aria-label="Default select example"
                size="lg"
                onChange={(e) => {
                  // data.pay_type = e.target.value;
                  // setData({ ...data });
                  const data = e.target.value.split(",");
                  handleProject(data);
                }}
                className={"drop_css"}
                required
              >
                <option selected disabled>
                  Select Project
                </option>
                {projects.map((s) => (
                  <option
                    key={s.project_id}
                    value={[
                      s.project_id,
                      s.project_title,
                      s.brand_logo,
                      s.color_code,
                      s.ref_table_name,
                    ]}
                    data={s}
                  >
                    {s.project_title}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          {show ? (
            <Col sx={12} md={6} className="div_card">
              <div
                className="card_sample"
                style={{ background: card.card_color }}
              >
                <img
                  src={card.card_logo}
                  alt="logo"
                  style={{
                    width: "150px",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                />
              </div>
            </Col>
          ) : (
            <></>
          )}
        </Row>
        {show ? (
          <>
            <Row>
              <Col sx={12} md={6}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>Start Date</Form.Label>
                  {/* <Form.Control
                    type="text"
                    onChange={(e) => {
                      data.start_date = e.target.value;
                      setData({ ...data });
                    }}
                  /> */}
                  <Form.Control
                    type="date"
                    placeholder={"DOB"}
                    onChange={(e) => {
                      data.start_date = e.target.value;
                      setData({ ...data });
                    }}
                    required
                  />
                </Form.Group>
              </Col>
              <Col sx={12} md={6}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>End Date</Form.Label>
                  {/* <Form.Control
                    type="text"
                    //

                    onChange={(e) => {
                      data.end_date = e.target.value;
                      setData({ ...data });
                    }}
                  /> */}
                  <Form.Control
                    type="date"
                    placeholder={"DOB"}
                    onChange={(e) => {
                      data.end_date = e.target.value;
                      setData({ ...data });
                    }}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sx={12} md={6}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>UTM Link</Form.Label>

                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      data.utm_link = e.target.value;
                      setData({ ...data });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col sx={12} md={6}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>Payout Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    size="lg"
                    onChange={(e) => {
                      data.pay_type = e.target.value;
                      setData({ ...data });
                    }}
                    className={"drop_css"}
                    required
                  >
                    <option value="none">None</option>
                    <option value="weekly">Weekly</option>
                    <option value="fortnightly">Fort Nightly</option>
                    <option value="monthly">Monthly</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sx={12} md={6}>
                {/* <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>Training Link</Form.Label>

                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      data.training_link = e.target.value;
                      setData({ ...data });
                    }}
                  />
                </Form.Group> */}
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>Trending</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    size="lg"
                    onChange={(e) => {
                      data.is_trending = e.target.value;
                      setData({ ...data });
                    }}
                    className={"drop_css"}
                    required
                  >
                    <option value="">Select Type</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sx={12} md={6}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>CPL</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      data.xleads = e.target.value;
                      setData({ ...data });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sx={12} md={6}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>Vertical</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    size="lg"
                    onChange={(e) => {
                      data.vertical = e.target.value;
                      setData({ ...data });
                    }}
                    className={"drop_css"}
                    required
                  >
                    <option disabled>Select Type</option>
                    <option value="field">field</option>
                    <option value="remote">remote</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sx={12} md={6}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>Job Category</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    size="lg"
                    onChange={(e) => {
                      data.job_category = e.target.value;
                      setData({ ...data });
                    }}
                    className={"drop_css"}
                    required
                  >
                    <option disabled>Select Type</option>
                    <option value="hyp_fot">HYP FOT</option>
                    <option value="hyp_dat">HYP DAT</option>
                    <option value="dig_cxt">DIG CXT</option>
                    <option value="dig_mot">DIG MOT</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sx={12} md={6}>
                {/* <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>OOV</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      data.OOV = e.target.value;
                      setData({ ...data });
                    }}
                  />
                </Form.Group> */}
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>Enable Status</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    size="lg"
                    onChange={(e) => {
                      data.vertical = e.target.value;
                      setData({ ...data });
                    }}
                    className={"drop_css"}
                    required
                  >
                    <option value="open_network">Open Network</option>
                    <option value="close_network">close Network</option>
                    <option value="both">Both</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sx={12} md={6}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>Active Sow Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    size="lg"
                    onChange={(e) => {
                      data.type = e.target.value;
                      setData({ ...data });
                    }}
                    className={"drop_css"}
                    required
                  >
                    <option value="" disabled>
                      Select Type
                    </option>
                    <option value="1">Merchant Acquisition</option>
                    <option value="2">Partner Onboarding</option>
                    <option value="3">Finance</option>
                    <option value="4">Seller Onboarding</option>
                    <option value="5">Customer Acquisition</option>
                    <option value="6">Data Moderation</option>
                    <option value="7">TeleCalling</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sx={12} md={12}>
                <Form.Group className="my-1" controlId="ControlInputAdd">
                  <label htmlFor="ControlInputAdd" className="label_edit_sow">
                    Whom to sell?
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    id="editor"
                    Essentials
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      // editor.getData();
                      // console.log(editor.getData(), "editor.getData");
                      data.project_desc = editor.getData();
                      setData({ ...data });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sx={12} md={12}>
                <Form.Group className="my-1" controlId="ControlInputAdd">
                  <label htmlFor="ControlInputAdd" className="label_edit_sow">
                    How to sell?
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      data.payout_criteria = editor.getData();
                      setData({ ...data });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sx={12} md={12}>
                <Form.Group className="my-1" controlId="ControlInputAdd">
                  <label htmlFor="ControlInputAdd" className="label_edit_sow">
                    Terms and conditions
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      // editor.getData();
                      // console.log(editor.getData(), "editor.getData");
                      data.other_terms = editor.getData();
                      setData({ ...data });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="d-flex justify-content-end">
              <Button
                className={"button_submit"}
                type="submit"
                style={{ marginRight: "20px" }}
              >
                Save
              </Button>
            </Row>
          </>
        ) : (
          <></>
        )}
      </Form>
    </div>
  );
}

export default AddSow;
