import React, { useContext, useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import "./Table.css";
import DataTableExtensions from "react-data-table-component-extensions";
import { Link } from "react-router-dom";
import axios from "axios";
import { sow_fse_list, sow_user_details } from "../../utils";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { dataToken, token_type } from "../config/token";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Zoom from "@mui/material/Zoom";

import { Context } from "../../App";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import BargraphEarnTrack from "../Chart/Bargraphdash/BargraphEarnTrack";
import tableData from "../../utils";
import { leadsChart } from "../../utils";

import TestTable from "./TestTable";
import LeadsChart from "./LeadsChart";

createTheme("VendorTable", {
  text: {
    primary: "black",
    secondary: "#b2b2b2",
  },
  background: {
    default: "#fff",
  },
  boxshadow: {
    default: "5px 5px 30px #DEDEDEBF",
  },
  headCells: {
    default: "#64abdd",
  },
  divider: {
    default: "#f4f4f4",
  },
  cell: {
    style: {
      height: "60px !important",
    },
  },

  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
function FseSowTable() {
  const [context, setContext] = useContext(Context);

  const params = useParams();

  useEffect(() => {
    const dataEnter = {
      sow_id: params.id,
      asm_id: params.id2,
    };

    // console.log(dataEnter, "dataEnter");

    axios
      .post(sow_fse_list, dataEnter, dataToken)
      .then((res) => {
        context.fseSowList = res.data.data;
        setContext({ ...context });
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  //   const handleOfferLetter = (id) => {
  //     const dataEntery = {
  //       user_id: id,
  //     };

  //     Swal.fire({
  //       title: "Are you sure?",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Confirm",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         axios
  //           .post(ABHI_OFFER_LETTER, dataEntery)
  //           .then((res) => {
  //             // console.log(res.data);
  //             if (res.data.error) {
  //               Swal.fire({
  //                 icon: "error",
  //                 title: "Oops!",
  //                 text: `${res.data.message}`,
  //               });
  //             } else {
  //               Swal.fire({
  //                 position: "center",
  //                 icon: "success",
  //                 title: `Sent to this ${id}`,
  //                 showConfirmButton: false,
  //                 timer: 1500,
  //               });
  //             }
  //           })
  //           .catch((err) => {
  //             Swal.fire({
  //               icon: "error",
  //               title: "Oops!",
  //               text: `Something went wrong`,
  //             });
  //           });
  //       }
  //     });
  //   };

  const columns = [
    {
      name: "User ID",
      selector: (d) => d.user_id,
      sortable: true,
      center: true,
      omit: true,
      cell: (d) => <div>{d.user_id}</div>,
    },
    {
      name: "Profile",
      selector: (d) => d.profile_image,
      sortable: true,
      center: true,
      width: "120px",
      cell: (d) => (
        <div>
          <img
            src={
              d.profile_image.length > 5
                ? d.profile_image.substr(0, 4) === "http"
                  ? d.profile_image
                  : `https://app.backend.taskmo.co/fieldon_images/${d.profile_image}`
                : window.location.origin + "/images/svg/default_profile.svg"
            }
            alt="profile"
            className="profile_table_images"
          />
        </div>
      ),
    },
    {
      name: "Name",
      selector: (d) => d.full_name,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.full_name}</div>,
      outerrWidth: 100,
    },

    {
      name: "Number",
      selector: (d) => d.mobile_number,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.mobile_number}</div>,
      outerrWidth: 100,
    },
    {
      name: "Asm Name",
      selector: (d) => d.asm_name,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.asm_name}</div>,
      innerWidth: 100,
    },
    {
      name: "City",
      selector: (d) => d.city,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.city}</div>,
      innerWidth: 100,
    },
    {
      name: "Total Leads",
      selector: (d) => d.total_count,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.total_count}</div>,
      innerWidth: 100,
    },
    {
      name: "Approved Leads",
      selector: (d) => d.approved_count,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.approved_count}</div>,
      innerWidth: 100,
    },
    {
      name: "Earning",
      selector: (d) => d.earnings,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.earnings}</div>,
      innerWidth: 100,
    },

    {
      name: "Status",
      selector: (d) => d.onboard_status,
      sortable: true,
      center: true,
      cell: (d) => (
        <div className="d-flex">
          <div className={`status_ball ${d.onboard_status}`}></div>
          <p style={{ margin: "auto" }}>
            {d.onboard_status === "onboarded"
              ? "Active"
              : d.onboard_status === "inactive"
              ? "Inactive"
              : d.onboard_status === "none"
              ? "New"
              : ""}
          </p>
        </div>
      ),
    },
    // {
    //   name: "Action",
    //   center: true,
    //   width: "100px",
    //   cell: (d) => (
    //     <div className="div_small" style={{ cursor: "pointer" }}>
    //       {d.onboard_status == "onboarded" ? (
    //         <img
    //           src={window.location.origin + "/images/svg/upload.svg"}
    //           alt="profile"
    //           className="view_image"
    //           style={{ width: "40px", height: "45px", cursor: "pointer" }}
    //           onClick={() => handleOfferLetter(d.user_id)}
    //         />
    //       ) : (
    //         <img
    //           src={window.location.origin + "/images/svg/UPLOAD_DISABLE.svg"}
    //           alt="profile"
    //           className="view_image"
    //           style={{
    //             width: "40px",
    //             height: "45px",
    //             cursor: "no-drop",
    //           }}
    //         />
    //       )}
    //     </div>
    //   ),
    // },
  ];

  //   if (token_type == "hr" || token_type == "all") {
  //     columns.splice(7, 0, {
  //       name: "Action",
  //       center: true,
  //       width: "100px",
  //       cell: (d) => (
  //         <div className="div_small" style={{ cursor: "pointer" }}>
  //           <LightTooltip TransitionComponent={Zoom} title="send Offer letter">
  //             {d.onboard_status == "onboarded" ? (
  //               <img
  //                 src={window.location.origin + "/images/svg/folder_upload.svg"}
  //                 alt="profile"
  //                 className="offer_letter"
  //                 style={{ cursor: "pointer" }}
  //                 onClick={() => handleOfferLetter(d.user_id)}
  //               />
  //             ) : (
  //               <img
  //                 src={
  //                   window.location.origin +
  //                   "/images/svg/folder_upload_disable.svg"
  //                 }
  //                 alt="profile"
  //                 className="offer_letter"
  //                 style={{
  //                   cursor: "no-drop",
  //                 }}
  //               />
  //             )}
  //           </LightTooltip>
  //         </div>
  //       ),
  //     });
  //   }
  const tableData = {
    columns,
    data: context.fseSowList,
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    // alert("dh")
  };

  const handleClose = () => {
    setOpen(false);
  };

  const rowModal = () => {
    handleClickOpen();
  };

  const [rowData, setRowData] = useState({});
  // console.log("rowdata", rowData);

  const [test, setTest] = useState([]);
  // console.log(test, "test");

  const sowDetails = (d) => {
    const dataEnter = {
      sow_id: params.id,
      user_id: d.user_id,
    };

    axios
      .post(sow_user_details, dataEnter, dataToken)
      .then((res) => setTest(res.data?.details))
      .catch((err) => console.log(err));
  };

  const [leadsdata, setLeadsData] = useState({});

  const [loading, setLoading] = useState(true);
  const [change, setChange] = useState(false);
  const leads = () => {
    const urlEnter =
      leadsChart + `?sow_id=${params.id}&user_id=${rowData?.user_id}&month=`;
    axios
      .get(urlEnter, dataToken)
      .then((res) => {
        setLeadsData(res.data);
        setLoading(false);
        setChange(!change);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="table_data">
      <div className="card_table">
        <DataTableExtensions
          {...tableData}
          export={false}
          filterPlaceholder={`Search`}
          style={{ paddingRight: "25px important" }}
        >
          <DataTable
            columns={columns}
            data={tableData}
            defaultSortFieldId={3}
            pagination
            theme="VendorTable"
            highlightOnHover={true}
            paginationPerPage={5}
            onRowClicked={(d) => {
              setRowData(d);
              rowModal();
              sowDetails(d);
              leads();
            }}
          />
        </DataTableExtensions>
        <Dialog
          open={open}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ "& .MuiDialog-paper": { minWidth: "75%" } }}
        >
          {/* <DialogTitle>
            <h3>Network Details</h3>
          </DialogTitle> */}
          <DialogContent style={{ overflowX: "scroll" }}>
            <div
              style={{ display: "flex", gap: "18px", justifyContent: "center" }}
            >
              <div>
                <img
                  src={
                    rowData?.profile_image?.length > 5
                      ? rowData?.profile_image.substr(0, 4) === "http"
                        ? rowData?.profile_image
                        : `https://app.backend.taskmo.co/fieldon_images/${rowData?.profile_image}`
                      : window.location.origin +
                        "/images/svg/default_profile.svg"
                  }
                  alt="profile"
                  style={{ width: "60px", height: "60px" }}
                  className="profile_table_images"
                />
              </div>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ fontWeight: "600", fontSize: "16px" }}
              >
                <p
                  style={{ margin: 0 }}
                >{`${rowData.full_name} (${rowData.user_id})`}</p>
              </div>
            </div>
            <hr className="w-70" />
            <div className="bargraph" style={{ marginTop: "24px" }}>
              <LeadsChart data={leadsdata} loading={loading} change={change} />
            </div>
            <div style={{ marginTop: "48px" }}>
              <TestTable testData={test} />
            </div>
          </DialogContent>
        </Dialog>
        ;
      </div>
    </div>
  );
}

export default FseSowTable;
