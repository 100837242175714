import React, { useEffect, useState } from "react";
import DataTable, {
  createTheme,
  ExpanderComponentProps,
} from "react-data-table-component";
import "./Table.css";
import DataTableExtensions from "react-data-table-component-extensions";
import { json, Link } from "react-router-dom";
import axios from "axios";
import {
  ABHI_OFFER_LETTER,
  create_associate,
  fse_list,
  fse_onboard,
  oaAssignSow,
  oa_active,
  oa_assign_lsit,
  oa_edit,
  oa_project_active,
  oa_project_list,
} from "../../utils";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { dataToken, token, token_type } from "../config/token";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Zoom from "@mui/material/Zoom";
import {
  ButtonGroup,
  Col,
  Dropdown,
  DropdownButton,
  Row,
} from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import { ToastContainer, toast } from "react-toastify";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
  Box,
  Modal,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { current_month_sow, oa_current_sow } from "../../utils";

createTheme("VendorTable", {
  text: {
    primary: "black",
    secondary: "#b2b2b2",
  },
  background: {
    default: "#fff",
  },
  boxshadow: {
    default: "5px 5px 30px #DEDEDEBF",
  },
  headCells: {
    default: "#64abdd",
  },
  divider: {
    default: "#f4f4f4",
  },
  cell: {
    style: {
      height: "60px !important",
    },
  },

  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
function AssociateTable({
  data,
  setUpdatedActive,
  updatedActive,
  addedData,
  setAddedData,
  setEditedSingleData,
  editedSingleData,
}) {
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("xs");
  const [maxWidth2, setMaxWidth2] = React.useState("sm");
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [projectData, setProjectData] = React.useState(undefined);
  const [changeStatusmbNumber, setChangeStatusMbNumber] = React.useState("");
  const [editTableRow, setEditTableRow] = React.useState(false);
  const [editableRowId, setEditableRowId] = React.useState(undefined);
  const [oaName, setOaName] = React.useState("");
  const [oaNumber, setOaNumber] = React.useState("");
  const [emailid, setEmailId] = React.useState("");
  const [modal, setModal] = React.useState(false);
  const [oa_id, set_oa_id] = React.useState("");
  const [oa_list, set_oa_list] = React.useState([]);
  const [data_enter, set_data_enter] = React.useState({});
  const handleOpenmodal = (e) => {
    set_oa_id(e.id);
    setModal(true);
    const dataUrl = oa_assign_lsit + `?oa_id=${e.id}`;
    axios
      .get(dataUrl, dataToken)
      .then((res) => {
        // console.log(res.data, "first");
        set_oa_list(res.data.sows);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const [gender, setGender] = React.useState("");
  // const [id, setId] = React.useState([]);

  // const handleChange = (event) => {
  //   setGender(event.target.value);
  // };

  // const handleSowid = (id) => {
  //   setId((nums) =>
  //     nums.includes(id) ? nums.filter((n) => n !== id) : [id, ...nums]
  //   );
  // };
  // console.log("id", id);

  // const label = { inputProps: { "aria-label": "Checkbox demo" } };

  // const style = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: 400,
  //   bgcolor: "background.paper",
  //   border: "2px solid #000",
  //   boxShadow: 24,
  //   p: 4,
  // };
  const dataURL = current_month_sow + "?add_lead_status=enable";
  const [test, setTest] = useState([]);

  useEffect(() => {
    axios
      .get(dataURL, dataToken)
      .then((res) => setTest(res.data.data))
      .catch((err) => console.log(err.message));
  }, []);

  const ref = React.useRef();
  const handleEditRowWise = async (d) => {
    if (editTableRow) {
      const obj = {
        user_id: d.id,
        full_name: oaName,
        mobile_number: oaNumber,
        email_id: emailid,
      };

      axios
        .patch(oa_edit, obj, dataToken)
        .then((res) => {
          // console.log(res);
          setEditTableRow(!editTableRow);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setEditableRowId(undefined);
          setEditedSingleData(!editedSingleData);
        });
    } else {
      setEditTableRow(!editTableRow);
      let ot = await d;
      setOaName(d.full_name);
      setOaNumber(d.mobile_number);
      setEmailId(d.email_id);
      setEditableRowId(ot.id);
    }
  };

  const initState = {
    full_name: "",
    mobile_number: "",
    email_id: "",
    type: "",
  };
  const [editformData, setEditFormData] = React.useState(initState);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const revokeProjectAccess = (d) => {
    const { crm_id, status } = d;
    let changeStatus;
    if (status == "active") {
      changeStatus = "inactive";
    } else {
      changeStatus = "active";
    }
    const postData = {
      id: crm_id,
      status: changeStatus,
    };
    axios
      .patch(oa_project_active, postData, dataToken)
      .then((res) => {
        let ProjectListApi = `${oa_project_list}?mobile_number=${changeStatusmbNumber}`;

        axios
          .get(ProjectListApi, dataToken)
          .then((res) => {
            setProjectData(res?.data);
          })
          .catch((err) => console.log(err));
        // console.log(d, "ddddd");
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      name: "OA Name",
      selector: (d) => d.full_name,
      sortable: true,
      center: true,
      // omit: true,
      // cell: (d) => <div>{d.full_name}</div>,
      cell: (d) =>
        editableRowId == d.id && editTableRow ? (
          <div>
            <input
              placeholder={d.full_name}
              onBlur={(e) => setOaName(e.target.value)}
            />
          </div>
        ) : (
          <div>{d.full_name}</div>
        ),
    },

    {
      name: "OA Number",
      selector: (d) => d.mobile_number,
      sortable: true,
      center: true,
      cell: (d) =>
        editableRowId == d.id && editTableRow ? (
          <div>
            <input
              placeholder={d.mobile_number}
              name="mobile_number"
              // onChange={(e) => setOaNumber(e.target.value)}
              onBlur={(e) => {
                setOaNumber(e.target.value);
              }}
            />
          </div>
        ) : (
          <div>{d.mobile_number}</div>
        ),
      outerrWidth: 100,
    },

    {
      name: "Email ID",
      selector: (d) => d.email_id,
      sortable: true,
      center: true,
      cell: (d) =>
        editableRowId == d.id && editTableRow ? (
          <div>
            <input
              placeholder={d.email_id}
              onBlur={(e) => {
                setEmailId(e.target.value);
              }}
            />
          </div>
        ) : (
          <div>{d.email_id}</div>
        ),
      innerWidth: 100,
    },
    {
      name: "Edit Data",
      // selector: (d) => d.edit,
      cell: (d) => (
        <button onClick={() => handleEditRowWise(d)}>
          {d.id == editableRowId && editTableRow ? "Save" : "Edit"}
        </button>
      ),
      width: "100px",
    },

    {
      name: "Action",
      sortable: true,
      center: true,
      cell: (d) => (
        <div>
          <DropdownButton
            as={ButtonGroup}
            id={`dropdown-button-drop-start`}
            drop={"start"}
            variant="secondary"
            title={``}
          >
            <Dropdown.Item eventKey={"0"} onClick={() => handleClicked(d)}>
              {d.mep_access == "true" ? "InActive" : "Active"}
            </Dropdown.Item>

            <Dropdown.Item
              eventKey={"1"}
              onClick={() => handleClickedProjectList(d)}
            >
              Project List
            </Dropdown.Item>

            <Dropdown.Item eventKey={"2"} onClick={() => handleOpenmodal(d)}>
              Assign SOW
            </Dropdown.Item>
          </DropdownButton>
        </div>
      ),
      width: "100px",
    },
  ];

  const handleClicked = ({ id, mep_access }) => {
    // console.log(id, "checkdata");

    const postData = {
      oa_id: id,
      status: mep_access == "true" ? false : true,
    };
    // console.log(postData, "patch");
    axios
      .patch(oa_active, postData, dataToken)
      .then((res) => {
        // console.log(res.data, "patch");
        setUpdatedActive(!updatedActive);
        if (res.data.error) {
          toast.error("🦄 something went wrong!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success("😎 successfully updated!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log(err, "patch");
        toast.error("🦄 something went wrong!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const handleClickedProjectList = (d) => {
    handleClickOpen();
    setChangeStatusMbNumber(d.mobile_number);
    let ProjectListApi = `${oa_project_list}?mobile_number=${d.mobile_number}`;

    axios
      .get(ProjectListApi, dataToken)
      .then((res) => {
        setProjectData(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const tableData = {
    columns,
    data: data,
  };

  const handleChangeform = (e) => {
    const { name, value } = e.target;
    setEditFormData({ ...editformData, [name]: value });
  };
  const handleSubmitform = (e) => {
    e.preventDefault();

    // console.log(editformData, "editformdata");
    const objValues = Object.values(editformData);
    const final = objValues.includes("");
    if (!final) {
      axios
        .post(create_associate, editformData, dataToken)
        .then((res) => {
          // console.log(res, "res");
          setAddedData(!addedData);
          handleClose2();
          if (res.data.error) {
            toast.error("something went wrong!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            toast.success("😎 Added successfully!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((err) => {
          console.log(err.response.data.message.sqlMessage, "err");
          handleClose2();
          toast.error("Duplicate entry found!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    }
  };

  const ExpandedComponent = ({ data }) => {
    // setObjData({ ...data });
    // return (
    //   <div className="d-flex">
    //     <input
    //       defaultValue={objData.full_name}
    //       onChange={(e) => {
    //         console.log(e.target.value, "objdatadata");
    //         objData.full_name = e.target.value;
    //         setObjData({ ...objData });
    //       }}
    //     />
    //     <p>{data.email_id}</p>
    //     <p>{data.mobile_number}</p>
    //   </div>
    // );
  };

  const handleChangeProject = (e) => {
    const { name, value } = e.target;

    const dataEnter = {};

    dataEnter[`${name}`] = value;
    dataEnter["oa_id"] = oa_id;

    // console.log(dataEnter, "first");
    set_data_enter(dataEnter);
  };

  const handleAssign = () => {
    axios
      .post(oaAssignSow, data_enter, dataToken)
      .then((res) => {
        const dataUrl = oa_assign_lsit + `?oa_id=${oa_id}`;
        if (res.data.error) {
          toast.error("😡 Already added", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.success("😎 successfully updated!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }

        axios
          .get(dataUrl, dataToken)
          .then((res) => {
            // console.log(res.data, "first");
            set_oa_list(res.data.sows);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="table_data">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
      <div className="card_table">
        <div className="float_right">
          <Button
            variant="contained"
            onClick={handleClickOpen2}
            startIcon={<AddIcon />}
          >
            Associate
          </Button>
        </div>
        <DataTableExtensions
          {...tableData}
          export={false}
          filterPlaceholder={`Search`}
          style={{ paddingRight: "25px important" }}
        >
          <DataTable
            columns={columns}
            data={tableData}
            defaultSortFieldId={3}
            pagination
            theme="VendorTable"
            highlightOnHover={true}
            paginationPerPage={10}
            // expandableRows
            // expandableRowsComponent={ExpandedComponent}
          />
        </DataTableExtensions>
      </div>

      {open && (
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Projects List"}
          </DialogTitle>
          <DialogContent>
            {/* {console.log(projectData?.error, "errororororor")} */}

            {projectData?.error && <Typography>No Data found</Typography>}
            {projectData?.error == false && (
              <div>
                {projectData &&
                  projectData?.data.map((item) => (
                    <Grid
                      container
                      spacing={5}
                      key={item.crm_id}
                      style={{ padding: "20px" }}
                    >
                      <Grid item>
                        <Avatar src={item.brand_logo} alt="brand name" />
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{
                            width: "120px",
                            WebkitFilter:
                              item.status == "inactive" ? "blur(1px)" : null,
                          }}
                        >
                          {item.brand_name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Switch
                          defaultChecked={
                            item.status == "active" ? true : false
                          }
                          onClick={() => revokeProjectAccess(item)}
                        />
                      </Grid>
                    </Grid>
                  ))}
              </div>
            )}
          </DialogContent>
        </Dialog>
      )}

      {open2 && (
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open2}
          onClose={handleClose2}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Add Associate"}
          </DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmitform}>
              <Grid container spacing={2} direction="column">
                <Grid item md={8}>
                  <div>
                    <label id="name">Name</label>
                  </div>
                  <div>
                    <TextField
                      type="text"
                      name="full_name"
                      id="name"
                      required
                      fullWidth
                      onChange={handleChangeform}
                    />
                  </div>
                </Grid>
                <Grid item md={8}>
                  <div>
                    <label id="number">Number</label>
                  </div>
                  <div>
                    <TextField
                      type="text"
                      name="mobile_number"
                      id="number"
                      inputProps={{ maxLength: 10 }}
                      onChange={handleChangeform}
                      required
                      fullWidth
                    />
                  </div>
                </Grid>

                <Grid item md={8}>
                  <div>
                    <label id="email_id">Email Id</label>
                  </div>
                  <div>
                    {" "}
                    <TextField
                      type="email"
                      name="email_id"
                      id="email_id"
                      onChange={handleChangeform}
                      required
                      fullWidth
                    />
                  </div>
                </Grid>

                <Grid item md={8}>
                  <div>
                    <label id="type">Type</label>
                  </div>
                  <div>
                    {" "}
                    <TextField
                      type="email"
                      name="type"
                      id="type"
                      onChange={handleChangeform}
                      required
                      select
                      fullWidth
                    >
                      <MenuItem value={"oa"}>OA</MenuItem>
                      <MenuItem value={"ol"}>OL</MenuItem>
                      <MenuItem value={"om"}>OM</MenuItem>
                      <MenuItem value={"oh"}>OH</MenuItem>
                      <MenuItem value={"hr"}>HR</MenuItem>
                      <MenuItem value={"all"}>ALL</MenuItem>
                    </TextField>
                    <Grid item style={{ marginTop: "13px" }}>
                      <Button
                        variant="contained"
                        style={{ float: "right" }}
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      )}

      {modal && (
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth2}
          open={modal}
          onClose={() => setModal(false)}
          aria-labelledby="responsive-dialog-title"
          style={{ overflow: "scroll" }}
        >
          <DialogTitle id="responsive-dialog-title">{"Assign Sow"}</DialogTitle>
          <DialogContent>
            <Row>
              <Col md={8}>
                <select
                  style={{ width: "100%", heigth: "15px" }}
                  className="select_download"
                  name="sow_id"
                  onChange={handleChangeProject}
                  required
                >
                  <option value="">Select Project</option>
                  {test.map((el) => (
                    <option
                      value={el.sow_id}
                    >{`${el.project_title} (${el.sow_id})`}</option>
                  ))}
                </select>
              </Col>
              <Col md={4}>
                <Button variant="contained" onClick={handleAssign}>
                  Assign
                </Button>
              </Col>
            </Row>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Grid container>
                <ul className="ua_circle">
                  {oa_list?.map((el) => (
                    <li
                      style={{ fontSize: "14px" }}
                    >{`${el.project_title} (${el.sow_id})`}</li>
                  ))}
                </ul>
              </Grid>
            </Typography>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default AssociateTable;
