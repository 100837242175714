import React, { useEffect, useState, useContext } from "react";
import "./Bargraph.css";
import Chart from "react-apexcharts";
import {
  oa_month_leads,
  overall_month_leads,
  project_graph_leads,
  project_hour_data,
} from "../../../utils";
import axios from "axios";
import { Context } from "../../../App";
import { dataToken, token_type } from "../../config/token";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";

export default function BargraphEarnTrack() {
  const [context, setContext] = useContext(Context);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [change, setChange] = useState(false);
  const param = useParams();
  const [ser, setSeries] = useState([]);

  

  useEffect(() => {
    const postData = {
      sow_id: param.id,
      asm_id: param.id2 ? param.id2 : "",
    };
    axios.post(project_hour_data, postData, dataToken).then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, []);

  const handleChange = () => {
    setLoading(true);

    const postData = {
      sow_id: param.id,
      data_month: "month",
      asm_id: change ? param.id2 : "",
    };
    axios
      .post(
        change ? project_hour_data : project_graph_leads,
        postData,
        dataToken
      )
      .then((res) => {
        setData(res.data);
        setLoading(false);
        setChange(!change);
      });
  };

  var options = {
    series1: [
      {
        name: "Total Lead",
        data: data?.total_lead,
      },
      {
        name: "Approved Leads",
        data: data?.approved_leads,
      },
      { name: "Total Checkin", data: data?.checkin },
    ],
    series2: [
      {
        name: "Total Lead",
        data: data?.total_lead,
      },
      {
        name: "Approved Leads",
        data: data?.approved_leads,
      },
    ],
    stroke: {
      curve: "smooth",
    },
    colors: ["#595BC7", "#3ABE4E", "#f7cb04"],
    title: {
      text: "Lead Track",
      // align: "right",
      // offsetY: 170,
      offsetX: 10,
      style: {
        fontSize: 18,
      },
    },
    // subtitle: {
    //   text: `Total Earnings Rs 12345345`,
    //   align: "right",
    //   margin: 20,
    //   offsetY: 0,
    //   offsetX: -30,
    //   style: {
    //     fontSize: 16,
    //   },
    // },
    chart: {
      type: "bar",
      stacked: false,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: 10,
        borderRadius: 3,
      },
    },
    xaxis: {
      type: "category",
      categories: data?.date,
      labels: {
        style: {
          fontSize: "10",
          colors: "#6B7280",
          fontWeight: 100,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: "10px",
          colors: "#6B7280",
          fontWeight: 100,
        },
      },
    },
    legend: {
      position: "top",
      offsetX: 800,
      offsetY: -60,
      fontSize: 14,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
      pattern: {
        width: 10,
      },
    },
  };
  return (
    <div>
      <div className="switch_leads">
        <Form onChange={handleChange}>
          <Form.Check type="switch" id="custom-switch" label="Month" />
        </Form>
      </div>

      {loading ? (
        <div className="chart_css11">
          <img
            src={window.location.origin + "/images/gif/search1.gif"}
            alt="gif"
            className="loader_gif"
          />
        </div>
      ) : (
        <div className="chart_css2">
          <Chart
            options={options}
            series={change ? options.series1 : options.series2}
            type="line"
            height={335}
          />
        </div>
      )}
    </div>
  );
}
