import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import "./Table.css";
import DataTableExtensions from "react-data-table-component-extensions";
import { Link } from "react-router-dom";
import axios from "axios";
import { ABHI_OFFER_LETTER, fse_list, fse_onboard } from "../../utils";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { dataToken, token_type } from "../config/token";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Zoom from "@mui/material/Zoom";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Modal,
} from "react-bootstrap";
import { GetGenderModal } from "../modals/GetGenderModal";

createTheme("VendorTable", {
  text: {
    primary: "black",
    secondary: "#b2b2b2",
  },
  background: {
    default: "#fff",
  },
  boxshadow: {
    default: "5px 5px 30px #DEDEDEBF",
  },
  headCells: {
    default: "#64abdd",
  },
  divider: {
    default: "#f4f4f4",
  },
  cell: {
    style: {
      height: "60px !important",
    },
  },

  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
function FseTable() {
  const [fse, setFse] = useState([]);
  const { id } = useParams();
  const [fseUserId, setFseUserId] = React.useState(undefined);

  const [show, setShow] = React.useState(false);

  const handleClickOpen = ({ user_id }) => {
    setFseUserId(user_id);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    const dataEntery = {
      asm_id: id,
    };

    axios
      .post(fse_list, dataEntery, dataToken)
      .then((res) => {
        setFse(res.data.fse_lists);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleOfferLetter = (id) => {
    const dataEntery = {
      user_id: id,
    };

    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(ABHI_OFFER_LETTER, dataEntery)
          .then((res) => {
            // console.log(res.data);
            if (res.data.error) {
              Swal.fire({
                icon: "error",
                title: "Oops!",
                text: `${res.data.message}`,
              });
            } else {
              Swal.fire({
                position: "center",
                icon: "success",
                title: `Sent to this ${id}`,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops!",
              text: `Something went wrong`,
            });
          });
      }
    });
  };

  const columns = [
    {
      name: "User ID",
      selector: (d) => d.user_id,
      sortable: true,
      center: true,
      omit: true,
      cell: (d) => <div>{d.user_id}</div>,
    },
    {
      name: "Profile",
      selector: (d) => d.profile_image,
      sortable: true,
      center: true,
      width: "120px",
      cell: (d) => (
        <div>
          <img
            src={
              d.profile_image.length > 5
                ? d.profile_image.substr(0, 4) === "http"
                  ? d.profile_image
                  : `https://app.backend.taskmo.co/fieldon_images/${d.profile_image}`
                : window.location.origin + "/images/svg/default_profile.svg"
            }
            alt="profile"
            className="profile_table_images"
          />
        </div>
      ),
    },
    {
      name: "Name",
      selector: (d) => d.full_name,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.full_name}</div>,
      outerrWidth: 100,
    },

    {
      name: "Number",
      selector: (d) => d.mobile_number,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.mobile_number}</div>,
      outerrWidth: 100,
    },
    {
      name: "Email ID",
      selector: (d) => d.email_id,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.email_id}</div>,
      innerWidth: 100,
    },
    {
      name: "City",
      selector: (d) => d.city,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.city}</div>,
      innerWidth: 100,
    },

    {
      name: "Status",
      selector: (d) => d.onboard_status,
      sortable: true,
      center: true,
      cell: (d) => (
        <div className="d-flex">
          <div className={`status_ball ${d.onboard_status}`}></div>
          <p style={{ margin: "auto" }}>
            {d.onboard_status == "onboarded"
              ? "Active"
              : d.onboard_status == "inactive"
              ? "Inactive"
              : d.onboard_status == "none"
              ? "New"
              : ""}
          </p>
        </div>
      ),
    },
    // {
    //   name: "Action",
    //   center: true,
    //   width: "100px",
    //   cell: (d) => (
    //     <div className="div_small" style={{ cursor: "pointer" }}>
    //       {d.onboard_status == "onboarded" ? (
    //         <img
    //           src={window.location.origin + "/images/svg/upload.svg"}
    //           alt="profile"
    //           className="view_image"
    //           style={{ width: "40px", height: "45px", cursor: "pointer" }}
    //           onClick={() => handleOfferLetter(d.user_id)}
    //         />
    //       ) : (
    //         <img
    //           src={window.location.origin + "/images/svg/UPLOAD_DISABLE.svg"}
    //           alt="profile"
    //           className="view_image"
    //           style={{
    //             width: "40px",
    //             height: "45px",
    //             cursor: "no-drop",
    //           }}
    //         />
    //       )}
    //     </div>
    //   ),
    // },
  ];

  if (token_type == "hr" || token_type == "all") {
    columns.splice(7, 0, {
      name: "Action",
      center: true,
      width: "100px",
      cell: (d) => (
        <div
          className="div_small"
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <div>
            <DropdownButton
              as={ButtonGroup}
              id={`dropdown-button-drop-start`}
              drop={"start"}
              variant="secondary"
              title={``}
            >
              <Dropdown.Item
                eventKey="0"
                style={{
                  filter:
                    d.gender !== "" || d.gender !== "none"
                      ? "blur(0.8px)"
                      : null,
                  cursor:
                    d.gender !== "" || d.gender !== "none" ? "no-drop" : null,
                }}
                onClick={() => {
                  (d.gender == "" || d.gender == "none") && handleClickOpen(d);
                }}
              >
                AddGender
              </Dropdown.Item>
            </DropdownButton>
          </div>
          {/* <LightTooltip TransitionComponent={Zoom} title="send Offer letter">
            {d.onboard_status == "onboarded" ? (
              <img
                src={window.location.origin + "/images/svg/folder_upload.svg"}
                alt="profile"
                className="offer_letter"
                style={{ cursor: "pointer" }}
                onClick={() => handleOfferLetter(d.user_id)}
              />
            ) : (
              <img
                src={
                  window.location.origin +
                  "/images/svg/folder_upload_disable.svg"
                }
                alt="profile"
                className="offer_letter"
                style={{
                  cursor: "no-drop",
                }}
              />
            )}
          </LightTooltip> */}
        </div>
      ),
    });
  }
  const tableData = {
    columns,
    data: fse,
  };
  return (
    <div className="table_data">
      <div className="card_table">
        <DataTableExtensions
          {...tableData}
          export={false}
          filterPlaceholder={`Search`}
          style={{ paddingRight: "25px important" }}
        >
          <DataTable
            columns={columns}
            data={tableData}
            defaultSortFieldId={3}
            pagination
            theme="VendorTable"
            highlightOnHover={true}
            paginationPerPage={5}
          />
        </DataTableExtensions>
      </div>
      <Modal
        className="gendermodalmain"
        show={show}
        onHide={() => setShow(false)}
      >
        <GetGenderModal setShow={setShow} fse={fseUserId} />
      </Modal>
    </div>
  );
}

export default FseTable;
