import React from "react";

function PageNotFound() {
  return (
    <div>
      <img
        src={window.location.origin + "/images/gif/page_not_found5.gif"}
        alt="gif"
        className="no_page_gif"
      />
    </div>
  );
}

export default PageNotFound;
