const token = localStorage.getItem("vendor-token");
const token_type = localStorage.getItem("type");
const dataToken = {
  headers: {
    "x-access-token": token,
    "content-type": "application/json",
  },
};

export { dataToken, token, token_type };
