import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { current_month_sow, oa_current_sow,open_sow_list } from "../../utils";

import { dataToken, token_type } from "../config/token";
import { Context } from "../../App";
import TableTraining from "../Table/TableTraining";
import TableSow from "../Table/TableSow";


const Training = () => {
  const [context, setContext] = useContext(Context);

  useEffect(() => {
    axios.get(open_sow_list,dataToken)
    .then(res=>{
      // console.log(res.data,'resdata')
      context.training = res.data;
      setContext({...context})
    })
    .catch(err=>{
      console.log(err.message)
    })
  }, []);
  return (
    <div className="px-3">
    <Row className="mt-4">
      <h1 className="h1_header">Training</h1>
     <TableSow/>
    </Row>
  </div>
  )
}

export default Training