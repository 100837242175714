import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Row } from "react-bootstrap";
import "../css/Dashboard.css";
import { asm_list, oa_asm_list, tsm_list } from "../../utils";
import Modal from "react-bootstrap/Modal";
import AddVendor from "./AddVendor";
import TableTSM from "../Table/TableTSM";
import WidgetTsmSp from "./WidgetTsmSp";
import { dataToken, token_type } from "../config/token";
import { Context } from "../../App";

function Dashboard() {
  const [show, setShow] = useState(false);
  const params = window.location.pathname.replace("/", "");

  const [context, setContext] = useContext(Context);

  useEffect(() => {
    const dataEntery = {
      status: "both",
    };
    axios
      .post(
        (token_type == "oh" || token_type == "hr" || token_type == "all") &&
          params == "superpartner"
          ? asm_list
          : (token_type == "oh" || token_type == "hr" || token_type == "all") &&
            params == "tsm"
          ? tsm_list
          : oa_asm_list,
        dataEntery,
        dataToken
      )
      .then((res) => {
        context.asm = res.data;
        setContext({ ...context });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [context.sidebar]);

  return (
    <div className="px-3">
      <Row className="justify-content-end">
        <div className="div_right">
          {token_type == "hr" || token_type == "all" ? (
            <button className="button_blue" onClick={() => setShow(true)}>
              <img
                src={window.location.origin + "/images/add.svg"}
                alt="ott"
                className="button_add"
              />
              Add
            </button>
          ) : (
            <></>
          )}
        </div>
      </Row>
      <Row className="justify-content-center">
        <WidgetTsmSp />
      </Row>

      <Row className="mt-4">
        <h1 className="h1_header">
          {params == "tsm" ? "tsm" : "super partner"}
        </h1>
        <TableTSM />
      </Row>
      <Modal show={show} onHide={() => setShow(false)}>
        <div className="add_page">
          <AddVendor />
        </div>
      </Modal>
    </div>
  );
}

export default Dashboard;
