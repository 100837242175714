import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import "../Table/Table.css";
import DataTableExtensions from "react-data-table-component-extensions";
import { Link } from "react-router-dom";
import axios from "axios";
import { ABHI_OFFER_LETTER, fse_list, fse_onboard } from "../../utils";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { dataToken, token_type } from "../config/token";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Zoom from "@mui/material/Zoom";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import SowDetails from "./SowDetails";

createTheme("VendorTable", {
  text: {
    primary: "black",
    secondary: "#b2b2b2",
  },
  background: {
    default: "#fff",
  },
  boxshadow: {
    default: "5px 5px 30px #DEDEDEBF",
  },
  headCells: {
    default: "#64abdd",
  },
  divider: {
    default: "#f4f4f4",
  },
  cell: {
    style: {
      height: "60px !important",
    },
  },

  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
function TableSowAsm({ tableColumnData, asmId }) {
  const [show2, setShow2] = useState(false);
  const [sowData, setSowData] = useState("");
  // console.log(tableColumnData, "checking");
  const handleSowDetails = (e) => {
    setShow2(true);
    setSowData(e);
  };
  const { id } = useParams();
  const navigate = useNavigate();

  // console.log(sowData, "sowData");

  const columns = [
    {
      name: "SOW-ID",
      selector: (d) => d.sow_id,
      sortable: true,
      center: true,
      width: "100px",
      cell: (d) => <div>{d.sow_id}</div>,
    },
    {
      name: "User ID",
      selector: (d) => d.user_id,
      sortable: true,
      center: true,
      omit: true,
      cell: (d) => <div>{d.user_id}</div>,
    },
    {
      name: "Logo",
      selector: (d) => d.brand_logo,
      sortable: true,
      center: true,
      cell: (d) => (
        <div>
          <img
            src={d.brand_logo}
            alt="profile"
            className="profile_table_images"
          />
          <div>{d.brand_name}</div>
        </div>
      ),
    },
    {
      name: "Project Title",
      selector: (d) => d.project_title,
      sortable: true,
      center: true,
      // width: "230px",
      cell: (d) => <div>{d.project_title}</div>,
    },
    {
      name: "Assigned Network",
      selector: (d) => d.team,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.team}</div>,
      outerrWidth: 100,
    },

    {
      name: "Network Earned",
      selector: (d) => d.earned,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.earned}</div>,
      outerrWidth: 100,
    },
    {
      name: "Commission Earned",
      selector: (d) => d.comission,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.comission}</div>,
      innerWidth: 100,
    },
    {
      name: "View More",
      sortable: true,
      center: true,
      cell: (d) => (
        <div>
          <DropdownButton
            as={ButtonGroup}
            id={`dropdown-button-drop-start`}
            drop={"start"}
            variant="secondary"
            title={``}
          >
            <Dropdown.Item
              eventKey="1"
              onClick={() =>
                handleSowDetails([d.project_title, d.sow_id, d.brand_logo])
              }
            >
              SOW Details
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="2"
              onClick={() => navigate(`/view-sow/${d.sow_id}/${asmId}`)}
            >
              View More
            </Dropdown.Item>
          </DropdownButton>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    data: tableColumnData,
  };
  return (
    <div className="table_data">
      <div className="card_table">
        <DataTableExtensions
          {...tableData}
          export={false}
          filterPlaceholder={`Search`}
          style={{ paddingRight: "25px important" }}
        >
          <DataTable
            columns={columns}
            data={tableData}
            defaultSortFieldId={3}
            pagination
            theme="VendorTable"
            highlightOnHover={true}
            paginationPerPage={5}
          />
        </DataTableExtensions>
      </div>

      <Modal show={show2} onHide={() => setShow2(false)}>
        <SowDetails
          brand={sowData[0]}
          setShow={setShow2}
          sow_id={sowData[1]}
          logo={sowData[2]}
          flag={false}
        />
      </Modal>
    </div>
  );
}

export default TableSowAsm;
