import React from "react";
import Widget from "./Widget";
import "../css/Home.css";
import Bargraph from "../Chart/Bargraphdash/Bargraph";
import { Col, Row } from "reactstrap";
import BargraphNetworkEarn from "../Chart/Bargraphdash/BargraphNetworkEarn";
import BargraphNetworkAct from "../Chart/Bargraphdash/BargraphNetworkAct";
function Home() {
  return (
    <div className="m-5 main_css">
      <Row>
        <Col md={5}>
          <Widget />
        </Col>
        <Col md={7}>
          <Bargraph />
        </Col>
      </Row>
      <Row className="mt-4">
        <BargraphNetworkEarn />
      </Row>
      <Row className="mt-4">
        <BargraphNetworkAct />
      </Row>
    </div>
  );
}

export default Home;
