import axios from "axios";
import React, { useEffect, useState } from "react";
import { oa_details, profile_upload } from "../../utils";
import { dataToken } from "../config/token";
import FeatherIcon from "feather-icons-react";
import "../css/Profilecard.css";
function ProfileCard() {
  const [userData, setUserdata] = useState({});
  const [url, setUrl] = useState("");
  useEffect(() => {
    axios
      .get(oa_details, dataToken)
      .then((res) => {
        setUserdata(res.data.data);
        setUrl(res.data.data.profile_image);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const fileUpload = (e) => {
    const fileSelect = document.getElementById("resumeSel"),
      fileElem = document.getElementById("resumeEle");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem) {
          fileElem.click();
        }
      },
      false
    );
  };
  const imageUpload = (e) => {
    const axiosData = new FormData();
    axiosData.append("file", e.target.files[0]);
    axiosData.append("id", userData.id);
    axios
      .post(profile_upload, axiosData, dataToken)
      .then((res) => {
        setUrl(res.data.results.url);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <div className="card-container">
      <span className="pro">P-ID:{userData.id}</span>
      {userData?.profile_image ? (
        <img
          src={url}
          alt="user"
          className="img_css_profile"
          onError={(e) => {
            e.target.src = `${window.location.origin}/images/power.svg`;
          }}
        />
      ) : (
        <div className="non_img_css_profile_outside">
          <div className="non_img_css_profile">
            {userData.full_name ? userData.full_name[0] : ""}
          </div>
        </div>
      )}
      <div>
        <input
          type="file"
          id="resumeEle"
          style={{ display: "none" }}
          onChange={(e) => {
            imageUpload(e);
          }}
        />
        <div
          onClick={() => {
            fileUpload();
          }}
          id="resumeSel"
          className="edit_profile_css"
        >
          <FeatherIcon icon="edit" />
        </div>
      </div>
      <h3 className="name_css mt-4">{userData.full_name}</h3>
      <h6 className="number_css">{userData.mobile_number}</h6>
      {userData.sub_team == "0" ? (
        <p className="unit_css"></p>
      ) : (
        <p className="unit_css">
          {userData.main_team}.{userData.sub_team}.{userData.unit_team}.
          {userData.unit_id}
        </p>
      )}
      <span className="email_css">{userData.email_id}</span>
    </div>
  );
}

export default ProfileCard;
