import React, { useEffect, useState, useContext } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import CountUp from "react-countup";
//Import Icons
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import "../css/Widget.css";
import {
  oa_onboarded_team,
  onboarded_team,
  onboarded_team_tsm,
} from "../../utils";
import { Context } from "../../App";
import { dataToken, token_type } from "../config/token";

const WidgetTsmSp = () => {
  const [stats, setStats] = useState({});
  const [vendor_type, setVendortype] = useState("");
  const [context, setContext] = useContext(Context);

  useEffect(() => {
    if (window.location.pathname === "/superpartner") {
      axios
        .get(
          token_type == "oh" || token_type == "hr" || token_type == "all"
            ? onboarded_team
            : oa_onboarded_team,
          dataToken
        )
        .then((res) => {
          if (res.data.error) {
            console.error(res.data.message);
          } else {
            setStats(res.data);
            setVendortype("SP");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (window.location.pathname === "/tsm") {
      axios
        .get(onboarded_team_tsm, dataToken)
        .then((res) => {
          if (res.data.error) {
            console.error(res.data.message);
          } else {
            setStats(res.data);
            setVendortype("TSM");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [context.sidebar]);

  return (
    <React.Fragment>
      <Row>
        <Col md={3}>
          <Card className="card-animate card_shadow">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="fw-medium text-muted mb-0">
                    Active {vendor_type}
                  </p>
                  <h2 className="mt-4 ff-secondary fw-semibold">
                    <span className="counter-value text-success">
                      <CountUp
                        start={0}
                        end={stats.total_onboarded}
                        decimals={0}
                        duration={2}
                      />
                    </span>
                  </h2>
                </div>
                <div className="icon_css_total">
                  <img
                    src={window.location.origin + "/images/svg/tsm.svg"}
                    alt="profile"
                    width="100px"
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={3}>
          <Card className="card-animate card_shadow">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="fw-medium text-muted mb-0">
                    Inactive {vendor_type}
                  </p>
                  <h2 className="mt-4 ff-secondary fw-semibold">
                    <span className="counter-value text-danger">
                      <CountUp
                        start={0}
                        end={stats.inactive_onboarded}
                        decimals={0}
                        duration={2}
                      />
                    </span>
                  </h2>
                </div>
                <div className="icon_css_total">
                  <img
                    src={
                      window.location.origin + "/images/svg/inactive_tsm.svg"
                    }
                    alt="profile"
                    width="100px"
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={3}>
          <Card className="card-animate card_shadow">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="fw-medium text-muted mb-0">New {vendor_type}</p>
                  <h2 className="mt-4 ff-secondary fw-semibold">
                    <span
                      className="counter-value "
                      style={{ color: "#DFA800" }}
                    >
                      <CountUp
                        start={0}
                        end={stats.new_onboarded}
                        decimals={0}
                        duration={2}
                      />
                    </span>
                  </h2>
                </div>
                <div className="icon_css_total">
                  <img
                    src={window.location.origin + "/images/svg/new_tsm.svg"}
                    alt="profile"
                    width="100px"
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={3}>
          <Card className="card-animate card_shadow">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="fw-medium text-muted mb-0">
                    Total {vendor_type}
                  </p>
                  <h2 className="mt-4 ff-secondary fw-semibold">
                    <span className="counter-value text-primary">
                      <CountUp
                        start={0}
                        end={stats.total_team}
                        decimals={0}
                        duration={2}
                      />
                    </span>
                  </h2>
                </div>
                <div className="icon_css_total">
                  <img
                    src={window.location.origin + "/images/svg/total_tsm.svg"}
                    alt="profile"
                    width="100px"
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default WidgetTsmSp;
