import React from "react";
import { Col, Row, Form, Button } from "react-bootstrap";

import FeatherIcon from "feather-icons-react";
import axios from "axios";
import { sow_media_upload } from "../../utils";
import { dataToken } from "../config/token";
import { useParams } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function AddImage(props) {
  const [data, setData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const { id3: sowId } = useParams();

  const fileUpload2 = (e) => {
    const fileSelect = document.getElementById("resumeSel2"),
      fileElem = document.getElementById("resumeEle2");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem) {
          fileElem.click();
        }
      },
      false
    );
  };
  const imageUpload = (e, type) => {
    setLoading(true);
    const axiosData = new FormData();
    axiosData.append("file", e.target.files[0]);
    axiosData.append("sow_id", sowId);
    axiosData.append("type", type);
    axiosData.append("description", data.description);
    axiosData.append("title", data.title);
    const files = e.target.files;
    let output = "";
    for (const file of files) {
      output += `${file.type || "unknown"}\n`;
    }
    axiosData.append("link_type", output.split("/")[0]);
    axios
      .post(sow_media_upload, axiosData, dataToken)
      .then((res) => {
        setLoading(false);
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <div>
      <Row className="mt-3">
        {props.dataType == "add_share_media" ? (
          <Col sx={12}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <label htmlFor="ControlInputAdd" className="label_edit_sow">
                Image Title
              </label>
              <Form.Control
                type="text"
                onChange={(e) => {
                  data.title = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        ) : (
          <></>
        )}
      </Row>
      <Row>
        {props.dataType !== "add_share_media" ? (
          <Col sx={12}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <label htmlFor="ControlInputAdd" className="label_edit_sow">
                Image Description
              </label>{" "}
              <Form.Control
                type="text"
                onChange={(e) => {
                  data.description = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        ) : (
          <Col sx={12}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <label htmlFor="ControlInputAdd" className="label_edit_sow">
                Share Description
              </label>

              <CKEditor
                editor={ClassicEditor}
                id="editor"
                Essentials
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  // editor.getData();
                  // console.log(editor.getData(), "editor.getData");
                  data.description = editor.getData();
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        )}
      </Row>
      {data.description ? (
        <Row>
          <Col sx={12}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <input
                type="file"
                id="resumeEle2"
                style={{ display: "none" }}
                onChange={(e) => {
                  const dataEnter =
                    props.dataType == "add_share_media"
                      ? "share_media"
                      : "customer_media";
                  imageUpload(e, dataEnter);
                }}
              />
              <div
                className="upload_css"
                id="resumeSel2"
                onClick={() => {
                  fileUpload2();
                }}
              >
                {loading ? (
                  <Bars
                    height="80"
                    width="80"
                    color="#6C58FB"
                    ariaLabel="bars-loading"
                    wrapperClass="loader"
                    visible={true}
                  />
                ) : (
                  <>
                    <FeatherIcon
                      icon="upload-cloud"
                      style={{ color: "#6C58FB" }}
                      size={40}
                    />
                    <span>Upload Image/Video</span>
                  </>
                )}
              </div>
            </Form.Group>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </div>
  );
}

export default AddImage;
