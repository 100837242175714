import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../css/Crop.css";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import axios from "axios";
import { upload_image } from "../../utils";
import Swal from "sweetalert2";

function Crop({ img, lead_id, type, btn_name, setShow1 }) {
  const [open1, setOpen1] = useState(false);
  function handleClose1() {
    setOpen1(false);
  }

  const [image, setImage] = useState(img);
  const [cropData, setCropData] = useState("");
  const [cropper, setCropper] = useState();
  const [fil, setFile] = useState({});
  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
      }
      var file = dataURLtoFile(
        cropper.getCroppedCanvas().toDataURL(),
        "hello.txt"
      );
      setFile(file);
    }
  };

  const UploadImagemyn = () => {
    const axiosData = new FormData();
    axiosData.append("file", fil);
    axiosData.append("asm_id", lead_id);
    axiosData.append("type", type);

    axios
      .post(upload_image, axiosData)
      .then((res) => {
        handleClose1();
        Swal.fire({
          icon: "success",
          text: "Image Updated",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((e) => {
        Swal.fire({
          icon: "warning",
          text: "oops something went wrong!",
        });
      });
  };
  return (
    <div className="call_btn">
      <button
        className="button_submit1"
        onClick={() => {
          setOpen1(true);
        }}
      >
        {btn_name}
      </button>
      <Modal show={open1} onHide={handleClose1} className="model_contain_crop">
        <div className="modal_crop">
          <div className="d-flex">
            <div style={{ width: "90vw", marginRight: "10px", height: "86vh" }}>
              {/* <input type="file" onChange={onChange} />
              <button>Use default img</button> */}
              <Cropper
                style={{ height: 600, width: "90%" }}
                zoomTo={0.5}
                initialAspectRatio={1}
                preview=".img-preview"
                src={
                  image?.length > 5
                    ? image.substr(0, 4) == "http"
                      ? image
                      : `https://app.backend.taskmo.co/asm_images/${image}`
                    : window.location.origin + "/images/certificate.png"
                }
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
              <div className="d-flex justify-content-around my-5">
                <button onClick={getCropData} className="button_submit1">
                  Crop Image
                </button>
                <div>
                  <button
                    style={{ float: "right" }}
                    onClick={UploadImagemyn}
                    className="button_submit1"
                  >
                    Save
                  </button>
                  <button
                    className="button_remove"
                    onClick={(e) => {
                      setOpen1(false);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div className="" style={{ width: "45%", height: "300px" }}>
              <img
                style={{ width: "100%" }}
                src={
                  cropData.length > 0
                    ? cropData
                    : window.location.origin + "/images/svg/doc.svg"
                }
                alt="cropped"
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Crop;
