import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import CountUp from "react-countup";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import { oa_dashboard_status, overall_dashboard_status } from "../../utils";
import { dataToken, token_type } from "../config/token";
const Widget = () => {
  const [stats, setStats] = useState({});

  useEffect(() => {
    axios
      .get(
        token_type == "oh" || token_type == "hr" || token_type == "all"
          ? overall_dashboard_status
          : oa_dashboard_status,
        dataToken
      )
      .then((res) => {
        setStats(res.data.data);
      });
  }, []);

  return (
    <React.Fragment>
      <Row>
        {/* <Row>
          <h4 className="d-flex">Leads</h4>
        </Row> */}
        <Col md={6}>
          <Card className="card-animate card_shadow">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="fw-medium text-muted mb-0">Total Leads</p>
                  <h2 className="mt-4 ff-secondary fw-semibold">
                    <span className="counter-value text-primary">
                      +
                      <CountUp
                        start={0}
                        end={stats.today_total_leads}
                        decimals={0}
                        duration={2}
                      />
                    </span>
                  </h2>
                  <p className="mb-0 text-muted">
                    <span className="badge bg-light text-primary mb-0">
                      <i className="ri-arrow-up-line align-middle"></i>
                      {stats.total_leads}
                    </span>
                  </p>
                </div>
                <div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-soft-info rounded-circle fs-2">
                      <FeatherIcon icon="users" className="text-info" />
                    </span>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="card-animate card_shadow">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="fw-medium text-muted mb-0">Approved Leads</p>
                  <h2 className="mt-4 ff-secondary fw-semibold">
                    <span className="counter-value text-success">
                      +
                      <CountUp
                        start={0}
                        end={stats.today_approved_leads}
                        decimals={0}
                        duration={2}
                      />
                    </span>
                  </h2>
                  <p className="mb-0 text-muted">
                    <span className="badge bg-light text-success mb-0">
                      <i className="ri-arrow-up-line align-middle"></i>{" "}
                      {stats.approved_leads}
                    </span>
                  </p>
                </div>
                <div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-soft-info rounded-circle fs-2">
                      <FeatherIcon icon="user-check" className="text-info" />
                    </span>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col md={6}>
          <Card className="card-animate card_shadow">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="fw-medium text-muted mb-0">Pending Leads</p>
                  <h2 className="mt-4 ff-secondary fw-semibold">
                    <span
                      className="counter-value "
                      style={{ color: "#DFA800" }}
                    >
                      +
                      <CountUp
                        start={0}
                        end={stats.today_pending_leads}
                        decimals={0}
                        duration={2}
                      />
                    </span>
                  </h2>
                  <p className="mb-0 text-muted">
                    <span
                      className="badge bg-light  mb-0"
                      style={{ color: "#DFA800" }}
                    >
                      <i className="ri-arrow-up-line align-middle"></i>{" "}
                      {stats.pending_leads}
                    </span>
                  </p>
                </div>
                <div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-soft-info rounded-circle fs-2">
                      <FeatherIcon icon="user" className="text-info" />
                    </span>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="card-animate card_shadow">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="fw-medium text-muted mb-0">Rejected Leads</p>
                  <h2 className="mt-4 ff-secondary fw-semibold">
                    <span className="counter-value text-danger">
                      +
                      <CountUp
                        start={0}
                        end={stats.today_rejected_leads}
                        decimals={0}
                        duration={2}
                      />
                    </span>
                  </h2>
                  <p className="mb-0 text-muted">
                    <span className="badge bg-light text-danger mb-0">
                      <i className="ri-arrow-up-line align-middle"></i>{" "}
                      {stats.rejected_leads}
                    </span>
                  </p>
                </div>
                <div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-soft-info rounded-circle fs-2">
                      <FeatherIcon icon="user-x" className="text-info" />
                    </span>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Widget;
