import React, { useEffect, useState, useContext } from "react";
import "./Bargraph.css";
import Chart from "react-apexcharts";
import { oa_month_leads, overall_month_leads } from "../../../utils";
import axios from "axios";
import { Context } from "../../../App";
import { dataToken, token_type } from "../../config/token";

export default function BargraphNetworkEarn() {
  const [context, setContext] = useContext(Context);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(
        token_type == "oh" || token_type == "hr" || token_type == "all"
          ? overall_month_leads
          : oa_month_leads,
        dataToken
      )
      .then((res) => {
        setData(res.data);
        setLoading(false);
      });
  }, []);
  var options = {
    series: [
      {
        name: "Leads",
        data: data?.leads,
      },
    ],
    stroke: {
      curve: "smooth",
    },
    colors: ["#3ABE4E"],
    title: {
      text: "Leads Per Month",
      // align: "right",
      // offsetY: 170,
      offsetX: 10,
      style: {
        fontSize: 18,
      },
    },
    // subtitle: {
    //   text: `Total Earnings Rs 12345345`,
    //   align: "right",
    //   margin: 20,
    //   offsetY: 0,
    //   offsetX: -30,
    //   style: {
    //     fontSize: 16,
    //   },
    // },
    chart: {
      type: "bar",
      stacked: false,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: 10,
        borderRadius: 3,
      },
    },
    xaxis: {
      type: "category",
      categories: data?.month,
      labels: {
        style: {
          fontSize: "10",
          colors: "#6B7280",
          fontWeight: 100,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: "10px",
          colors: "#6B7280",
          fontWeight: 100,
        },
      },
    },
    legend: {
      position: "top",
      offsetX: 800,
      offsetY: -60,
      fontSize: 14,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
      pattern: {
        width: 10,
      },
    },
  };
  return (
    <div>
      {loading ? (
        <div className="chart_css11">
          <img
            src={window.location.origin + "/images/gif/search1.gif"}
            alt="gif"
            className="loader_gif"
          />
        </div>
      ) : (
        <div className="chart_css2">
          <Chart
            options={options}
            series={options.series}
            type="line"
            height={335}
          />
        </div>
      )}
    </div>
  );
}
