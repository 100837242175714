export const contextData = {
  sidebar: [false, true, true, true, true, true],
  threeLine: false,
  loggedin: false,
  otp_mobile: "",
  navData: {},
  asm: [],
  sow: [],
  projectCard: {},
  projectsList: [],
  fseSowList: [],
};
