import React, { useContext, useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import "./Table.css";
import DataTableExtensions from "react-data-table-component-extensions";
import { Link } from "react-router-dom";
import axios from "axios";
import { get_payment_request, sow_fse_list } from "../../utils";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { dataToken, token_type } from "../config/token";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Zoom from "@mui/material/Zoom";
import { Context } from "../../App";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PayoutsDataModal from "../modals/PayoutsDataModal";
import { Modal } from "react-bootstrap";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import GppBadIcon from "@mui/icons-material/GppBad";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
createTheme("VendorTable", {
  text: {
    primary: "black",
    secondary: "#b2b2b2",
  },
  background: {
    default: "#fff",
  },
  boxshadow: {
    default: "5px 5px 30px #DEDEDEBF",
  },
  headCells: {
    default: "#64abdd",
  },
  divider: {
    default: "#f4f4f4",
  },
  cell: {
    style: {
      height: "60px !important",
    },
  },

  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

function PayoutSowTable() {
  const [context, setContext] = useContext(Context);
  const { id } = useParams();
  const [callApiupdated, setCallApiUpdated] = React.useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const dataEnter = {
      sow_id: id,
    };

    axios
      .post(get_payment_request, dataEnter, dataToken)
      .then((res) => {
        context.paymentrequest = res.data.details;
        setContext({ ...context });
      })
      .catch((err) => {
        console.error(err);
      });
  }, [callApiupdated]);

  const columns = [
    {
      name: " Start Date",
      selector: "start_date",
      cell: (d) => <div className="date">{d.start_date}</div>,
      sortable: true,
      //   center: true,
      width: "120px",
    },
    {
      name: "End Date",
      selector: "end_date",
      cell: (d) => <div className="date">{d.end_date}</div>,
      sortable: true,
      //   center: true,
      width: "120px",
    },
    {
      name: "Lead Request",
      selector: "request_date",
      cell: (d) => <div className="amt_table">{d.request_date}</div>,
      sortable: true,
      center: true,
    },
    {
      name: "Total Leads",
      selector: "leads",
      cell: (d) => <div className="amt_table">{d.leads}</div>,
      sortable: true,
      center: true,
      width: "80px",
    },
    {
      name: "Cpl",
      selector: "cpl",
      cell: (d) => <div className="amt_table">{d.cpl}</div>,
      sortable: true,
      center: true,
      width: "80px",
    },
    {
      name: "Total Amount",
      selector: "cpl",
      cell: (d) => <div className="amt_table">{d.cpl * d.leads}</div>,
      sortable: true,
      center: true,
      width: "120px",
    },
    {
      name: "Remark",
      selector: "req_remark",
      cell: (d) => <div className="amt_table">{d.req_remark}</div>,
      sortable: true,
      center: true,
      width: "180px",
    },
    {
      name: "users",
      selector: "users",
      cell: (d) => <div className="amt_table">{d.users}</div>,
      sortable: true,
      left: true,
      width: "100px",
    },
    {
      name: "status",
      selector: "status",
      sortable: true,
      width: "80px",
      cell: (d) => (
        <div>
          {d.status == "processed" ? (
            <VerifiedUserIcon style={{ color: "green" }} />
          ) : d.status == "failed" ? (
            <GppBadIcon style={{ color: "red" }} />
          ) : (
            <GppMaybeIcon style={{ color: "grey" }} />
          )}
        </div>
      ),
    },
    {
      name: "Finance Remark",
      selector: "remark",
      cell: (d) => <div className="amt_table">{d.remark}</div>,
      sortable: true,
      width: "240px",
    },
  ];

  const tableData = {
    columns,
    data: context.paymentrequest,
  };

  // console.log(context.paymentrequest, "context.paymentrequest");

  return (
    <>
      <div className="table_data">
        <div className="card_table">
          {/* {token_type == "all" || token_type == "oh" ? (
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              style={{ float: "right" }}
              onClick={() => setShow(true)}
            >
              ADD
            </Button>
          ) : (
            ""
          )} */}

          <DataTableExtensions
            {...tableData}
            export={false}
            filterPlaceholder={`Search`}
            style={{ paddingRight: "25px important" }}
          >
            <DataTable
              columns={columns}
              data={tableData}
              defaultSortFieldId={3}
              pagination
              theme="VendorTable"
              highlightOnHover={true}
              paginationPerPage={5}
            />
          </DataTableExtensions>
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <div className="add_page">
          <PayoutsDataModal
            setShow={setShow}
            callApiupdated={callApiupdated}
            setCallApiUpdated={setCallApiUpdated}
          />
        </div>
      </Modal>
    </>
  );
}

export default PayoutSowTable;
