import React, { useState, useEffect } from "react";
import "../css/AddSow.css";
import { Col, Row, Form, Button } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { sow_training } from "../../utils";
import axios from "axios";
import Swal from "sweetalert2";
import { dataToken } from "../config/token";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import moment from "moment";
import dayjs from "dayjs";

function AddTraining({ sid }) {
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [card, setCard] = useState({});
  const [valueDate, setValueDate] = React.useState("undefined");
  const [valueStartTime, setValueStartTime] = React.useState("undefined");
  const [valueEndTime, setValueEndTime] = React.useState("undefined");
  const [teamCount, setTeamCount] = React.useState(undefined);

  const handleChangeTeamCount = (event) => {
    setTeamCount(event.target.value);
    data.count = event.target.value;
    setData({ ...data });
  };

  const handleChangeEndTime = (newValue) => {
    setValueEndTime(newValue);
    data.end = newValue;

    setData({ ...data });
  };

  const handleChangeStartTime = (newValue) => {
    setValueStartTime(newValue);
    data.start = newValue;
    setData({ ...data });
  };

  const handleChangeDate = (newValue) => {
    setValueDate(newValue);
    data.date = moment(newValue).format("YYYY-MM-DD");
    setData({ ...data });
  };

  const handlesubmit = (event) => {
    event.preventDefault();
    if (!data.title || !data.training_link || !data.count) {
      // console.log(data,'databefore')
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "Please fill all the details",
      });
      return "";
    }

    data.sow_id = sid;
    data.start = moment(data.start["$d"]).format("LTS");
    data.end = moment(data.end["$d"]).format("LTS");
    //  console.log(data,'data')
    axios
      .post(sow_training, data, dataToken)
      .then((res) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `success`,
          showConfirmButton: false,
          timer: 1500,
        });
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1500);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: `${err.response.data.message}`,
        });
      });
  };

  return (
    <div>
      <span className="add_heading">Create Training</span>
      <Form onSubmit={handlesubmit}>
        <Row className="mt-5">
          <Col sx={12} md={12}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <TextField
                id="outlined-basic"
                label="Title"
                variant="outlined"
                fullWidth
                value={data.title}
                onChange={(e) => {
                  data.title = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={12}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <TextField
                id="outlined-basic"
                label="Link"
                value={data.training_link}
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  data.training_link = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sx={12} md={4}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date"
                  inputFormat="MM/DD/YYYY"
                  value={valueDate}
                  minDate={new Date()}
                  onChange={handleChangeDate}
                  renderInput={(params) => (
                    <TextField id="filled-helperText" {...params} />
                  )}
                />
              </LocalizationProvider>
            </Form.Group>
          </Col>
          <Col sx={12} md={4}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Start Time"
                  value={valueStartTime}
                  onChange={handleChangeStartTime}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Form.Group>
          </Col>
          <Col sx={12} md={4}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="End Time"
                  value={valueEndTime}
                  onChange={handleChangeEndTime}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Form.Group>
          </Col>
          <Col sx={12}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Team count
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={teamCount}
                  label="Team Count"
                  onChange={handleChangeTeamCount}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={35}>35</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={45}>45</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
            </Form.Group>
          </Col>
        </Row>
        <Row className="d-flex justify-content-end">
          <Button
            className={"button_submit"}
            type="submit"
            style={{ marginRight: "20px" }}
          >
            Submit
          </Button>
        </Row>
      </Form>
    </div>
  );
}

export default AddTraining;
