import React, { useState, useContext } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import "./Table.css";
import DataTableExtensions from "react-data-table-component-extensions";
import { Link } from "react-router-dom";
import axios from "axios";
import { tsm_list, change_vendor, asm_list, oa_asm_list } from "../../utils";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Modal from "react-bootstrap/Modal";
import InactiveMember from "../Pages/InactiveMember";
import Swal from "sweetalert2";
import { Context } from "../../App";
import { dataToken, token_type } from "../config/token";
import { useNavigate } from "react-router-dom";

createTheme("VendorTable", {
  text: {
    primary: "black",
    secondary: "#b2b2b2",
  },
  background: {
    default: "#fff",
  },
  boxshadow: {
    default: "5px 5px 30px #DEDEDEBF",
  },
  headCells: {
    default: "#64abdd",
  },
  divider: {
    default: "#f4f4f4",
  },
  cell: {
    style: {
      height: "60px !important",
    },
  },

  action: {
    button: "rgba(0,0,0,.54)",
    hover: "#f5f2fd !important",
    disabled: "rgba(0,0,0,.12)",
  },
});

function TableProject({ asm, navData, setAsm }) {
  const [context, setContext] = useContext(Context);

  // const handleMoveVendor = (e) => {
  //   const dataEnter = {
  //     asm_id: e.asm_id,
  //   };
  //   axios
  //     .patch(change_vendor, dataEnter, dataToken)
  //     .then((res) => {
  //       if (res.data.error) {
  //         Swal.fire({
  //           position: "center",
  //           icon: "error",
  //           title: `${res.data.message}`,
  //           showConfirmButton: false,
  //           timer: 1500,
  //         });
  //       } else {
  //         Swal.fire({
  //           position: "center",
  //           icon: "success",
  //           title: `Updated`,
  //           showConfirmButton: false,
  //           timer: 1500,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops!",
  //         text: `${err.response.data.message}`,
  //       });
  //     });
  // };

  // const handleFilter = (e) => {
  //   console.log(e, "first");
  //   const dataEntery = {
  //     status: e,
  //   };

  //   axios
  //     .post(
  //       (token_type == "oh" || token_type == "hr" || token_type == "all") &&
  //         params == "superpartner"
  //         ? asm_list
  //         : (token_type == "oh" || token_type == "hr" || token_type == "all") &&
  //           params == "tsm"
  //         ? tsm_list
  //         : oa_asm_list,
  //       dataEntery,
  //       dataToken,
  //       dataEntery,
  //       dataToken
  //     )
  //     .then((res) => {
  //       context.asm = res.data;

  //       setContext({ ...context });
  //       set_options_open(false);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  // const handleInactive = (data) => {
  //   console.log(data);
  //   setShow(true);
  //   setUserData(data);
  // };

  const columns = [
    {
      name: "Week",
      selector: (d) => d.week,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.week}</div>,
    },
    {
      name: "ASM Count",
      selector: (d) => d.asm_count,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.asm_count}</div>,
    },
    {
      name: "Network",
      selector: (d) => d.network_earning,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.network_earning}</div>,
    },
    {
      name: "Commission",
      selector: (d) => d.commission_earning,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.commission_earning}</div>,
    },
    {
      name: "Total Leads",
      selector: (d) => d.total_lead,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.total_lead}</div>,
      innerWidth: 100,
    },
    {
      name: "Approved Leads",
      selector: (d) => d.approved_leads,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.approved_leads}</div>,
    },
    // {
    //   name: "Status",
    //   center: true,
    //   cell: (d) => <div>Processing</div>,
    // },

    // {
    //   name: "Action",
    //   center: true,
    //   width: "100px",
    //   cell: (d) => (
    //     <div className="div_small">
    //       <Link to={`/profile/${d.asm_id}`}>
    //         <img
    //           src={window.location.origin + "/images/svg/ic_eye.svg"}
    //           alt="profile"
    //           className="view_image"
    //         />
    //       </Link>
    //     </div>
    //   ),
    // },
  ];
  // if (token_type == "hr" || token_type == "all") {
  //   columns.splice(10, 0, {
  //     name: "Options",
  //     center: true,
  //     width: "100px",
  //     cell: (d) => (
  //       <div>
  //         <DropdownButton
  //           as={ButtonGroup}
  //           id={`dropdown-button-drop-start`}
  //           drop={"start"}
  //           variant="secondary"
  //           title={``}
  //         >
  //           <Dropdown.Item
  //             eventKey="0"
  //             onClick={() => navigate(`/profile/${d.asm_id}`)}
  //           >
  //             Profile
  //           </Dropdown.Item>
  //           <Dropdown.Item
  //             eventKey="1"
  //             onClick={() => navigate(`/fse/${d.asm_id}`)}
  //           >
  //             view team
  //           </Dropdown.Item>

  //           <Dropdown.Item eventKey="2" onClick={() => handleInactive(d)}>
  //             {d.onboard_status == "onboarded" ? "Inactive" : "Active"}
  //           </Dropdown.Item>
  //           {/* <Dropdown.Item eventKey="3">Remap</Dropdown.Item> */}
  //           <Dropdown.Item eventKey="4" onClick={() => handleMoveVendor(d)}>
  //             Move Vendor
  //           </Dropdown.Item>
  //         </DropdownButton>
  //       </div>
  //     ),
  //   });
  // }
  const tableData = {
    columns,
    data: context.projectsList,
  };
  return (
    <div className="table_data">
      <div className="card_table">
        <DataTableExtensions
          {...tableData}
          export={false}
          filterPlaceholder={`Search`}
        >
          <DataTable
            columns={columns}
            data={tableData}
            defaultSortFieldId={1}
            pagination
            theme="VendorTable"
            highlightOnHover={true}
          />
        </DataTableExtensions>
      </div>
    </div>
  );
}

export default TableProject;
