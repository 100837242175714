import React, { useEffect, useState } from "react";
import "../css/Addvendor.css";
import { Col, Row, Form, Button } from "react-bootstrap";
import axios from "axios";
import { add_vendor, cm_list } from "../../utils";
import Swal from "sweetalert2";
import { dataToken } from "../config/token";
function AddVendor() {
  const [data, setData] = useState({
    full_name: "",
    mobile_number: "",
    email_id: "",
    dob: "",
    user_type: "",
    pincode: "",
    aadhar_number: "",
  });
  const [cms, setCms] = useState([]);

  useEffect(() => {
    axios
      .get(cm_list, dataToken)
      .then((res) => {
        setCms(res.data.cm);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handlesubmit = (event) => {
    event.preventDefault();
    let dataEnter = { ...data };
    axios
      .post(add_vendor, dataEnter, dataToken)
      .then((res) => {
        if (res.data.error) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${res.data.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "success",
            title: `User ID ${res.data.vendor_id}`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: `${err.response.data.message}`,
        });
      });
  };
  return (
    <div>
      <span className="add_heading">Employee Details</span>
      <Form onSubmit={handlesubmit}>
        <Row>
          <Col sx={12} md={6}>
            <Form.Group className="my-4" controlId="ControlInput">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                placeholder={"Full Name"}
                onChange={(e) => {
                  data.full_name = e.target.value;
                  setData({ ...data });
                }}
                required
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={6}>
            <Form.Group className="my-4" controlId="ControlInput">
              <Form.Label>Contact Number</Form.Label>
              <Form.Control
                type="text"
                minLength={10}
                maxLength={10}
                placeholder={"Contact Number"}
                onChange={(e) => {
                  data.mobile_number = e.target.value;
                  setData({ ...data });
                }}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Email-ID</Form.Label>
              <Form.Control
                type="email"
                placeholder={"Email-ID"}
                onChange={(e) => {
                  data.email_id = e.target.value;
                  setData({ ...data });
                }}
                required
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>DOB</Form.Label>
              <Form.Control
                type="date"
                placeholder={"DOB"}
                onChange={(e) => {
                  data.dob = e.target.value;
                  setData({ ...data });
                }}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Aadhar Number</Form.Label>
              <Form.Control
                type="text"
                minLength={12}
                maxLength={12}
                placeholder={"Aadhar Number"}
                onChange={(e) => {
                  data.aadhar_number = e.target.value;
                  setData({ ...data });
                }}
                required
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Pincode</Form.Label>
              <Form.Control
                type="text"
                placeholder={"Pincode"}
                onChange={(e) => {
                  data.pincode = e.target.value;
                  setData({ ...data });
                }}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>GST Type</Form.Label>
              <Form.Select
                aria-label="Default select example"
                size="lg"
                onChange={(e) => {
                  data.user_type = e.target.value;
                  setData({ ...data });
                }}
                className={"drop_css"}
                required
              >
                <option>Select Type</option>
                <option value="reg_vendor">Registered Vendor</option>
                <option value="unreg_vendor">Unregistered Vendor</option>
                {/* <option value="internal_sp">Internal Superpartner</option> */}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>City Manager</Form.Label>
              <Form.Select
                aria-label="Default select example"
                size="lg"
                onChange={(e) => {
                  data.cm_id = e.target.value;
                  setData({ ...data });
                }}
                className={"drop_css"}
                required
              >
                <option>Select Type</option>
                {cms.map((cm) => (
                  <option key={cm.cm_id} value={cm.cm_id}>
                    {cm.full_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Type</Form.Label>
              <Form.Select
                aria-label="Default select example"
                size="lg"
                onChange={(e) => {
                  data.type = e.target.value;
                  setData({ ...data });
                }}
                className={"drop_css"}
                required
              >
                <option value="">Select Type</option>
                <option value="3">TSM</option>
                <option value="4">Vendor</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sx={12} md={6}></Col>
        </Row>
        <Row className="d-flex justify-content-end">
          <Button
            className={"button_submit "}
            type="submit"
            style={{ marginRight: "20px" }}
            disabled={
              !Object.keys(data).includes("type") ||
              data.type == "undefined" ||
              data.type == ""
                ? true
                : false
            }
          >
            Save
          </Button>
        </Row>
      </Form>
    </div>
  );
}

export default AddVendor;
