import React, { useEffect, useState } from "react";
import "../css/SpProfile.css";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import { single_vendor_details } from "../../utils";
import { useParams } from "react-router-dom";
import { dataToken } from "../config/token";
function SpProfile() {
  const param = useParams();
  const [asmData, setAsmdata] = useState({});
  useEffect(() => {
    const postData = {
      asm_id: param.id,
    };
    axios
      .post(single_vendor_details, postData, dataToken)
      .then((res) => {
        setAsmdata(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  return (
    <div className="sp_profile_total row">
      <div className="col-4 d-flex row">
        <div className="img_total col-4">
          <img
            src={
              asmData?.details?.profile_image.length > 5
                ? asmData?.details?.profile_image.substr(0, 4) === "http"
                  ? asmData?.details?.profile_image
                  : `https://app.backend.taskmo.co/asm_images/${asmData?.details?.profile_image}`
                : window.location.origin + "/images/svg/default_profile.svg"
            }
            alt="ott"
            className="asm_img"
          />
        </div>
        <div className="details_css col-7">
          <div className="row">
            <div className="col-2">
              <div className="icon_bg_css">
                <FeatherIcon icon="user" size={13} />
              </div>
            </div>
            <div className="col-10 align-items-center d-flex">
              <span className="details_css_head">
                {asmData?.details?.full_name}
              </span>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-2">
              <div className="icon_bg_css">
                <FeatherIcon icon="briefcase" size={10} />
              </div>
            </div>
            <div className="col-10 align-items-center d-flex">
              <span className="details_css_head">
                ID- {asmData?.details?.asm_id}
              </span>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-2">
              <div className="icon_bg_css">
                <FeatherIcon icon="phone-call" size={10} />
              </div>
            </div>
            <div className="col-10 align-items-center d-flex">
              <span className="details_css_head">
                {asmData?.details?.mobile_number}
              </span>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-2">
              <div className="icon_bg_css">
                <FeatherIcon icon="mail" size={10} />
              </div>
            </div>
            <div className="col-10 align-items-center d-flex">
              <span className="details_css_head">
                {asmData?.details?.email_id}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-8 row">
        <div className="card_right_css">
          <span className="amt_css">{asmData?.transaction?.raised}</span>
          <span className="amt_sub_css">Raised</span>
        </div>
        <div className="card_right_css">
          <span className="amt_css">{asmData?.transaction?.processed}</span>
          <span className="amt_sub_css">Processed</span>
        </div>
        <div className="card_right_css">
          <span className="amt_css">{asmData?.transaction?.redeemed}</span>
          <span className="amt_sub_css">Redeemed</span>
        </div>
        <div className="card_right_css">
          <span className="amt_css">
            {asmData?.transaction?.commission_earned}
          </span>
          <span className="amt_sub_css">Commission </span>
        </div>
      </div>
    </div>
  );
}

export default SpProfile;
