import React, { useState, useEffect } from "react";
import "../css/AddSow.css";
import { Col, Row, Form, Button } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { current_month_sow, edit_sow, single_sow } from "../../utils";
import axios from "axios";
import Swal from "sweetalert2";
import { dataToken } from "../config/token";
function EditSow(props) {
  const [single, setSingle] = useState({});
  const [data, setData] = useState({});

  useEffect(() => {
    const data = {
      sow_id: props.sow,
    };
    setData({ ...data });
    if (props.sow > 0) {
      axios
        .get(single_sow + `?sow_id=${props.sow}`, dataToken)
        .then((res) => {
          setSingle(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const handlesubmit = (event) => {
    event.preventDefault();

    axios
      .patch(edit_sow, data, dataToken)
      .then((res) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `success`,
          showConfirmButton: false,
          timer: 1500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })

      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: `${err.response.data.message}`,
        });
      });
  };

  return (
    <div>
      <span className="add_heading">Sow Edit Detials</span>
      <Form onSubmit={handlesubmit}>
        <Row className="mt-5">
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Project Title</Form.Label>
              <Form.Control
                type="text"
                defaultValue={single.project_title}
                onChange={(e) => {
                  data.project_title = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Vertical</Form.Label>
              <Form.Select
                aria-label="Default select example"
                size="lg"
                onChange={(e) => {
                  data.job_category = e.target.value;
                  setData({ ...data });
                }}
                style={{ textTransform: "uppercase" }}
                className={"drop_css"}
                defaultValue={single.job_category}
                required
              >
                <option value={single.job_category} disabled>
                  {single.job_category?.replace("_", "")}
                </option>
                <option value="hyp_fot">HYPFOT</option>
                <option value="hyp_dat">HYPDAT</option>
                <option value="dig_cxt">DIGCXT</option>
                <option value="dig_mot">DIGMOT</option>
                <option value="hyp_vet">HYPVET</option>
                <option value="hyp_ret">HYPRET</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                defaultValue={single.start_date}
                onChange={(e) => {
                  data.start_date = e.target.value;
                  setData({ ...data });
                }}
                required
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>End Date</Form.Label>
              {/*<Form.Control
                type="text"
                defaultValue={single.end_date}
                onChange={(e) => {
                  data.end_date = e.target.value;
                  setData({ ...data });
                }}
              />*/}
              <Form.Control
                type="date"
                defaultValue={single.end_date}
                onChange={(e) => {
                  data.end_date = e.target.value;
                  setData({ ...data });
                }}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sx={12} md={6}>
            {/* <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>QC Number</Form.Label>

              <Form.Control
                type="text"
                // placeholder={single.end_date}
                defaultValue={single.qc_number}
                onChange={(e) => {
                  data.qc_number = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group> */}
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Trending</Form.Label>
              <Form.Select
                aria-label="Default select example"
                size="lg"
                onChange={(e) => {
                  data.is_trending = e.target.value;
                  setData({ ...data });
                }}
                className={"drop_css"}
                defaultValue={single.is_trending}
              >
                <option
                  value={single.is_trending}
                  
                >
                  {single.is_trending}
                </option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
               
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Payout Type</Form.Label>
              <Form.Select
                aria-label="Default select example"
                size="lg"
                onChange={(e) => {
                  data.pay_type = e.target.value;
                  setData({ ...data });
                }}
                className={"drop_css"}
              >
                <option
                  value={single.pay_type}
                  style={{ textTransform: "capitalize" }}
                  disabled
                >
                  {single.pay_type}
                </option>
                <option value="weekly">Weekly</option>
                <option value="fortnightly">Fort Nightly</option>
                <option value="monthly">Monthly</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>UTM Link</Form.Label>

              <Form.Control
                type="text"
                // placeholder={single.end_date}
                defaultValue={single.utm_link}
                onChange={(e) => {
                  data.utm_link = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>CPL</Form.Label>
              <Form.Control
                type="text"
                // placeholder={single.end_date}
                defaultValue={single.xleads}
                onChange={(e) => {
                  data.xleads = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Category</Form.Label>
              <Form.Select
                aria-label="Default select example"
                size="lg"
                onChange={(e) => {
                  data.vertical = e.target.value;
                  setData({ ...data });
                }}
                className={"drop_css"}
                required
              >
                <option
                  value={single.vertical}
                  style={{ textTransform: "capitalize" }}
                  disabled
                >
                  {single.vertical}
                </option>
                <option value="field">Field</option>
                <option value="remote">Remote</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sx={12} md={6}>
            {/* <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>OOV</Form.Label>
              <Form.Control
                type="text"
                // placeholder={single.end_date}
                defaultValue={single.OOV}
                onChange={(e) => {
                  data.OOV = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group> */}
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Enable Status</Form.Label>
              <Form.Select
                aria-label="Default select example"
                size="lg"
                onChange={(e) => {
                  data.enable_for = e.target.value;
                  setData({ ...data });
                }}
                className={"drop_css"}
                required
                defaultValue={single.enable_for}
              >
                <option
                  value={single.enable_for}
                  style={{ textTransform: "capitalize" }}
                  disabled
                >
                  {single.enable_for}
                </option>
                <option value="none">None</option>
                <option value="open_network">Open Network</option>
                <option value="close_network">Close Network</option>
                <option value="both">Both</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        {/* <Row>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Sow Status</Form.Label>
              <Form.Select
                aria-label="Default select example"
                size="lg"
                onChange={(e) => {
                  data.add_lead_status = e.target.value;
                  setData({ ...data });
                }}
                className={"drop_css"}
                required
              >
                <option
                  value={single.add_lead_status}
                  style={{ textTransform: "capitalize" }}
                  disabled
                >
                  {single.add_lead_status}
                </option>
                <option value="enable">Enable</option>
                <option value="disable">Disable</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sx={12} md={6}></Col>
        </Row> */}
         <Row>
        <Col sx={12} md={6}>
            {/* <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>OOV</Form.Label>
              <Form.Control
                type="text"
                // placeholder={single.end_date}
                defaultValue={single.OOV}
                onChange={(e) => {
                  data.OOV = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group> */}
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Active Sow Type</Form.Label>
              <Form.Select
                aria-label="Default select example"
                size="lg"
                onChange={(e) => {
                  data.type= e.target.value;
                  setData({ ...data });
                }}
                className={"drop_css"}
                required
                defaultValue={single.type}
              >
                <option
                  value=""
                  disabled
                >
                  Select Type
                </option>
                <option value="1">Merchant Acquisition</option>
                <option value="2">Partner Onboarding</option>
                <option value="3">Finance</option>
                <option value="4">Seller Onboarding</option>
                <option value="5">Customer Acquisition</option>
                <option value="6">Data Moderation</option>
                <option value="7">TeleCalling</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sx={12} md={12}>
            <Form.Group className="my-1" controlId="ControlInputAdd">
              <label htmlFor="ControlInputAdd" className="label_edit_sow">
              Whom to sell?
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={single.project_desc}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  // editor.getData();
                  // console.log(editor.getData(), "editor.getData");
                  data.project_desc = editor.getData();
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col sx={12} md={12}>
            <Form.Group className="my-1" controlId="ControlInputAdd">
              <label htmlFor="ControlInputAdd" className="label_edit_sow">
              How to sell?
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={single.payout_criteria}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  data.payout_criteria = editor.getData();
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sx={12} md={12}>
            <Form.Group className="my-1" controlId="ControlInputAdd">
              <label htmlFor="ControlInputAdd" className="label_edit_sow">
              Terms and conditions
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={single.other_terms}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  // editor.getData();
                  // console.log(editor.getData(), "editor.getData");
                  data.other_terms = editor.getData();
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="d-flex justify-content-end">
          <Button
            className={"button_submit"}
            type="submit"
            style={{ marginRight: "20px" }}
          >
            Save
          </Button>
        </Row>
      </Form>
    </div>
  );
}

export default EditSow;
