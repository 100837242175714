import React, { useState } from "react";
import "../css/AddSow.css";
import { Col, Row, Form, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { status_change } from "../../utils";
import { dataToken } from "../config/token";

function InactiveMember(props) {
  const [data, setData] = useState({});
  const params = window.location.pathname.replace("/", "");
  const user = props.userData;

  const handlesubmit = (event) => {
    event.preventDefault();

    let action = "";
    if (user.onboard_status == "onboarded") {
      action = "inactive";
    } else {
      action = "active";
    }
    const dataEnter = {
      user_type: user.user_type,
      user_id: user.asm_id,
      action_type: action,
      comment: data.comment,
    };
    axios
      .post(status_change, dataEnter, dataToken)
      .then((res) => {
        props.setShow(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: `success`,
          showConfirmButton: false,
          timer: 1500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: `${err.response.data.message}`,
        });
      });
  };

  return (
    <div>
      <span className="add_heading">
        {user.onboard_status == "onboarded" ? "Inactive" : "Active"} {params}
      </span>
      <Form onSubmit={handlesubmit}>
        <Row className="my-4">
          <Col sx={12} md={12}>
            <Form.Label>Comment</Form.Label>

            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              onChange={(e) => {
                data.comment = e.target.value;
                setData({ ...data });
              }}
            />
          </Col>
        </Row>
        <Row className="d-flex justify-content-end">
          <Button
            className={"button_submit"}
            type="submit"
            style={{ marginRight: "20px" }}
          >
            Save
          </Button>
        </Row>
      </Form>
    </div>
  );
}

export default InactiveMember;
