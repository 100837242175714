import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  getAllProjects,
  verify_otp,
  confirm_sow_enable,
  token_extract,
} from "../../utils";
import { dataToken } from "../config/token";
import "../css/MerchantOtp.css";
import Swal from "sweetalert2";
import TextareaAutosize from "@mui/material/TextareaAutosize";

function MerchantOtp({ hideData, sowId, setShow3, status }) {
  // console.log(hideData, "hideData");
  const [otpOn, setOtpOn] = useState(true);
  const [otpData, setOtpData] = useState("");
  const [selectProj, setSelectProj] = useState([]);
  const [confirmEnableOtp, setConfirmEnableOtp] = useState("");
  const [remarkData, setRemarkData] = useState(" ");

  useEffect(() => {
    axios
      .get(getAllProjects)
      .then((res) => {
        // console.log(res.data, "otp data");
        setSelectProj(res.data.projects);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [companyName, setCompanyName] = useState({
    project_name: "",
    mobile_number: "",
  });

  let { project_name = "", mobile_number = "" } = companyName;

  const handleChangeOtp = (e) => {
    setCompanyName({
      ...companyName,
      [e.target.name]: [e.target.value].toString(),
    });
  };
  const handleSendOtp = async () => {
    try {
      const data = {
        project_name: companyName.project_name,
        mobile_number: companyName.mobile_number,
      };
      const otp = await axios.post(verify_otp, {
        project_name: companyName.project_name,
        mobile_number: companyName.mobile_number,
      });
      setOtpOn(false);
      setOtpData(otp.data.otp.verification_code);
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmSowEnable = () => {
    const postdata = {
      sow_id: sowId,
      otp: confirmEnableOtp,
      type: "sow",
      remark: remarkData,
      action_type: status === "enable" ? "closed" : "enable",
    };
    axios
      .post(confirm_sow_enable, postdata, dataToken)

      .then((res) => {
        setShow3(false);
        // console.log(res.data,"res.data")
        if (res.data.error) {
          Swal.fire({
            position: "center",
            icon: "warning",
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "success",
            title: status === "enable" ? "Deactivated" : "Activated",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setShow3(false);
        Swal.fire({
          position: "center",
          icon: "warning",
          title: err.response?.data?.message?.message,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  return (
    <div className="model_contain3" style={{ minHeight: "425px" }}>
      {otpOn ? (
        <div className="model_contain3">
          <img
            src={window.location.origin + "/images/gif/otp.gif"}
            alt="profile"
            className="otp_gif"
          />
          {!hideData && (
            <>
              <label htmlFor="select_label" className="label_add_sow">
                select Project
              </label>
              <select
                className="select_download"
                name="project_name"
                value={project_name}
                onChange={handleChangeOtp}
                required
              >
                <option value="" disabled>
                  select Project
                </option>
                {selectProj.map((project) => (
                  <option value={project.company_name} key={project.project_id}>
                    {project.company_name}
                  </option>
                ))}
              </select>
              <label
                htmlFor="select_label"
                className="label_add_sow"
                style={{ marginTop: "20px" }}
              >
                Enter Mobile Number
              </label>
              <input
                className="input_add_otp"
                type="text"
                name="mobile_number"
                value={mobile_number}
                onChange={handleChangeOtp}
                minLength={10}
                maxLength={10}
                required
              />
            </>
          )}
          {hideData && (
            <>
              <div className="hideDataFirstInput">
                <div> Enter OTP:</div>
                <div>
                  {" "}
                  <input
                    className="hideDataFirstInputStyle"
                    id="enterotp"
                    type="text"
                    placeholder="Enter otp"
                    required
                    onChange={(e) => setConfirmEnableOtp(e.target.value)}
                  />
                </div>
              </div>
              <div className="hideDataSecondInput">
                <div>
                  {" "}
                  <label htmlFor="name">Remark Data:</label>
                </div>
                <div>
                  {" "}
                  <TextareaAutosize
                    aria-label="empty textarea"
                    placeholder="Enter Remark"
                    minRows={3}
                    style={{ width: 200 }}
                    onChange={(e) => setRemarkData(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}
          <div className="sendButton">
            <Button
              className="button_submit m-4"
              onClick={hideData ? handleConfirmSowEnable : handleSendOtp}
            >
              <p className="d1_p42 ">SEND</p>
            </Button>
          </div>
        </div>
      ) : (
        <div className="model_contain3">
          <img
            src={window.location.origin + "/images/gif/blast2.gif"}
            alt="profile"
            className="blast_gif"
          />
          <p className="otp_VERIFY">{otpData}</p>
        </div>
      )}
    </div>
  );
}

export default MerchantOtp;
