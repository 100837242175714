import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Modal from "react-bootstrap/Modal";
import FeatherIcon from "feather-icons-react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Zoom from "@mui/material/Zoom";

import "./NavBar.css";

import Moda from "@material-ui/core/Modal";
import FseSearch from "../Pages/FseSearch";
import { dataToken, token_type } from "../config/token";
import { download_sow_report, oa_details, token_extract } from "../../utils";
import MerchantOtp from "../Pages/MerchantOtp";
import ProfileCard from "../Pages/ProfileCard";
import axios from "axios";
import LeadApproved from "../Pages/LeadApproved";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

function NavBar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4,setShow4] = useState(false)
  const [userData, setUserdata] = useState({});
  const [userEmail,setUserEmail] = useState('')

  useEffect(() => {
    axios
      .get(oa_details, dataToken)
      .then((res) => {
        setUserdata(res.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(()=>{
    gettheTokenData()
  },[])

  const gettheTokenData = async()=>{
    try {
      let data = await axios.get(token_extract,dataToken)
      let {data: {email_id}} = data
      console.log(email_id,'useremailid')
      setUserEmail(email_id)
    } catch (error) {
      console.log(error,'error')
    }
  }

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    localStorage.removeItem("vendor-token");
    localStorage.removeItem("type");
    window.location.reload();
  };

  return (
    <div className="nav_head">
      <div className="threeline">
        <h2 className="text-left header">Super Partner Onboarding</h2>
      </div>

      <div className="nav_profile">

     {userEmail=='vishnu.ananthan@taskmo.com' ? <LightTooltip TransitionComponent={Zoom} title="Lead Approved">
          <Button onClick={() => setShow4(true)}>
            <div className="profile">
            <div className="profile">LA</div>
            </div>
          </Button>
        </LightTooltip>:''}
        <LightTooltip TransitionComponent={Zoom} title="Merchant OTP">
          <Button onClick={() => setShow1(true)}>
            <div className="profile">
              <FeatherIcon icon="key" className="text-white" />
            </div>
          </Button>
        </LightTooltip>
        {token_type == "oh" || token_type == "all" ? (
          <LightTooltip TransitionComponent={Zoom} title="Download sow report">
            <div className="profile">
              <a href={download_sow_report}>
                <FeatherIcon icon="download" className="text-white" />
              </a>
            </div>
          </LightTooltip>
        ) : (
          <></>
        )}
        {token_type == "hr" || token_type == "all" ? (
          <LightTooltip TransitionComponent={Zoom} title="Search FSE">
            <Button onClick={() => setShow(true)}>
              <div className="profile">FSE</div>
            </Button>
          </LightTooltip>
        ) : (
          <></>
        )}

        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {userData?.profile_image ? (
            <img className="profile_1" src={userData.profile_image} alt="" />
          ) : (
            <div className="profile">
              {userData.full_name ? userData.full_name[0] : ""}
            </div>
          )}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setShow2(true);
            }}
          >
            <div>
              <FeatherIcon icon="user" className="text-dark" />
              <span className="css_text_menu">Profile</span>
            </div>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              handleLogout();
            }}
          >
            <div className="logout_css">
              <FeatherIcon icon="power" className="text-dark" />
              <span className="css_text_menu">Logout</span>
            </div>
          </MenuItem>
        </Menu>
      </div>
      {show4 && <Modal show={show4} onHide={() => setShow4(false)}>
        <LeadApproved setShow={setShow4} />
      </Modal>}
      <Modal show={show} onHide={() => setShow(false)}>
        <FseSearch setShow={setShow} />
      </Modal>
      <Modal show={show1} onHide={() => setShow1(false)}>
        <MerchantOtp setShow={setShow1} />
      </Modal>

      <Modal show={show3} onHide={() => setShow3(false)}>
        <MerchantOtp setShow={setShow1} />
      </Modal>
      <Moda
        open={show2}
        onClose={() => setShow2(false)}
        className="model_contain"
      >
        <ProfileCard setShow={setShow2} />
      </Moda>
    </div>
  );
}

export default NavBar;
