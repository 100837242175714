import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../App";
import { token_type } from "../config/token";
import "./side.css";
function Sidebar() {
  const navigate = useNavigate();
  const [context, setContext] = useContext(Context);

  useEffect(() => {
    if (window.location.pathname === "/onboarding") {
      handleClick(0);
    } else if (window.location.pathname === "/dashboard") {
      handleClick(2);
    } else if (window.location.pathname === "/tsm") {
      handleClick(1);
    } else if (window.location.pathname === "/sow") {
      handleClick(3);
    } else if (window.location.pathname === "/training") {
      handleClick(4);
    }
  }, [window.location.pathname]);

  const handleClick = (e) => {
    let swap = [];
    for (let i = 0; i < context.sidebar.length; i++) {
      if (i === e) {
        swap.push(false);
      } else {
        swap.push(true);
      }
    }
    context.sidebar = [...swap];
    setContext({ ...context });
  };

  const handleLogout = () => {
    localStorage.removeItem("vendor-token");
    localStorage.removeItem("type");
    window.location.reload();
  };

  return (
    <div className="sidebar">
      <div>
        <div className="side_row1 d-flex justify-content-center">
          <img
            src={window.location.origin + "/images/tmlogo.svg"}
            alt="ott"
            className="logo"
          />
        </div>
        <div
          onClick={() => {
            handleClick(2);
            navigate("/dashboard");
          }}
          className={
            context.sidebar[2]
              ? "side_tabs d-flex justify-content-between"
              : "side_tabs1 d-flex justify-content-between"
          }
        >
          <div>
            {!context.sidebar[2] ? (
              <img
                src={
                  window.location.origin +
                  "/images/sidebar/home_active_icon.svg"
                }
                alt="ott"
                className="paper_icon"
              />
            ) : (
              <img
                src={
                  window.location.origin + "/images/sidebar/home_grey_icon.svg"
                }
                alt="ott"
                className="paper_icon"
              />
            )}
            <span
              className="side_text"
              style={{
                color: `${context.sidebar[2] ? "#CFCFCF" : "#121E2E"}`,
              }}
            >
              Dashboard
            </span>
          </div>
        </div>
        <div
          onClick={() => {
            handleClick(0);
            navigate("/superpartner");
          }}
          className={
            context.sidebar[0]
              ? "side_tabs d-flex justify-content-between"
              : "side_tabs1 d-flex justify-content-between"
          }
        >
          <div>
            {!context.sidebar[0] ? (
              <img
                src={
                  window.location.origin +
                  "/images/sidebar/superpartner_active_icon.svg"
                }
                alt="ott"
                className="paper_icon"
              />
            ) : (
              <img
                src={
                  window.location.origin +
                  "/images/sidebar/superpartner_grey_icon.svg"
                }
                alt="ott"
                className="paper_icon"
              />
            )}
            <span
              className="side_text"
              style={{
                color: `${context.sidebar[0] ? "#CFCFCF" : "#121E2E"}`,
              }}
            >
              Super Partner
            </span>
          </div>
        </div>

        {token_type == "hr" || token_type == "all" ? (
          <>
            <div
              onClick={() => {
                handleClick(1);
                navigate("/tsm");
              }}
              className={
                context.sidebar[1]
                  ? "side_tabs d-flex justify-content-between"
                  : "side_tabs1 d-flex justify-content-between"
              }
            >
              <div>
                {!context.sidebar[1] ? (
                  <img
                    src={
                      window.location.origin +
                      "/images/sidebar/tsm_active_icon.svg"
                    }
                    alt="ott"
                    className="paper_icon"
                  />
                ) : (
                  <img
                    src={
                      window.location.origin +
                      "/images/sidebar/tsm_grey_icon.svg"
                    }
                    alt="ott"
                    className="paper_icon"
                  />
                )}
                <span
                  className="side_text"
                  style={{
                    color: `${context.sidebar[1] ? "#CFCFCF" : "#121E2E"}`,
                  }}
                >
                  TSM
                </span>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        <div
          onClick={() => {
            handleClick(3);
            navigate("/sow");
          }}
          className={
            context.sidebar[3]
              ? "side_tabs d-flex justify-content-between"
              : "side_tabs1 d-flex justify-content-between"
          }
        >
          <div>
            {!context.sidebar[3] ? (
              <img
                src={
                  window.location.origin + "/images/sidebar/sow_active_icon.svg"
                }
                alt="ott"
                className="paper_icon"
              />
            ) : (
              <img
                src={
                  window.location.origin + "/images/sidebar/sow_grey_icon.svg"
                }
                alt="ott"
                className="paper_icon"
              />
            )}
            <span
              className="side_text"
              style={{
                color: `${context.sidebar[3] ? "#CFCFCF" : "#121E2E"}`,
              }}
            >
              SOW
            </span>
          </div>
        </div>
        {/* <div
          onClick={() => {
            handleClick(4);
            navigate("/training");
          }}
          className={
            context.sidebar[4]
              ? "side_tabs d-flex justify-content-between"
              : "side_tabs1 d-flex justify-content-between"
          }
        >
          <div>
            {!context.sidebar[4] ? (
              <img
                src={
                  window.location.origin + "/images/sidebar/training_icon.svg"
                }
                alt="ott"
                className="paper_icon"
              />
            ) : (
              <img
                src={
                  window.location.origin + "/images/sidebar/training_icon_grey.svg"
                }
                alt="ott"
                className="paper_icon"
              />
            )}
            <span
              className="side_text"
              style={{
                color: `${context.sidebar[4] ? "#CFCFCF" : "#121E2E"}`,
              }}
            >
              Training
            </span>
          </div>
        </div> */}
        {token_type == "oh" || token_type == "all" ? (
          <>
            <div
              onClick={() => {
                handleClick(5);
                navigate("/oa-associate");
              }}
              className={
                context.sidebar[5]
                  ? "side_tabs d-flex justify-content-between"
                  : "side_tabs1 d-flex justify-content-between"
              }
            >
              <div>
                {!context.sidebar[5] ? (
                  <img
                    src={
                      window.location.origin +
                      "/images/sidebar/oa_list_icon.svg"
                    }
                    alt="ott"
                    className="paper_icon"
                  />
                ) : (
                  <img
                    src={
                      window.location.origin +
                      "/images/sidebar/oa_list_icon_grey.svg"
                    }
                    alt="ott"
                    className="paper_icon"
                  />
                )}
                <span
                  className="side_text"
                  style={{
                    color: `${context.sidebar[5] ? "#CFCFCF" : "#121E2E"}`,
                  }}
                >
                  OA LIST
                </span>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      {/* <div
        onClick={() => {
          handleClick(4);
          handleLogout();
        }}
        className={
          context.sidebar[4]
            ? "side_tabs d-flex justify-content-between"
            : "side_tabs1 d-flex justify-content-between"
        }
      >
        <div>
          <img
            src={window.location.origin + "/images/power.svg"}
            alt="power"
            className="paper_icon"
          />
          <span className="side_text" style={{ color: "red" }}>
            Log Out
          </span>
        </div>
      </div> */}
    </div>
  );
}
export default Sidebar;
