import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import {
  current_month_sow,
  oa_current_sow,
  oa_list,
  open_sow_list,
} from "../../utils";

import { dataToken, token_type } from "../config/token";
import { Context } from "../../App";
import TableTraining from "../Table/TableTraining";
import TableSow from "../Table/TableSow";
import AssociateTable from "../Table/AssociateTable";

const OaAssociate = () => {
  const [updatedActive, setUpdatedActive] = React.useState(false);
  const [oaList, setOaList] = React.useState(undefined);
  const [addedData, setAddedData] = React.useState(false);
  const [editedSingleData, setEditedSingleData] = React.useState(false);

  useEffect(() => {
    axios
      .get(oa_list, dataToken)
      .then((res) => {
        // console.log(res.data, "resdata");
        setOaList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [updatedActive, addedData, editedSingleData]);
  // console.log("testingkit");
  return (
    <div className="px-3">
      <Row className="mt-4">
        <h1 className="h1_header">Associate</h1>

        {oaList && (
          <AssociateTable
            data={oaList}
            addedData={addedData}
            setAddedData={setAddedData}
            setUpdatedActive={setUpdatedActive}
            updatedActive={updatedActive}
            setEditedSingleData={setEditedSingleData}
            editedSingleData={editedSingleData}
          />
        )}
      </Row>
    </div>
  );
};

export default OaAssociate;
