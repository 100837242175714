import React, { useEffect, useState, useContext } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import CountUp from "react-countup";
import axios from "axios";
import "../css/Widget.css";
import { onboarded_team_tsm } from "../../utils";
import { Context } from "../../App";
import { dataToken } from "../config/token";

const WidgetEarning = () => {
  const [stats, setStats] = useState({});
  const [context, setContext] = useContext(Context);

  return (
    <React.Fragment>
      <Row>
        <Col md={3}>
          <Card className="card-animate card_shadow card_css_height">
            <CardBody>
              <div className="d-flex">
                <div className="left_card_css_col">
                  <p className="fw-medium text-muted mb-0">Assigned Network</p>
                  <h2 className="mt-4 ff-secondary fw-semibold">
                    <span className="counter-value text-success">
                      <CountUp
                        start={0}
                        end={context.projectCard?.assigned_network}
                        decimals={0}
                        duration={2}
                      />
                    </span>
                  </h2>
                </div>
                <div className="icon_css_total">
                  <img
                    src={window.location.origin + "/images/svg/path.svg"}
                    alt="profile"
                    width="100px"
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={3}>
          <Card className="card-animate card_shadow card_css_height">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div className="left_card_css_col">
                  <p className="fw-small text-muted mb-0">
                    Assigned N/w Earning
                  </p>
                  <h2 className="mt-4 ff-secondary fw-semibold">
                    <span className="counter-value text-danger">
                      <CountUp
                        start={0}
                        end={context.projectCard?.network_earning}
                        decimals={0}
                        duration={2}
                      />
                    </span>
                  </h2>
                </div>
                <div className="icon_css_total">
                  <img
                    src={window.location.origin + "/images/svg/pathred.svg"}
                    alt="profile"
                    width="100px"
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={3}>
          <Card className="card-animate card_shadow card_css_height">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div className="left_card_css_col">
                  <p className="fw-medium text-muted mb-0">Commission Earned</p>
                  <h2 className="mt-4 ff-secondary fw-semibold">
                    <span
                      className="counter-value "
                      style={{ color: "#DFA800" }}
                    >
                      <CountUp
                        start={0}
                        end={context.projectCard?.commission_earning}
                        decimals={0}
                        duration={2}
                      />
                    </span>
                  </h2>
                </div>
                <div className="icon_css_total">
                  <img
                    src={window.location.origin + "/images/svg/pathyellow.svg"}
                    alt="profile"
                    width="100px"
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={3}>
          <Card className="card-animate card_shadow card_css_height">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div className="left_card_css_col">
                  <p className="fw-medium text-muted mb-0">Approved leads</p>
                  <h2 className="mt-4 ff-secondary fw-semibold">
                    <span className="counter-value text-primary">
                      <CountUp
                        start={0}
                        end={context.projectCard?.approved_leads}
                        decimals={0}
                        duration={2}
                      />
                    </span>
                  </h2>
                </div>
                <div className="icon_css_total">
                  <img
                    src={window.location.origin + "/images/svg/pathblue.svg"}
                    alt="profile"
                    width="100px"
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default WidgetEarning;
