import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { current_month_sow, oa_current_sow } from "../../utils";
import TableSow from "../Table/TableSow";
import { dataToken, token_type } from "../config/token";
import { Context } from "../../App";

function Sow() {
  const [context, setContext] = useContext(Context);

  useEffect(() => {
    if (token_type == "oh" || token_type == "all" || token_type == "hr") {
      const dataURL = current_month_sow + "?add_lead_status=enable";

      axios
        .get(dataURL, dataToken)
        .then((res) => {
          // setSow(res.data);
          context.sow = res.data;
          setContext({ ...context });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const dataURL = oa_current_sow + "?add_lead_status=enable";

      axios
        .get(dataURL, dataToken)
        .then((res) => {
          // setSow(res.data);
          context.sow = res.data;
          setContext({ ...context });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  return (
    <div className="px-3">
      <Row className="mt-4">
        <h1 className="h1_header">Sow's</h1>
        <TableSow />
      </Row>
    </div>
  );
}

export default Sow;
