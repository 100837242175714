import React, { useState } from "react";
import "../css/addsowsp.css";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import { search_vendor, vendor_sow_assign } from "../../utils";
import { dataToken } from "../config/token";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Swal from "sweetalert2";

function AddSowSp({ sowId, setShow }) {
  const [type, setType] = useState(["Mobile Number", "mobile_number"]);
  const [data, setData] = useState("");
  const [asm, setAsm] = useState({});
  const [singleAsm, setSingleAsm] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (a) => {
    setOpen(true);
    setSingleAsm(a);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNo = () => {};
  const handleYes = () => {
    const postData = {
      asm_id: singleAsm.asm_id,
      sow_id: sowId[0],
      project_id: sowId[1],
    };
    // console.log(postData);
    // axios
    //   .post(vendor_sow_assign, postData, dataToken)
    //   .then((res) => {
    //     setShow(false);
    //     Swal.fire({
    //       position: "center",
    //       icon: "success",
    //       title: "SOW Assigined",
    //       showConfirmButton: false,
    //       timer: 1500,
    //     });
    //   })
    //   .catch((e) => {
    //     setShow(false);
    //     Swal.fire({
    //       position: "center",
    //       icon: "warning",
    //       title: e.response.data.message,
    //       showConfirmButton: false,
    //       timer: 1500,
    //     });
    //   });
  };

  const handleSearch = () => {
    const postData = {};
    postData[type[1]] = data;
    axios
      .post(search_vendor, postData, dataToken)
      .then((res) => {
        if (res.data.error) {
          setShow(false);
          Swal.fire({
            position: "center",
            icon: "error",
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          setAsm(res.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="total_add_sow_sp">
      <div className="heading_addspsow">
        <div className="d-flex align-items-center">
          <span className="heading_addsp">Add Super Partner</span>
          <span className="sow_css_right">{sowId[0]}</span>
        </div>
        <div className="d-flex">
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {type[0]}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <span
                className="dropdown-item"
                onClick={() => setType(["Mobile Number", "mobile_number"])}
              >
                Mobile Number
              </span>
              <span
                className="dropdown-item"
                onClick={() => setType(["Aadhar Number", "aadhar_number"])}
              >
                Aadhar Number
              </span>
              <span
                className="dropdown-item"
                onClick={() => setType(["SP-ID", "asm_id"])}
              >
                SP-ID
              </span>
              <span
                className="dropdown-item"
                onClick={() => setType(["Email-ID", "email_id"])}
              >
                Email-ID
              </span>
            </div>
          </div>
          <div className="input-group">
            <div className="form-outline">
              <input
                type="search"
                id="form1"
                className="form-control"
                placeholder="Search"
                onChange={(e) => {
                  setData(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
            </div>
            <button
              type="button"
              className="btn btn-light"
              onClick={handleSearch}
            >
              <FeatherIcon icon="search" className="text-secondary" />
            </button>
          </div>
        </div>
      </div>
      {Object.keys(asm).length > 0 ? (
        <>
          <div className="total_grid w-100">
            <div className="user_card_total m-3 col-md-3" key={asm.asm_id}>
              <div className="status">
                <span
                  className="text_bg"
                  style={
                    asm.onboard_status == "none"
                      ? { backgroundColor: "#E2F9FD", color: "#3EA2B4" }
                      : asm.onboard_status == "inactive"
                      ? { backgroundColor: "#FFE1E1", color: "red" }
                      : null
                  }
                >
                  {asm.onboard_status}
                </span>
              </div>
              <div>
                <img
                  alt="ott"
                  className="profile_img"
                  src={
                    asm?.profile_image?.length > 5
                      ? asm?.profile_image.substr(0, 4) === "http"
                        ? asm.profile_image
                        : `https://app.backend.taskmo.co/asm_images/${asm.profile_image}`
                      : window.location.origin +
                        "/images/svg/default_profile.svg"
                  }
                />
              </div>
              <span className="user_name">{asm.full_name}</span>
              <div className="position_outline">
                <span className="pos_text">{asm.user_type}</span>
              </div>
              <span className="loc_css">{asm.city}</span>
              <span className="loc_css">Aadhar:{asm.aadhar_number}</span>
              <span className="loc_css">Number:{asm.mobile_number}</span>
              <div className="line"></div>
              <div className="d-flex flex-wrap justify-content-around mx-4">
                {asm.sow.map((res) => {
                  return (
                    <div className="row m-1">
                      <div className="view_outline m-0">
                        <span className="view_text1">{res}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="d-flex justify-content-between w-75">
                <div className="view_outline ">
                  <Link to={`/fse/${asm.asm_id}`}>
                    <span className="view_text">View Team</span>
                  </Link>
                </div>
                <div
                  className="view_outline"
                  onClick={() => handleClickOpen(asm)}
                >
                  <span className="view_text">Add Sow</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to
            <span
              className="base-card card-green"
              style={{ marginLeft: "0px" }}
            >
              {" "}
              assign{" "}
            </span>
            SOW {sowId} to{" "}
            <span style={{ textTransform: "capitalize" }}>
              {singleAsm.full_name}
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleNo();
              handleClose();
            }}
            color="primary"
            autoFocus
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleYes();
              handleClose();
            }}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddSowSp;
