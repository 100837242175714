import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Context } from "../../App";
import { project_card, single_sow } from "../../utils";
import BargraphEarnTrack from "../Chart/Bargraphdash/BargraphEarnTrack";
import BargraphNetworkEarn from "../Chart/Bargraphdash/BargraphNetworkEarn";
import { dataToken } from "../config/token";
import TableProject from "../Table/TableProject";
import WidgetEarning from "./WidgetEarnings";
import Modal from "react-bootstrap/Modal";
import BarGraphHourly from "../Chart/Bargraphdash/BarGraphHourly";
import SowDetails from "./SowDetails";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "../css/ProjectStats.css";
import FseSowTable from "../Table/FseSowTable";
import PayoutSowTable from "../Table/PayoutSowTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  // console.log(props, "props");

  return (
    <div>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ProjectStats() {
  const [context, setContext] = useContext(Context);
  const [brand, setBrand] = useState([]);
  const [show, setShow] = useState(false);
  const [single, setSingle] = useState({});

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const params = useParams();

  useEffect(() => {
    const dataEnter = {
      sow_id: params.id,
      asm_id: params.id2,
    };
    axios
      .post(project_card, dataEnter, dataToken)
      .then((res) => {
        setBrand(res.data.card_data);
        context.projectCard = res.data.card_data;
        context.projectsList = res.data.finalData;
        setContext({ ...context });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="px-3">
      <Row className="d-felx justify-content-between my-3">
        <Col
          md={5}
          className="d-flex align-items-center justify-content-start"
          style={{ cursor: "pointer" }}
          onClick={() => setShow(true)}
        >
          <img
            src={brand?.brand_logo}
            alt="ott"
            className="logo_projects mx-3"
          />
          <p className="text-capatilize m-0">{brand.project_title}</p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <WidgetEarning />
      </Row>
      {/* <Row className="px-3 mt-4">
        <BarGraphHourly />
      </Row> */}

      <Row className="px-3 mt-4">
        <BargraphEarnTrack />
      </Row>
      <Row className="px-3 mt-4">
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Project Data" {...a11yProps(0)} />
              <Tab label="Fse Data" {...a11yProps(1)} />
              <Tab label="Payouts Data" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <TableProject />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <FseSowTable />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <PayoutSowTable />
          </TabPanel>
        </Box>
      </Row>

      {/* <Row className="px-3 mt-4">
        <TableProject />
      </Row> */}
      <Modal show={show} onHide={() => setShow(false)}>
        <SowDetails
          brand={brand?.project_title}
          setShow={setShow}
          logo={brand?.brand_logo}
          flag={true}
        />
      </Modal>
    </div>
  );
}

export default ProjectStats;
