import React, { createContext, useEffect, useState } from "react";
import { contextData } from "./statesInitializer";
import { useNavigate } from "react-router-dom";
import "./App.css";
import { token_extract } from "./utils";
import axios from "axios";
import RouterComponent from "./components/routes/RouterComponent";
import { dataToken, token } from "./components/config/token";

export const Context = createContext();
function App() {
  const [context, setContext] = useState({ ...contextData });
  const [login, setLogin] = useState(false);
  const [navData, setNav] = useState({ id: "", full_name: "", email_id: "" });
  const navigate = useNavigate();

  const params = window.location.pathname.replace("/", "");

  useEffect(() => {
    console.log(token);
    if (token !== "null") {
      axios
        .get(token_extract, dataToken)
        .then((res) => {
          // console.log(res.data.mep_access, "res.data.mep_access");
          if (!res.data.error && res.data.mep_access == "true") {
            setLogin(true);
            setNav(res.data);
            context.navData = res.data;
            setContext({ ...context });
            switch (params) {
              case `${params}`:
                navigate(`/${params}`);
                break;
              default:
                navigate("/dashboard");
            }
          } else {
            setLogin(false);
            localStorage.removeItem("vendor-token");
            localStorage.removeItem("type");
            navigate("/login");
          }
        })
        .catch((err) => {
          setLogin(false);
          localStorage.removeItem("vendor-token");
          localStorage.removeItem("type");
          navigate("/login");
        });
    } else {
      setLogin(false);
      localStorage.removeItem("vendor-token");
      localStorage.removeItem("type");
      navigate("/login");
    }
  }, []);
  return (
    <Context.Provider value={[context, setContext]}>
      <div className="App">
        <RouterComponent login={login} navData={navData} setLogin={setLogin} />
      </div>
    </Context.Provider>
  );
}

export default App;
