/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import "../css/Profile.css";
import axios from "axios";
import { ABHI_ONBOARD_API, cm_list, single_asm } from "../../utils";
import { useParams } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { edit_vendor } from "../../utils";
import Swal from "sweetalert2";
import "../css/PersonalDetails2.css";
import Modal from "react-bootstrap/Modal";
import Crop from "./Crop";
import { upload_image } from "../../utils";
import { dataToken } from "../config/token";
import moment from "moment";

function Profile() {
  const { id } = useParams();
  const [singleData, setSingleData] = useState({});
  const [image, setImage] = useState(true);
  const [edit, setEdit] = useState(true);
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [imageShow, setImageShow] = useState([]);
  const [cms, setCms] = useState([]);

  useEffect(() => {
    const dataEnter = {
      asm_id: id,
    };

    axios
      .post(single_asm, dataEnter, dataToken)
      .then((res) => {
        setSingleData(res.data.asm[0]);
        data["asm_id"] = id;
        setData(...data);
      })
      .catch((err) => {
        console.error(err);
      });
    axios
      .get(cm_list, dataToken)
      .then((res) => {
        setCms(res.data.cm);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleRefresh = (e) => {
    e.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataEnter = { ...data };

    if (
      data.pan_image ||
      data.aadhar_back_image ||
      data.aadhar_front_image ||
      data.profile_image
    ) {
      dataEnter.documentation_status = "none";
    } else if (data.msme_cert || data.gst_cert || data.reg_cert) {
      dataEnter.employment_status = "none";
    } else if (data.bank_statement_image) {
      dataEnter.bank_status = "none";
    }

    if (data.subscription_type == "gold") {
      dataEnter.commission = "30";
    } else if (data.subscription_type == "silver") {
      dataEnter.commission = "25";
    } else if (data.subscription_type == "bronze") {
      dataEnter.commission = "20";
    }
    setEdit(true);

    axios
      .patch(edit_vendor, dataEnter, dataToken)
      .then((res) => {
        setImage(true);
        if (res.data.error) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `${res.data.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Success",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: `${err.response.data.message}`,
        });
      });
  };

  const handleSubmit2 = (e, d) => {
    e.preventDefault();
    const dataEnter = { ...data };

    if (d == "documentation_status") {
      if (singleData.pan_image?.length <= 5) {
        alert("Please fill pan image");
      } else if (singleData.aadhar_back_image?.length <= 5) {
        alert("Please fill aadhar back image");
      } else if (singleData.aadhar_front_image?.length <= 5) {
        alert("Please fill aadhar front image");
      } else if (singleData.profile_image?.length <= 5) {
        alert("Please fill profile image");
      } else {
        dataEnter.documentation_status = "completed";
      }
    } else if (d == "professional_status") {
      dataEnter.professional_status = "completed";
    } else if (d == "personal_details_status") {
      dataEnter.personal_details_status = "completed";
    } else if (d == "employment_status") {
      if (singleData.msme_cert?.length <= 5) {
        alert("Please fill msme certificate");
      } else if (singleData.gst_cert?.length <= 5) {
        alert("Please fill gst certificate");
      } else if (singleData.reg_cert?.length <= 5) {
        alert("Please fill registeration certificate");
      } else {
        dataEnter.employment_status = "completed";
      }
    } else if (d == "bank_status") {
      if (singleData.bank_statement_image?.length <= 5) {
        alert("Please fill Bank Passbook Image");
      } else {
        dataEnter.bank_status = "completed";
      }
    }

    axios
      .patch(edit_vendor, dataEnter, dataToken)
      .then((res) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Success",
          showConfirmButton: false,
          timer: 1500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: `${err.response.data.message}`,
        });
      });
  };

  const handleOnboard = () => {
    const dataEnter = {
      asm_id: id,
    };

    axios
      .put(ABHI_ONBOARD_API, dataEnter)
      .then((res) => {
        // console.log(res.data, "res.data");
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Onboarded",
          showConfirmButton: false,
          timer: 1500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: `${err.response.data.message}`,
        });
      });
  };

  const handleUnonboard = (data) => {
    const dataEnter = {
      asm_id: id,
      onboard_status: "none",
    };
    const date = new Date();
    const created_on = moment(date)
      .utcOffset(330)
      .format("YYYY-MM-DD HH:mm:ss");

    if (data.onboarded_on !== "0000-00-00 00:00:00.000000") {
      dataEnter["reonboarded_on"] = created_on;
    }

    axios
      .patch(edit_vendor, dataEnter, dataToken)
      .then((res) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Success",
          showConfirmButton: false,
          timer: 1500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: `${err.response.data.message}`,
        });
      });
  };

  const fileUpload = (e) => {
    const fileSelect = document.getElementById("fileElem");
    const fileElem = document.getElementById("fileElem1");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem) {
          fileElem.click();
        }
      },
      false
    );
  };

  const fileUpload1 = (e) => {
    const fileSelect = document.getElementById("fileElem2");
    const fileElem2 = document.getElementById("fileElem3");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem2) {
          fileElem2.click();
        }
      },
      false
    );
  };
  const fileUpload2 = (e) => {
    const fileSelect = document.getElementById("fileElem4");
    const fileElem2 = document.getElementById("fileElem5");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem2) {
          fileElem2.click();
        }
      },
      false
    );
  };
  const fileUpload3 = (e) => {
    const fileSelect = document.getElementById("fileElem6");
    const fileElem2 = document.getElementById("fileElem7");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem2) {
          fileElem2.click();
        }
      },
      false
    );
  };

  const fileUpload4 = (e) => {
    const fileSelect = document.getElementById("fileElem8");
    const fileElem2 = document.getElementById("fileElem9");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem2) {
          fileElem2.click();
        }
      },
      false
    );
  };

  const fileUpload5 = (e) => {
    const fileSelect = document.getElementById("fileElem10");
    const fileElem2 = document.getElementById("fileElem11");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem2) {
          fileElem2.click();
        }
      },
      false
    );
  };

  const fileUpload6 = (e) => {
    const fileSelect = document.getElementById("fileElem12");
    const fileElem2 = document.getElementById("fileElem13");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem2) {
          fileElem2.click();
        }
      },
      false
    );
  };

  const fileUpload7 = (e) => {
    const fileSelect = document.getElementById("fileElem14");
    const fileElem2 = document.getElementById("fileElem15");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem2) {
          fileElem2.click();
        }
      },
      false
    );
  };

  const fileUpload8 = (e) => {
    const fileSelect = document.getElementById("fileElem16");
    const fileElem2 = document.getElementById("fileElem17");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem2) {
          fileElem2.click();
        }
      },
      false
    );
  };

  const imageUpload = (e) => {
    const dataEnter2 = {
      asm_id: id,
    };
    const axiosData = new FormData();
    axiosData.append("file", e.target.files[0]);
    axiosData.append("asm_id", imageShow[1]);
    axiosData.append("type", imageShow[2]);

    axios
      .post(upload_image, axiosData)
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Image Updated",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((e) => {
        Swal.fire({
          icon: "warning",
          text: "oops something went wrong!",
        });
      });
  };

  return (
    <div>
      <h2 className="header_profile">Vendor Details</h2>
      <Row className="d-flex justify-content-around">
        <Col md={5} className="d-flex justify-content-start">
          <Box sx={{ justifyContent: "flex-start" }} className="m-4">
            <img
              src={
                singleData?.profile_image?.length > 5 && image
                  ? singleData?.profile_image.substr(0, 4) === "http"
                    ? singleData.profile_image
                    : `https://app.backend.taskmo.co/asm_images/${singleData.profile_image}`
                  : window.location.origin + "/images/svg/default_profile.svg"
              }
              alt="ott"
              className="profile_edit"
            />

            {edit && image ? null : (
              <img
                src={window.location.origin + "/images/wrong.svg"}
                alt="ott"
                className="right_wrong"
                onClick={(e) => {
                  data.profile_image = "null";
                  setData({ ...data });
                  handleSubmit(e);
                }}
              />
            )}
          </Box>
        </Col>
        <Col md={5} className="d-flex justify-content-end align-items-center">
          {edit ? (
            <img
              src={window.location.origin + "/images/png/edit.png"}
              alt="ott"
              className="edit_icon"
              onClick={() => {
                setEdit(false);
              }}
            />
          ) : (
            <img
              src={window.location.origin + "/images/nonEdit.svg"}
              alt="ott"
              className="edit_icon"
              style={{ cursor: "no-drop" }}
            />
          )}
        </Col>
      </Row>
      <Form onSubmit={handleRefresh}>
        {/*Personal details*/}

        <Row className="d-flex justify-content-around">
          <Col sx={12} md={5}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>full name</Form.Label>
              <Form.Control
                type="text"
                defaultValue={singleData.full_name}
                onChange={(e) => {
                  data.full_name = e.target.value;
                  setData({ ...data });
                }}
                disabled={edit}
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={5}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>mobile number</Form.Label>

              <Form.Control
                type="text"
                defaultValue={singleData.mobile_number}
                disabled={edit}
                onChange={(e) => {
                  data.mobile_number = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="d-flex justify-content-around">
          <Col sx={12} md={5}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>email id</Form.Label>

              <Form.Control
                type="email"
                defaultValue={singleData.email_id}
                disabled={edit}
                onChange={(e) => {
                  data.email_id = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
          {edit ? (
            <Col sx={12} md={5}>
              <Form.Group className="mb-4" controlId="ControlInput">
                <Form.Label>DOB</Form.Label>

                <Form.Control
                  type="text"
                  defaultValue={singleData.dob_format}
                  disabled={true}
                />
              </Form.Group>
            </Col>
          ) : (
            <Col sx={12} md={5}>
              <Form.Group className="mb-4" controlId="ControlInput">
                <Form.Label>DOB</Form.Label>
                <Form.Control
                  type="date"
                  defaultValue={singleData.dob_format}
                  onChange={(e) => {
                    data.dob = e.target.value;
                    setData({ ...data });
                  }}
                />
              </Form.Group>
            </Col>
          )}
        </Row>
        <Row className="d-flex justify-content-around">
          <Col sx={12} md={5}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>pincode</Form.Label>

              <Form.Control
                type="text"
                defaultValue={singleData.pincode}
                disabled={edit}
                onChange={(e) => {
                  data.pincode = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
          {edit ? (
            <Col md={5} sx={12}>
              <Form.Group className="mb-4" controlId="ControlInput">
                <Form.Label>Type</Form.Label>

                <Form.Control
                  type="text"
                  placeholder={
                    singleData.user_type == "reg_vendor"
                      ? "Registered Vendor"
                      : singleData.user_type == "unreg_vendor"
                      ? "Unregistered Vendor"
                      : "TSM"
                  }
                  disabled={edit}
                />
              </Form.Group>
            </Col>
          ) : (
            <Col md={5} sx={12}>
              <Form.Group className="mb-4" controlId="ControlInput">
                <Form.Label>Type</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  size="lg"
                  onChange={(e) => {
                    data.user_type = e.target.value;
                    setData({ ...data });
                  }}
                  className={"drop_css"}
                  defaultValue={singleData.user_type}
                  required
                >
                  <option value="tsm">TSM</option>
                  <option value="reg_vendor">Registered Vendor</option>
                  <option value="unreg_vendor">Unregistered Vendor</option>
                </Form.Select>
              </Form.Group>
            </Col>
          )}
          {/* <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>city</Form.Label>

              <Form.Control
                type="text"
                placeholder={singleData.city}
                disabled={edit}
                onChange={(e) => {
                  data.city = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group> */}
        </Row>
        <Row className="d-flex justify-content-around">
          <Col md={5} sx={12}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Employment ID</Form.Label>

              <Form.Control
                type="text"
                defaultValue={singleData.employee_id}
                disabled={edit}
                onChange={(e) => {
                  data.employee_id = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
          {edit ? (
            <Col md={5} sx={12}>
              <Form.Group className="mb-4" controlId="ControlInput">
                <Form.Label>Membership Plan</Form.Label>

                <Form.Control
                  type="text"
                  placeholder={singleData.subscription_type}
                  disabled={edit}
                />
              </Form.Group>
            </Col>
          ) : (
            <Col md={5} sx={12}>
              <Form.Group className="mb-4" controlId="ControlInput">
                <Form.Label>Membership Plan</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  size="lg"
                  onChange={(e) => {
                    data.subscription_type = e.target.value;
                    setData({ ...data });
                  }}
                  className={"drop_css"}
                  defaultValue={singleData.subscription_type}
                  required
                >
                  <option>Select Type</option>
                  <option value="bronze">Bronze</option>
                  <option value="silver">Silver</option>
                  <option value="gold">Gold</option>
                </Form.Select>
              </Form.Group>
            </Col>
          )}
        </Row>
        {/*professional details*/}
        {/* <h2 className="header_profile"> professional details</h2> */}

        <Row className="d-flex justify-content-around">
          <Col sx={12} md={5}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>highest education</Form.Label>
              <Form.Control
                type="text"
                defaultValue={singleData.highest_education}
                disabled={edit}
                onChange={(e) => {
                  data.highest_education = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={5}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>experience</Form.Label>

              <Form.Control
                type="number"
                defaultValue={singleData.experience}
                disabled={edit}
                onChange={(e) => {
                  data.experience = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="d-flex justify-content-around">
          <Col sx={12} md={5}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>languages known</Form.Label>

              <Form.Control
                type="text"
                defaultValue={singleData.languages_known}
                disabled={edit}
                onChange={(e) => {
                  data.languages_known = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
          {edit ? (
            <Col md={5} sx={12}>
              <Form.Group className="mb-4" controlId="ControlInput">
                <Form.Label>Payment Terms</Form.Label>

                <Form.Control
                  type="text"
                  defaultValue={singleData.payment_integer}
                  disabled={edit}
                />
              </Form.Group>
            </Col>
          ) : (
            <Col md={5} sx={12}>
              <Form.Group className="mb-4" controlId="ControlInput">
                <Form.Label>Payment Terms</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  size="lg"
                  onChange={(e) => {
                    data.payment_integer = e.target.value;
                    setData({ ...data });
                  }}
                  className={"drop_css"}
                  defaultValue={singleData.payment_integer}
                  required
                >
                  <option>Select Type</option>
                  <option value="7 days">7 Days</option>
                  <option value="15 days">15 Days</option>
                  <option value="30 days">30 Days</option>
                </Form.Select>
              </Form.Group>
            </Col>
          )}
        </Row>
        {/* <Row className="d-flex justify-content-around">
          <Col sx={12} md={5}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>referral code</Form.Label>

              <Form.Control
                type="text"
                placeholder={singleData.referral_code}
                disabled={edit}
                onChange={(e) => {
                  data.referral_code = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={5}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>referral number</Form.Label>

              <Form.Control
                type="number"
                placeholder={singleData.ref_no}
                disabled={edit}
                onChange={(e) => {
                  data.ref_no = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row> */}

        {/* KYC Details*/}
        {/* <h2 className="header_profile">User KYC Details</h2> */}

        <Row className="d-flex justify-content-around">
          <Col sx={12} md={5}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>aadhar number</Form.Label>

              <Form.Control
                type="text"
                placeholder={
                  singleData.aadhar_number == "null"
                    ? " "
                    : singleData.aadhar_number
                }
                disabled={edit}
                onChange={(e) => {
                  data.aadhar_number = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={5}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>pan number</Form.Label>
              <Form.Control
                type="text"
                placeholder={
                  singleData.pan_number == "null" ? " " : singleData.pan_number
                }
                disabled={edit}
                onChange={(e) => {
                  data.pan_number = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="d-flex justify-content-around mb-4">
          <Col md={5}>
            <label className="label_image">Aadhar Front Image</label>
            <div
              className="container"
              style={edit ? { color: "#000" } : { background: "#fff" }}
            >
              <div className="image_name">
                {singleData.aadhar_front_image?.length <= 5 ? (
                  singleData.aadhar_front_image
                ) : (
                  <div className="d-flex">
                    <img
                      src={window.location.origin + "/images/svg/image.svg"}
                      alt="ott"
                      className="status_icon1"
                    />
                    Aadhar Front Image
                  </div>
                )}
              </div>
              {edit ? (
                <div></div>
              ) : singleData.aadhar_front_image?.length > 5 ? (
                <div>
                  <button
                    className="button_submit1"
                    onClick={() => {
                      setShow1(true);
                      setImageShow([
                        singleData.aadhar_front_image,
                        singleData.asm_id,
                        "aadhar_front_image",
                      ]);
                    }}
                  >
                    View
                  </button>
                </div>
              ) : (
                <div>
                  <input
                    type="file"
                    id="fileElem1"
                    accept="image/*"
                    style={{ display: "none" }}
                    name="aadhar_front_image"
                    onChange={(value) => {
                      imageUpload(value);
                    }}
                  />
                  <button
                    id="fileElem"
                    className="button_submit1"
                    onClick={() => {
                      setImageShow([
                        singleData.aadhar_front_image,
                        singleData.asm_id,
                        "aadhar_front_image",
                      ]);
                      fileUpload();
                    }}
                  >
                    Upload
                  </button>
                </div>
              )}
            </div>
          </Col>
          <Col md={5}>
            <label className="label_image">Aadhar Back Image</label>
            <div
              className="container"
              style={edit ? { color: "#000" } : { background: "#fff" }}
            >
              <div className="image_name">
                {singleData.aadhar_back_image?.length <= 5 ? (
                  singleData.aadhar_back_image
                ) : (
                  <div className="d-flex">
                    <img
                      src={window.location.origin + "/images/svg/image.svg"}
                      alt="ott"
                      className="status_icon1"
                    />
                    Aadhar Back Image
                  </div>
                )}
              </div>
              {edit ? (
                <div></div>
              ) : singleData.aadhar_back_image?.length > 5 ? (
                <div>
                  <button
                    className="button_submit1"
                    onClick={() => {
                      setShow1(true);
                      setImageShow([
                        singleData.aadhar_back_image,
                        singleData.asm_id,
                        "aadhar_back_image",
                      ]);
                    }}
                  >
                    View
                  </button>
                </div>
              ) : (
                <div>
                  <input
                    type="file"
                    id="fileElem3"
                    accept="image/*"
                    style={{ display: "none" }}
                    name="aadhar_back_image"
                    onChange={(value) => {
                      imageUpload(value);
                    }}
                  />
                  <button
                    id="fileElem2"
                    className="button_submit1"
                    onClick={() => {
                      setImageShow([
                        singleData.aadhar_back_image,
                        singleData.asm_id,
                        "aadhar_back_image",
                      ]);
                      fileUpload1();
                    }}
                  >
                    Upload
                  </button>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-around mb-4">
          <Col md={5}>
            <label className="label_image">PAN Front image</label>
            <div
              className="container"
              style={edit ? { color: "#000" } : { background: "#fff" }}
            >
              <div className="image_name">
                {singleData.pan_image?.length <= 5 ? (
                  singleData.pan_image
                ) : (
                  <div className="d-flex">
                    <img
                      src={window.location.origin + "/images/svg/image.svg"}
                      alt="ott"
                      className="status_icon1"
                    />
                    PAN Image
                  </div>
                )}
              </div>
              {edit ? (
                <div></div>
              ) : singleData.pan_image?.length > 5 ? (
                <div>
                  <button
                    className="button_submit1"
                    onClick={() => {
                      setShow1(true);
                      setImageShow([
                        singleData.pan_image,
                        singleData.asm_id,
                        "pan_image",
                      ]);
                    }}
                  >
                    View
                  </button>
                </div>
              ) : (
                <div>
                  <input
                    type="file"
                    id="fileElem5"
                    accept="image/*"
                    style={{ display: "none" }}
                    name="pan_image"
                    onChange={(value) => {
                      imageUpload(value);
                    }}
                  />
                  <button
                    id="fileElem4"
                    className="button_submit1"
                    onClick={(e) => {
                      setImageShow([
                        singleData.pan_image,
                        singleData.asm_id,
                        "pan_image",
                      ]);
                      fileUpload2(e);
                    }}
                  >
                    Upload
                  </button>
                </div>
              )}
            </div>
          </Col>
          <Col sx={12} md={5}>
            <label className="label_image">
              Bank Passbook / cancelled check image
            </label>
            <div
              className="container"
              style={edit ? { color: "#000" } : { background: "#fff" }}
            >
              <div className="image_name">
                {singleData.bank_statement_image?.length <= 5 ? (
                  singleData.bank_statement_image
                ) : (
                  <div className="d-flex">
                    <img
                      src={window.location.origin + "/images/svg/image.svg"}
                      alt="ott"
                      className="status_icon1"
                    />
                    Bank Passbook image
                  </div>
                )}
              </div>
              {edit ? (
                <div></div>
              ) : singleData.bank_statement_image?.length > 5 ? (
                <div>
                  <button
                    className="button_submit1"
                    onClick={() => {
                      setShow1(true);
                      setImageShow([
                        singleData.bank_statement_image,
                        singleData.asm_id,
                        "bank_statement_image",
                      ]);
                    }}
                  >
                    View
                  </button>
                </div>
              ) : (
                <div>
                  <input
                    type="file"
                    id="fileElem13"
                    accept="image/*"
                    style={{ display: "none" }}
                    name="bank_statement_image"
                    // value={singleData.bank_statement_image}
                    onChange={(value) => {
                      imageUpload(value);
                    }}
                  />
                  <button
                    id="fileElem12"
                    className="button_submit1"
                    onClick={() => {
                      setImageShow([
                        singleData.bank_statement_image,
                        singleData.asm_id,
                        "bank_statement_image",
                      ]);
                      fileUpload6();
                    }}
                  >
                    Upload
                  </button>
                </div>
              )}
            </div>
          </Col>
        </Row>

        <Row className="d-flex justify-content-around mb-4">
          {edit ? (
            <Col sx={12} md={5}>
              <Form.Group className="mb-4" controlId="ControlInput">
                <Form.Label>Sign Status</Form.Label>

                <Form.Control
                  type="text"
                  placeholder={
                    singleData.sign_status == "null"
                      ? " "
                      : singleData.sign_status
                  }
                  disabled={true}
                />
              </Form.Group>
            </Col>
          ) : (
            <Col md={5}>
              <Form.Label>Sign Status</Form.Label>
              <Form.Select
                aria-label="Default select example"
                size="lg"
                onChange={(e) => {
                  data.sign_status = e.target.value;
                  setData({ ...data });
                }}
                className={"drop_css"}
                defaultValue={singleData.sign_status}
                required
              >
                <option>Select Type</option>
                <option value="none">None</option>
                <option value="completed">Completed</option>
              </Form.Select>
            </Col>
          )}
          <Col sx={12} md={5}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Vertical</Form.Label>

              <Form.Control
                type="text"
                placeholder={singleData.vertical}
                disabled={true}
                style={{ textTransform: "uppercase" }}
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Employment Verification */}
        {/* <h2 className="header_profile">Billing Detials</h2> */}

        {/* <h2 className="header_profile">Bank Detials</h2> */}

        <Row className="d-flex justify-content-around">
          <Col sx={12} md={5}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>account holder name</Form.Label>

              <Form.Control
                type="text"
                placeholder={singleData.account_name}
                disabled={edit}
                onChange={(e) => {
                  data.account_name = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={5}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>account number</Form.Label>

              <Form.Control
                type="text"
                placeholder={singleData.account_number}
                disabled={edit}
                onChange={(e) => {
                  data.account_number = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="d-flex justify-content-around">
          <Col sx={12} md={5}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>bank name</Form.Label>

              <Form.Control
                type="text"
                placeholder={singleData.bank_name}
                disabled={edit}
                onChange={(e) => {
                  data.bank_name = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={5}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>ifsc code</Form.Label>

              <Form.Control
                type="text"
                placeholder={singleData.ifsc_code}
                disabled={edit}
                onChange={(e) => {
                  data.ifsc_code = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="d-flex justify-content-around">
          {edit ? (
            <Col md={5} sx={12}>
              <Form.Group className="mb-4" controlId="ControlInput">
                <Form.Label>City Manager</Form.Label>

                <Form.Control
                  type="text"
                  placeholder={singleData.cm_name}
                  disabled={edit}
                />
              </Form.Group>
            </Col>
          ) : (
            <Col sx={12} md={5}>
              <Form.Group className="mb-4" controlId="ControlInput">
                <Form.Label>City Manager</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  size="lg"
                  onChange={(e) => {
                    data.cm_id = e.target.value;
                    setData({ ...data });
                  }}
                  disabled={edit}
                  defaultValue={singleData.cm_id}
                  className={"drop_css"}
                >
                  <option>Select Type</option>
                  {cms.map((cm) => (
                    <option key={cm.cm_id} value={cm.cm_id}>
                      {cm.full_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          )}
          <Col sx={12} md={5}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>CM ID</Form.Label>

              <Form.Control
                type="text"
                placeholder={singleData.cm_id}
                disabled={true}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="d-flex justify-content-around">
          {/* <Col sx={12} md={5}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Main Team</Form.Label>
              
              <Form.Select
                aria-label="Default select example"
                size="lg"
                onChange={(e) => {
                  data.main_team = e.target.value;
                  setData({ ...data });
                }}
                className={"drop_css"}
                defaultValue={singleData.main_team}
              >
                <option value="Alpha">Alpha</option>
                <option value="Beta">Beta</option>
                <option value="Gamma">Gamma</option>
              </Form.Select>
            </Form.Group>
          </Col> */}
          {edit ? (
            <Col md={5} sx={12}>
              <Form.Group className="mb-4" controlId="ControlInput">
                <Form.Label>Main Team</Form.Label>

                <Form.Control
                  type="text"
                  placeholder={singleData.main_team}
                  disabled={edit}
                />
              </Form.Group>
            </Col>
          ) : (
            <Col md={5} sx={12}>
              <Form.Group className="mb-4" controlId="ControlInput">
                <Form.Label>Main Team</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  size="lg"
                  onChange={(e) => {
                    data.main_team = e.target.value;
                    setData({ ...data });
                  }}
                  className={"drop_css"}
                  defaultValue={singleData.main_team}
                >
                  <option value="">Select Team</option>
                  <option value="Alpha">Alpha</option>
                  <option value="Beta">Beta</option>
                  <option value="Gamma">Gamma</option>
                </Form.Select>
              </Form.Group>
            </Col>
          )}

          <Col sx={12} md={5}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Sub Team</Form.Label>

              <Form.Control
                type="text"
                defaultValue={singleData.sub_team}
                disabled={edit}
                onChange={(e) => {
                  data.sub_team = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="d-flex justify-content-around">
          <Col sx={12} md={5}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Unit Team</Form.Label>

              <Form.Control
                type="text"
                defaultValue={singleData.unit_team}
                disabled={edit}
                onChange={(e) => {
                  data.unit_team = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>

          <Col sx={12} md={5}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Unit Id</Form.Label>

              <Form.Control
                type="text"
                defaultValue={singleData.unit_id}
                disabled={edit}
                onChange={(e) => {
                  data.unit_id = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>

        {singleData.user_type == "reg_vendor" ? (
          <>
            <Row className="d-flex justify-content-around">
              <Col sx={12} md={5}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>company name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={singleData.company_name}
                    disabled={edit}
                    onChange={(e) => {
                      data.company_name = e.target.value;
                      setData({ ...data });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col sx={12} md={5}>
                <Form.Group className="mb-4" controlId="ControlInput">
                  <Form.Label>GST number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={singleData.gst_number}
                    disabled={edit}
                    onChange={(e) => {
                      data.gst_number = e.target.value;
                      setData({ ...data });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="d-flex justify-content-around mb-4">
              <Col sx={12} md={5}>
                <label className="label_image">MSME certificate</label>
                <div
                  className="container"
                  style={edit ? { color: "#000" } : { background: "#fff" }}
                >
                  <div className="image_name">
                    {singleData.msme_cert?.length <= 5 ? (
                      singleData.msme_cert
                    ) : (
                      <div className="d-flex">
                        <img
                          src={window.location.origin + "/images/svg/doc.svg"}
                          alt="ott"
                          className="status_icon1"
                        />
                        MSME certificate
                      </div>
                    )}
                  </div>
                  {edit ? (
                    <div></div>
                  ) : singleData.msme_cert?.length > 5 ? (
                    <div>
                      <button
                        className="button_submit1"
                        onClick={() => {
                          setShow2(true);
                          setImageShow([
                            singleData.msme_cert,
                            singleData.asm_id,
                            "msme_cert",
                          ]);
                        }}
                      >
                        View
                      </button>
                    </div>
                  ) : (
                    <div>
                      <input
                        type="file"
                        id="fileElem9"
                        style={{ display: "none" }}
                        name="msme_cert"
                        onChange={(value) => {
                          imageUpload(value);
                        }}
                      />
                      <button
                        id="fileElem8"
                        className="button_submit1"
                        onClick={() => {
                          setImageShow([
                            singleData.msme_cert,
                            singleData.asm_id,
                            "msme_cert",
                          ]);
                          fileUpload4();
                        }}
                      >
                        Upload
                      </button>
                    </div>
                  )}
                </div>
              </Col>
              <Col sx={12} md={5}>
                <label className="label_image">GST certificate</label>
                <div
                  className="container"
                  style={edit ? { color: "#000" } : { background: "#fff" }}
                >
                  <div className="image_name">
                    {singleData.gst_cert?.length <= 5 ? (
                      singleData.gst_cert
                    ) : (
                      <div className="d-flex">
                        <img
                          src={window.location.origin + "/images/svg/doc.svg"}
                          alt="ott"
                          className="status_icon1"
                        />
                        GST certificate
                      </div>
                    )}
                  </div>
                  {edit ? (
                    <div></div>
                  ) : singleData.gst_cert?.length > 5 ? (
                    <div>
                      <button
                        className="button_submit1"
                        onClick={() => {
                          setShow2(true);
                          setImageShow([
                            singleData.gst_cert,
                            singleData.asm_id,
                            "gst_cert",
                          ]);
                        }}
                      >
                        View
                      </button>
                    </div>
                  ) : (
                    <div>
                      <input
                        type="file"
                        id="fileElem11"
                        style={{ display: "none" }}
                        name="gst_cert"
                        onChange={(value) => {
                          imageUpload(value);
                        }}
                      />
                      <button
                        id="fileElem10"
                        className="button_submit1"
                        onClick={() => {
                          setImageShow([
                            singleData.gst_cert,
                            singleData.asm_id,
                            "gst_cert",
                          ]);
                          fileUpload5();
                        }}
                      >
                        Upload
                      </button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>

            <Row className="d-flex justify-content-around">
              <Col md={5}>
                <label className="label_image">
                  Registeration certificate image
                </label>
                <div
                  className="container"
                  style={edit ? { color: "#000" } : { background: "#fff" }}
                >
                  <div className="image_name">
                    {singleData.reg_cert?.length <= 5 ? (
                      singleData.reg_cert
                    ) : (
                      <div className="d-flex">
                        <img
                          src={window.location.origin + "/images/svg/doc.svg"}
                          alt="ott"
                          className="status_icon1"
                        />
                        Registeration certificate image
                      </div>
                    )}
                  </div>
                  {edit ? (
                    <div></div>
                  ) : singleData.reg_cert?.length > 5 ? (
                    <div>
                      <button
                        className="button_submit1"
                        onClick={() => {
                          setShow2(true);
                          setImageShow([
                            singleData.reg_cert,
                            singleData.asm_id,
                            "reg_cert",
                          ]);
                        }}
                      >
                        View
                      </button>
                    </div>
                  ) : (
                    <div>
                      <input
                        type="file"
                        id="fileElem7"
                        style={{ display: "none" }}
                        name="reg_cert"
                        onChange={(value) => {
                          imageUpload(value);
                        }}
                      />
                      <button
                        id="fileElem6"
                        className="button_submit1"
                        onClick={() => {
                          setImageShow([
                            singleData.reg_cert,
                            singleData.asm_id,
                            "reg_cert",
                          ]);
                          fileUpload3();
                        }}
                      >
                        Upload
                      </button>
                    </div>
                  )}
                </div>
              </Col>
              <Col md={5} sx={12}></Col>
            </Row>
          </>
        ) : (
          <></>
        )}
        <Row className="d-flex justify-content-end my-4">
          {!edit ? (
            <button
              className="button_submit"
              type="submit"
              onClick={handleSubmit}
            >
              Save
            </button>
          ) : (
            <button className="button_submit" onClick={() => setShow(true)}>
              Finish
            </button>
          )}
        </Row>
      </Form>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        style={{
          display: "flex",
          justifyContent: "space-between",
          direction: "column",
        }}
      >
        <div
          className="add_page"
          style={{ width: "900px", height: "80vh", paddingBottom: "20px" }}
        >
          <div
            style={{ marginTop: "40px" }}
            className="model_status_container"
            onClick={(e) => handleSubmit2(e, "personal_details_status")}
          >
            <h3 className="h3_model_status">Personal verification Status</h3>
            {singleData.personal_details_status !== "completed" ? (
              <img
                src={window.location.origin + "/images/wrong.svg"}
                alt="ott"
                className="status_icon"
              />
            ) : (
              <img
                src={window.location.origin + "/images/right.svg"}
                alt="ott"
                className="status_icon"
              />
            )}
          </div>
          <div
            className="model_status_container"
            onClick={(e) => handleSubmit2(e, "professional_status")}
          >
            <h3 className="h3_model_status">
              Professional verification Status
            </h3>
            {singleData.professional_status !== "completed" ? (
              <img
                src={window.location.origin + "/images/wrong.svg"}
                alt="ott"
                className="status_icon"
              />
            ) : (
              <img
                src={window.location.origin + "/images/right.svg"}
                alt="ott"
                className="status_icon"
              />
            )}
          </div>
          {singleData.user_type == "reg_vendor" ? (
            <div
              className="model_status_container"
              onClick={(e) => handleSubmit2(e, "employment_status")}
            >
              <h3 className="h3_model_status">Company verification Status</h3>
              {singleData.employment_status !== "completed" ? (
                <img
                  src={window.location.origin + "/images/wrong.svg"}
                  alt="ott"
                  className="status_icon"
                />
              ) : (
                <img
                  src={window.location.origin + "/images/right.svg"}
                  alt="ott"
                  className="status_icon"
                />
              )}
            </div>
          ) : (
            <></>
          )}
          <div
            className="model_status_container"
            onClick={(e) => handleSubmit2(e, "documentation_status")}
          >
            <h3 className="h3_model_status">KYC verification Status</h3>
            {singleData.documentation_status !== "completed" ? (
              <img
                src={window.location.origin + "/images/wrong.svg"}
                alt="ott"
                className="status_icon"
              />
            ) : (
              <img
                src={window.location.origin + "/images/right.svg"}
                alt="ott"
                className="status_icon"
              />
            )}
          </div>
          <div
            className="model_status_container"
            onClick={(e) => handleSubmit2(e, "bank_status")}
          >
            <h3 className="h3_model_status">Bank verification Status</h3>
            {singleData.bank_status !== "completed" ? (
              <img
                src={window.location.origin + "/images/wrong.svg"}
                alt="ott"
                className="status_icon"
              />
            ) : (
              <img
                src={window.location.origin + "/images/right.svg"}
                alt="ott"
                className="status_icon"
              />
            )}
          </div>
          <label className="label_image">Contract Agreement *</label>
          <div className="container" style={{ background: "#fff" }}>
            <div className="image_name">
              {singleData.contract_agreement?.length <= 5 ? (
                singleData.contract_agreement
              ) : (
                <div className="d-flex">
                  <img
                    src={window.location.origin + "/images/svg/image.svg"}
                    alt="ott"
                    className="status_icon1"
                  />
                  Contract Agreement
                </div>
              )}
            </div>
            {singleData.contract_agreement?.length > 5 ? (
              <div>
                <button
                  className="button_submit1"
                  onClick={() => {
                    setShow2(true);
                    setImageShow([
                      singleData.contract_agreement,
                      singleData.asm_id,
                      "contract_agreement",
                    ]);
                  }}
                >
                  View
                </button>
              </div>
            ) : (
              <div className="d-flex">
                <input
                  type="file"
                  id="fileElem15"
                  style={{ display: "none" }}
                  name="contract_agreement"
                  onChange={(value) => {
                    imageUpload(value);
                  }}
                />
                <button id="fileElem14" className="button_submit1">
                  <a
                    href={`https://spapp.backend.taskmo.co/agreement/downloadcontractagreement?asm_id=${singleData.asm_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#fff", textDecoration: "none" }}
                  >
                    Download
                  </a>
                </button>

                <div>
                  <input
                    type="file"
                    id="fileElem17"
                    style={{ display: "none" }}
                    name="contract_agreement"
                    onChange={(value) => {
                      imageUpload(value);
                    }}
                  />
                  <button
                    id="fileElem16"
                    className="button_submit1"
                    onClick={() => {
                      setImageShow([
                        singleData.contract_agreement,
                        singleData.asm_id,
                        "contract_agreement",
                      ]);
                      fileUpload8();
                    }}
                  >
                    Upload
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {singleData.onboard_status == "onboarded" ? (
          <div className="button_space">
            <button
              className={
                singleData.onboard_status == "onboarded"
                  ? "button_submit"
                  : "false_button"
              }
              style={{ marginRight: "30px" }}
              onClick={() => handleUnonboard(singleData)}
            >
              Reonboard
            </button>
          </div>
        ) : (
          <div className="button_space">
            <button
              className={
                singleData.user_type == "reg_vendor"
                  ? singleData.personal_details_status == "completed" &&
                    singleData.professional_status == "completed" &&
                    singleData.employment_status == "completed" &&
                    singleData.bank_status == "completed" &&
                    singleData.documentation_status == "completed" &&
                    singleData.onboard_status != "onboarded"
                    ? "onboard_button"
                    : "false_button"
                  : singleData.personal_details_status == "completed" &&
                    singleData.professional_status == "completed" &&
                    singleData.bank_status == "completed" &&
                    singleData.documentation_status == "completed" &&
                    singleData.onboard_status != "onboarded"
                  ? "onboard_button"
                  : "false_button"
              }
              style={{ marginRight: "30px" }}
              onClick={handleOnboard}
            >
              Onboard
            </button>
          </div>
        )}
      </Modal>
      <Modal
        show={show1}
        onHide={() => setShow1(false)}
        style={{
          display: "flex",
          justifyContent: "space-between",
          direction: "column",
        }}
      >
        <div
          className="add_page"
          style={{ width: "900px", height: "90vh", paddingBottom: "20px" }}
        >
          <img
            src={
              imageShow[0]?.length > 5
                ? imageShow[0].substr(0, 4) === "http"
                  ? imageShow[0]
                  : `https://app.backend.taskmo.co/asm_images/${imageShow[0]}`
                : window.location.origin + "/images/certificate.png"
            }
            alt="ott"
            className="modal_image"
          />
          <div className="d-flex justify-content-around">
            <button
              className="button_remove"
              onClick={(e) => {
                data[imageShow[2]] = "null";
                setData({ ...data });
                handleSubmit(e);
                setShow1(false);
              }}
            >
              Remove
            </button>
            <Crop
              img={imageShow[0]}
              lead_id={imageShow[1]}
              type={imageShow[2]}
              btn_name={"crop"}
              setShow1={setShow1}
            />
          </div>
        </div>
      </Modal>
      <Modal
        show={show2}
        onHide={() => setShow2(false)}
        style={{
          display: "flex",
          justifyContent: "space-between",
          direction: "column",
        }}
      >
        <div
          className="add_page"
          style={{ width: "900px", height: "90vh", paddingBottom: "20px" }}
        >
          <iframe
            src={imageShow[0]}
            title={imageShow[2]}
            className="modal_image"
          ></iframe>
          <div className="d-flex justify-content-around">
            <button
              className="button_remove"
              onClick={(e) => {
                data[imageShow[2]] = "null";
                setData({ ...data });
                handleSubmit(e);
                setShow2(false);
              }}
            >
              Remove
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Profile;
