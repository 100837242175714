import axios from "axios";
import React from "react";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { gender_fse } from "../../utils";
import { dataToken } from "../config/token";
import "../css/MerchantOtp.css";
export const GetGenderModal = ({ setShow, fse }) => {
  const [data, setData] = React.useState({});
  const handleChangeGender = (e) => {
    const { name, value } = e.target;
    if (value == "") return;
    setData({ [name]: value });
  };
  const handleSubmit = () => {
    // console.log(data);
    data["fse"] = fse;
    // console.log(data, "datadata");
    axios
      .post(gender_fse, data, dataToken)
      .then((res) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `success`,
          showConfirmButton: false,
          timer: 1500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: `${err.response.data.message}`,
        });
      });
    setData({});
  };
  return (
    <div className="headerModalgender">
      <div className="d-flex align-items-center">
        <span className="heading_addsp mb-4">Select Gender</span>
        {/* <span className="sow_css_right">{sowId[0]}</span> */}
      </div>
      {/* <label htmlFor="select_label" className="label_add_sow">
        select Gender
      </label> */}
      <select
        style={{ width: "40%", heigth: "10px" }}
        className="select_download"
        name="gender"
        onChange={handleChangeGender}
        required
      >
        <option value="">select Gender</option>

        <option value={"male"}>Male</option>
        <option value={"female"}>Female</option>
      </select>

      <Button className="gendermodalbutton" size="small" onClick={handleSubmit}>
        submit
      </Button>
    </div>
  );
};
