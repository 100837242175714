import React from "react";
import FseTable from "../Table/FseTable";

function Fse() {
  return (
    <div style={{ padding: "20px", marginTop: "30px" }}>
      <FseTable />
    </div>
  );
}

export default Fse;
