import { Avatar, Button, Grid, IconButton, Tooltip } from "@mui/material";

import React from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { qcbulkUpload } from "../../utils";
import { CSVLink } from "react-csv";
import DownloadIcon from '@mui/icons-material/Download';
import axios from "axios";

const LeadApproved = () => {
  const [userData, setUserData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [datadown,setDataDown] = React.useState(false)
  const [isErr, setIsErr] = React.useState(false);
  const hiddenFileInput = React.useRef(null);
  const handleClickuploadtraining = () => {
    hiddenFileInput.current.click();
  };
  const handleChangeuploadtraining = (e) => {
    console.log(e.target.files[0], "files000");
    var formData = new FormData();
    formData.append("file", e.target.files[0]);
    setIsLoading(true)
    axios
      .put(qcbulkUpload, formData)
      .then((res) => {
        console.log(res.data.users, "testing");
        setUserData(res?.data?.users);
        setIsLoading(false)
        setDataDown(true)
        //   window.location.reload();
      })
      .catch((err) => {setIsErr(true)
        setTimeout(()=>{
            window.location.reload()
        },1000)
    })
      .finally(()=>{setIsLoading(false)})
  };
  return isLoading ? (
    <>...loading</>
  ) : isErr ? (
    <>something went wrong</>
  ) : (
    <Grid container justifyContent={"space-between"}>
      <Grid item>Lead Status Approved</Grid>
      <Grid item>
       

       {!datadown && <Tooltip title="Upload">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input
              hidden
              
              type="file"
              htmlFor="lablelab"
              style={{ display: " none" }}
              onChange={handleChangeuploadtraining}
              ref={hiddenFileInput}
            />
            <FileUploadIcon />
          </IconButton >
        </Tooltip>}

        {datadown && <div>
            <CSVLink
                data={userData}
                filename={"my-file.csv"}
                className="down_load_btn  mt-2"
                target="_blank"
              >
                <Avatar
                  sx={{ bgcolor: "black", fontSize: "12px", cursor: "pointer" }}
                  style={{ background: "#663595", cursor: "pointer" }}
                >
                  <DownloadIcon />
                </Avatar>
              </CSVLink>
            </div>}
      </Grid>
      
    </Grid>
  );
};

export default LeadApproved;
