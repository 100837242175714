import { RemoveFromQueue } from "@mui/icons-material";
import axios from "axios";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { create_payment_request, generate_lead_count } from "../../utils";
import { dataToken } from "../config/token";

const PayoutsDataModal = ({ setShow, callApiupdated, setCallApiUpdated }) => {
  const [data, setData] = useState({});
  const [totalLeadsApi, setTotalLeadsApi] = React.useState("");
  const [cplApi, setCplApi] = React.useState("");
  const invoiceId = localStorage.getItem("invoiceId");
  const [showDates, setShowDates] = React.useState(true);
  const [noOfusers, setNoOfusers] = React.useState("");
  const { id } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(e);
  };
  const handleGenerate = (e) => {
    // console.log(data);
    data.sow_id = id;
    var check = Object.keys(data);
    if (check.includes("sow_id", "start_date", "end_date")) {
      //   console.log("got inside");
      axios
        .post(generate_lead_count, data, dataToken)
        .then((res) => {
          setShowDates(false);
          // console.log(res.data);
          if (res.data.total_leads) {
            setTotalLeadsApi(res.data.total_leads);
            setNoOfusers(res.data.users);
            data.leads = res.data.total_leads;
            setData({ ...data });
          } else {
            setTotalLeadsApi(0);
          }
          if (res.data.cpl) {
            setCplApi(res.data.cpl);
            data.cpl = res.data.cpl;
            setData({ ...data });
          } else {
            setCplApi(0);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const handleclearAll = (e) => {
    data.leads = "";
    data.cpl = "";
    setTotalLeadsApi("");
    setCplApi("");

    setShowDates(true);
  };

  const handleAdd = (e) => {
    e.preventDefault();
    data.invoice_id = 0;
    data.type = "auto_payment";
    data.users = noOfusers;

    axios
      .post(create_payment_request, data, dataToken)
      .then((res) => {
        setShow(false);
        // console.log(res.data.error, "checkerror");
        if (res.data.error) {
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: `${res.data.message}`,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "success",
            title: `success`,
            showConfirmButton: false,
            timer: 1500,
          });
          setCallApiUpdated(!callApiupdated);
          //   setTimeout(() => {
          //     window.location.reload();
          //   }, 1500);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <span className="add_heading"> Add Payout Details</span>
      <Form onSubmit={handleSubmit}>
        <Row className="mt-5">
          {/* <Col sx={12} md={6}> */}
          {/* <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>SOW ID</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => {
                  data.sow_id = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group> */}
          {/* </Col> */}
          <Col sx={12} md={4}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Payment Start Date</Form.Label>
              <Form.Control
                disabled={!showDates}
                min={"2022-12-12"}
                type="date"
                onChange={(e) => {
                  data.start_date = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={4}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Payment End Date</Form.Label>
              <Form.Control
                disabled={!showDates}
                min={data.start_date}
                type="date"
                onChange={(e) => {
                  data.end_date = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={2}>
            <Button
              style={{ marginTop: "55px", float: "right" }}
              onClick={handleclearAll}
            >
              Clear All
            </Button>
          </Col>
          <Col sx={12} md={2}>
            <Button
              style={{ marginTop: "55px", float: "right" }}
              onClick={handleGenerate}
            >
              Generate
            </Button>
          </Col>
        </Row>

        <Row>
          <Col sx={12} md={4}>
            <Form.Group className="mb-4 " controlId="ControlInput">
              <Form.Label>No Of Leads</Form.Label>
              <Form.Control
                defaultValue={totalLeadsApi}
                type="text"
                disabled
                onChange={(e) => {
                  data.leads = e.target.value;
                  setData({ ...data });
                }}
                required
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={4}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Cost per Item</Form.Label>
              <Form.Control
                defaultValue={cplApi}
                type="text"
                onChange={(e) => {
                  data.cpl = e.target.value;
                  setData({ ...data });
                }}
                required
                disabled
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={4}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Total Cost</Form.Label>
              <Form.Control
                type="text"
                // value={data.cpl * data.leads}
                value={Number(cplApi) * Number(totalLeadsApi)}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>No of users</Form.Label>
              <Form.Control type="text" value={noOfusers} disabled />
            </Form.Group>
          </Col>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Remarks</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => {
                  data.req_remark = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="d-flex justify-content-end">
          <Button
            className={"button_submit btn_pos"}
            type="submit"
            style={{ marginRight: "50px" }}
            onClick={handleAdd}
            disabled={
              Object.keys(data).includes(
                "sow_id",
                "start_date",
                "end_date",
                "leads",
                "cpl",
                "invoice_id",
                "users"
              ) && !showDates
                ? false
                : true
            }
          >
            ADD
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default PayoutsDataModal;
