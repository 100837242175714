export const localRoute = {
  notFound: "*",
  dashboard: "/dashboard",
  superpartner: "/superpartner",
  tsm: "/tsm",
  profile: "/profile",
  fse: "/fse",
  sow: "/sow",
  editsow: '/editsow',
  training: '/training',
  sows: "/oa-sow",
  view_sow: "/view-sow",
  project: "/projects",
  login: "/login",
  id: "/:id",
  id2: "/:id2",
  id3: "/:id3",
  oaAssociate: "/oa-associate"
};
