import React from "react";
import { Route, Routes } from "react-router-dom";
// import Login from "../Login/login";
import Login from "../Login/Number";
import Sidebar from "../sidebar/Side";
import NavBar from "../Navbar/NavBar";
import Dashboard from "../Pages/Dashboard";
import Profile from "../Pages/Profile";
import Fse from "../Pages/Fse";
import Home from "../Pages/Home";
import Sow from "../Pages/Sow";
import ScrollToTop from "../assets/scrollToTop";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

import "../css/Dashboard.css";
import { purple } from "@mui/material/colors";
import { localRoute } from "./localRoutes";
import PageNotFound from "../Pages/PageNotFound";
import { token_type } from "../config/token";
import ProjectStats from "../Pages/ProjectStats";
import OaSows from "../Pages/OaSows";
import EditSowPage from "../Pages/EditSowPage";
import Training from "../Pages/Training";
import OaAssociate from "../Pages/OaAssociate";

function RouterComponent(props) {
  const toTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction = () => {
    const element = document.getElementById("back-to-top");
    if (element) {
      if (
        document.body.scrollTop > 130 ||
        document.documentElement.scrollTop > 130
      ) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  };
  return (
    <div>
      {props.login ? (
        <div className="dashboard_container">
          <ScrollToTop />

          <div style={{ display: "flex", minHeight: "100vh" }}>
            <div style={{ marginTop: "0px" }}>
              <Sidebar />
            </div>
          </div>
          <div style={{ width: "88.5%", marginLeft: "180px" }}>
            <button onClick={() => toTop()} id="back-to-top">
              <KeyboardDoubleArrowUpIcon sx={{ color: purple[800] }} />
            </button>
            <NavBar navData={props.navData} />
            <Routes>
              <Route exact path={localRoute.dashboard} element={<Home />} />
              <Route
                exact
                path={localRoute.superpartner}
                element={<Dashboard />}
              />
              <Route
                exact
                path={localRoute.sows + localRoute.id}
                element={<OaSows />}
              />
              <Route
                exact
                path={localRoute.view_sow + localRoute.id + localRoute.id2}
                element={<ProjectStats />}
              />
              {token_type == "hr" || token_type == "all" ? (
                <>
                  <Route exact path={localRoute.tsm} element={<Dashboard />} />
                  <Route
                    exact
                    path={localRoute.profile + localRoute.id}
                    element={<Profile />}
                  />
                </>
              ) : (
                <></>
              )}
              <Route
                exact
                path={localRoute.fse + localRoute.id}
                element={<Fse />}
              />
              <Route exact path={localRoute.sow} element={<Sow />} />
              <Route
                path={localRoute.project + localRoute.id}
                element={<ProjectStats />}
              />
              <Route
                path={localRoute.sow + localRoute.editsow + localRoute.id3}
                element={<EditSowPage />}
              />
              <Route path={localRoute.oaAssociate} element={<OaAssociate />} />

              <Route
                exact
                path={localRoute.notFound}
                element={<PageNotFound />}
              />
              <Route exact path={localRoute.training} element={<Training />} />
            </Routes>
          </div>
        </div>
      ) : (
        <>
          <Routes>
            <Route
              exact
              path={localRoute.login}
              element={<Login setLoggedIn={props.setLogin} />}
            />
          </Routes>
        </>
      )}
    </div>
  );
}

export default RouterComponent;
