import React, { useState, useContext } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import "./Table.css";
import DataTableExtensions from "react-data-table-component-extensions";
import { Link } from "react-router-dom";
import axios from "axios";
import { tsm_list, change_vendor, asm_list, oa_asm_list } from "../../utils";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Modal from "react-bootstrap/Modal";
import InactiveMember from "../Pages/InactiveMember";
import Swal from "sweetalert2";
import { Context } from "../../App";
import { dataToken, token_type } from "../config/token";
import { useNavigate } from "react-router-dom";

createTheme("VendorTable", {
  text: {
    primary: "black",
    secondary: "#b2b2b2",
  },
  background: {
    default: "#fff",
  },
  boxshadow: {
    default: "5px 5px 30px #DEDEDEBF",
  },
  headCells: {
    default: "#64abdd",
  },
  divider: {
    default: "#f4f4f4",
  },
  cell: {
    style: {
      height: "60px !important",
    },
  },

  action: {
    button: "rgba(0,0,0,.54)",
    hover: "#f5f2fd !important",
    disabled: "rgba(0,0,0,.12)",
  },
});

function Table({ asm, navData, setAsm }) {
  const [show, setShow] = useState(false);
  const [options_open, set_options_open] = useState(false);
  const [userData, setUserData] = useState([false]);
  const filter = ["all", "new", "active", "inactive"];
  const [context, setContext] = useContext(Context);
  const navigate = useNavigate();
  const params = window.location.pathname.replace("/", "");

  const handleMoveVendor = (e) => {
    const dataEnter = {
      asm_id: e.asm_id,
    };

    if (params !== "tsm") {
      dataEnter["status"] = "tsm";
    } else {
      dataEnter["status"] = "unreg_vendor";
    }

    Swal.fire({
      title: "Are you sure you want to move?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(change_vendor, dataEnter, dataToken)
          .then((res) => {
            if (res.data.error) {
              Swal.fire({
                position: "center",
                icon: "error",
                title: `${res.data.message}`,
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              Swal.fire({
                position: "center",
                icon: "success",
                title: `Updated`,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              icon: "error",
              title: "Oops!",
              text: `${err.response.data.message}`,
            });
          });
      }
    });
  };

  const handleFilter = (e) => {
    const dataEntery = {
      status: e,
    };

    axios
      .post(
        (token_type == "oh" || token_type == "hr" || token_type == "all") &&
          params == "superpartner"
          ? asm_list
          : (token_type == "oh" || token_type == "hr" || token_type == "all") &&
            params == "tsm"
          ? tsm_list
          : oa_asm_list,
        dataEntery,
        dataToken,
        dataEntery,
        dataToken
      )
      .then((res) => {
        context.asm = res.data;

        setContext({ ...context });
        set_options_open(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleInactive = (data) => {
    setShow(true);
    setUserData(data);
  };

  const columns = [
    {
      name: "ASM ID",
      selector: (d) => d.asm_id,
      sortable: true,
      center: true,
      omit: true,
      cell: (d) => <div>{d.asm_id}</div>,
    },
    {
      name: "ASM AADHAR NUMBER",
      selector: (d) => d.aadhar_number,
      sortable: true,
      center: true,
      omit: true,
      cell: (d) => <div>{d.aadhar_number}</div>,
    },
    {
      name: "Profile",
      selector: (d) => d.profile_image,
      sortable: true,
      center: true,
      width: "300px",
      cell: (d) => (
        <div className="d-flex">
          <div>
            <img
              src={
                d.profile_image.length > 5
                  ? d.profile_image.substr(0, 4) === "http"
                    ? d.profile_image
                    : `https://app.backend.taskmo.co/asm_images/${d.profile_image}`
                  : window.location.origin + "/images/svg/default_profile.svg"
              }
              alt="profile"
              className="profile_table_images"
            />
          </div>
          <div className="name_box">
            <p className="name">{d.full_name}</p>
            <p className="doj">{d.doj}</p>
          </div>
        </div>
      ),
    },
    {
      name: "Number",
      selector: (d) => d.mobile_number,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.mobile_number}</div>,
      outerrWidth: 100,
    },
    {
      name: "Email ID",
      selector: (d) => d.email_id,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.email_id}</div>,
      innerWidth: 100,
    },
    {
      name: "City",
      selector: (d) => d.city,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.city}</div>,
      innerWidth: 100,
    },
    {
      name: "Type",
      selector: (d) => d.user_type,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.user_type}</div>,
    },
    {
      name: "CM ID",
      selector: (d) => d.cm_id,
      sortable: true,
      omit: true,
      center: true,
      cell: (d) => <div>{d.cm_id}</div>,
    },
    {
      name: "CM Name",
      selector: (d) => d.cm_name,
      sortable: true,
      center: true,
      cell: (d) => <div>{d.cm_name}</div>,
    },
    {
      name: "Status",
      selector: (d) => d.onboard_status,
      sortable: true,
      center: true,
      cell: (d) => (
        <div className="d-flex">
          <div className={`status_ball ${d.onboard_status}`}></div>
          <p style={{ margin: "auto" }}>
            {d.onboard_status == "onboarded"
              ? "Active"
              : d.onboard_status == "inactive"
              ? "Inactive"
              : d.onboard_status == "none"
              ? "New"
              : ""}
          </p>
        </div>
      ),
    },
    {
      name: "Options",
      center: true,
      width: "100px",
      cell: (d) => (
        <div>
          <DropdownButton
            as={ButtonGroup}
            id={`dropdown-button-drop-start`}
            drop={"start"}
            variant="secondary"
            title={``}
          >
            {token_type == "hr" || token_type == "all" ? (
              <>
                <Dropdown.Item
                  eventKey="0"
                  onClick={() => navigate(`/profile/${d.asm_id}`)}
                >
                  Profile
                </Dropdown.Item>
                <Dropdown.Item eventKey="2" onClick={() => handleInactive(d)}>
                  {d.onboard_status == "onboarded" ? "Inactive" : "Active"}
                </Dropdown.Item>
                <Dropdown.Item eventKey="4" onClick={() => handleMoveVendor(d)}>
                  Move Vendor
                </Dropdown.Item>
              </>
            ) : (
              <></>
            )}
            {/* <Dropdown.Item
              eventKey="1"
              onClick={() => navigate(`/fse/${d.asm_id}`)}
            >
              view team
            </Dropdown.Item> */}

            <Dropdown.Item
              eventKey="4"
              onClick={() => navigate(`/oa-sow/${d.asm_id}`)}
            >
              View Sow's
            </Dropdown.Item>
          </DropdownButton>
        </div>
      ),
    },
  ];
  // if (token_type == "hr" || token_type == "all") {
  //   columns.splice(10, 0, {
  //     name: "Options",
  //     center: true,
  //     width: "100px",
  //     cell: (d) => (
  //       <div>
  //         <DropdownButton
  //           as={ButtonGroup}
  //           id={`dropdown-button-drop-start`}
  //           drop={"start"}
  //           variant="secondary"
  //           title={``}
  //         >
  //           <Dropdown.Item
  //             eventKey="0"
  //             onClick={() => navigate(`/profile/${d.asm_id}`)}
  //           >
  //             Profile
  //           </Dropdown.Item>
  //           <Dropdown.Item
  //             eventKey="1"
  //             onClick={() => navigate(`/fse/${d.asm_id}`)}
  //           >
  //             view team
  //           </Dropdown.Item>

  //           <Dropdown.Item eventKey="2" onClick={() => handleInactive(d)}>
  //             {d.onboard_status == "onboarded" ? "Inactive" : "Active"}
  //           </Dropdown.Item>
  //           {/* <Dropdown.Item eventKey="3">Remap</Dropdown.Item> */}
  //           <Dropdown.Item eventKey="4" onClick={() => handleMoveVendor(d)}>
  //             Move Vendor
  //           </Dropdown.Item>
  //           <Dropdown.Item
  //             eventKey="4"
  //             onClick={() => navigate(`/oa-sow/${d.asm_id}`)}
  //           >
  //             View Sow's
  //           </Dropdown.Item>
  //         </DropdownButton>
  //       </div>
  //     ),
  //   });
  // }
  const tableData = {
    columns,
    data: context.asm?.users,
  };
  // console.log(asm.users, "asm add vendor");
  return (
    <div className="table_data">
      <div className="card_table">
        <div className="menu-container">
          <div className="menu-container">
            {options_open ? (
              <div className="filter_options">
                <ul className="ul_filter">
                  {filter.map((fill, index) => (
                    <li
                      key={index}
                      className="li_filter"
                      name={fill}
                      onClick={() => handleFilter(fill)}
                    >
                      <div className={`status_ball1 ${fill}`}></div>
                      {fill}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <></>
            )}
            <div
              className="filter_box"
              onClick={() => set_options_open(!options_open)}
            >
              <img
                src={window.location.origin + "/images/svg/filter.svg"}
                alt="profile"
                className="filter_box_image"
              />
              <p className="filter_name">Filter</p>
            </div>
          </div>
        </div>
        <DataTableExtensions
          {...tableData}
          export={false}
          filterPlaceholder={`Search`}
        >
          <DataTable
            columns={columns}
            data={tableData}
            defaultSortFieldId={1}
            pagination
            theme="VendorTable"
            highlightOnHover={true}
          />
        </DataTableExtensions>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <div className="add_page">
          <InactiveMember userData={userData} setShow={setShow} />
        </div>
      </Modal>
    </div>
  );
}

export default Table;
