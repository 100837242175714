import React, { useContext, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import "./Table.css";
import DataTableExtensions from "react-data-table-component-extensions";
import axios from "axios";
import { current_month_sow, oa_current_sow, sow_email_otp } from "../../utils";
import Modal from "react-bootstrap/Modal";
import EditSow from "../Pages/EditSow";
import { dataToken, token_type } from "../config/token";
import { Context } from "../../App";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { useLocation, useNavigate } from "react-router-dom";
import AddSow from "../Pages/AddSow";
import SowDetails from "../Pages/SowDetails";
import AddSowSp from "../Pages/AddSowSp";
import MerchantOtp from "../Pages/MerchantOtp";
import AddTraining from "../Pages/AddTraining";
import CrmAccess from "../Pages/CrmAccess";
import Badge from "react-bootstrap/Badge";

createTheme("VendorTable", {
  text: {
    primary: "black",
    secondary: "#b2b2b2",
  },
  background: {
    default: "#fff",
  },
  boxshadow: {
    default: "5px 5px 30px #DEDEDEBF",
  },
  headCells: {
    default: "#64abdd",
  },
  divider: {
    default: "#f4f4f4",
  },
  cell: {
    style: {
      height: "60px !important",
    },
  },

  action: {
    button: "rgba(0,0,0,.54)",
    hover: "#f5f2fd !important",
    disabled: "rgba(0,0,0,.12)",
  },
});
function TableSow() {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [sowId, setSowId] = useState([]);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [sowData, setSowData] = useState("");
  const [options_open, set_options_open] = useState(false);
  const [filter, setFilter] = useState(["enable", "disable", "new", "closed"]);
  const [context, setContext] = useContext(Context);
  const [sowId1, setSowId1] = useState(null);
  const [status, setStatus] = useState(null);
  const [pid, setPid] = useState(null);
  const navigate = useNavigate();

  const handleEdit = (e) => {
    setShow(true);
    setSowData(e);
  };

  const handleSowDetails = (e) => {
    setShow2(true);
    setSowData(e);
  };
  const callAddTrainingModal = (id) => {
    setSowId1(id);
    setShow5(true);
  };
  const callCRMAccess = (projectId) => {
    setPid(projectId);
    setShow6(true);
  };
  const callOtpFunction = (id, leadStatus) => {
    const postData = {
      sow_id: id,
    };
    axios
      .post(sow_email_otp, postData, dataToken)
      .then((res) => {
        // console.log(res.data, "res.data");
        setShow3(true);
        setSowId1(id);
        setStatus(leadStatus);
      })
      .catch((err) => console.log(err.message));
  };
  const handleFilter = (e) => {
    if (token_type == "oh" || token_type == "all" || token_type == "hr") {
      set_options_open(false);
      const dataEntery = current_month_sow + `?add_lead_status=${e}`;
      // console.log(dataEntery, "dataEntery");

      axios
        .get(dataEntery, dataToken)
        .then((res) => {
          context.sow = res.data;
          // console.log(res.data, "sow");

          setContext({ ...context });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      set_options_open(false);
      const dataEntery = oa_current_sow + `?add_lead_status=${e}`;
      axios
        .get(dataEntery, dataToken)
        .then((res) => {
          // setSow(res.data);
          // console.log(res.data, "sow");
          context.sow = res.data;
          setContext({ ...context });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const columns = [
    {
      name: "S-ID",
      selector: (d) => d.sow_id,
      sortable: true,
      center: true,
      width: "80px",
      cell: (d) => <div>{d.sow_id}</div>,
    },
    // {
    //   name: "P-Id",
    //   selector: (d) => d.project_id,
    //   sortable: true,
    //   center: true,
    //   width: "80px",
    //   cell: (d) => <div>{d.project_id}</div>,
    // },
    // {
    //   name: "Vertical",
    //   selector: (d) => d.job_category,
    //   sortable: true,
    //   center: true,
    //   cell: (d) => (
    //     <div style={{ textTransform: "uppercase" }}>
    //       {d.job_category.replace("_", "")}
    //     </div>
    //   ),
    //   outerrWidth: 100,
    // },

    {
      name: "Logo",
      selector: (d) => d.brand_logo,
      sortable: true,
      center: true,
      cell: (d) => (
        <div>
          <img
            src={d.brand_logo}
            alt="profile"
            className="profile_table_images"
          />
          <div>{d.brand_name}</div>
        </div>
      ),
    },
    {
      name: "Project Title",
      selector: (d) => d.project_title,
      sortable: true,
      center: true,
      // width: "230px",
      cell: (d) => <div>{d.project_title}</div>,
    },
    // {
    //   name: "Brand Name",
    //   selector: (d) => d.brand_name,
    //   sortable: true,
    //   center: true,
    //   cell: (d) => <div>{d.brand_name}</div>,
    // },

    // {
    //   name: "Sow Status",
    //   selector: (d) => d.add_lead_status,
    //   sortable: true,
    //   center: true,
    //   cell: (d) => <div>{d.add_lead_status}</div>,
    //   outerrWidth: 100,
    // },
    // {
    //   name: "Date",
    //   selector: (d) => d.start_date,
    //   sortable: true,
    //   center: true,
    //   width: "230px",
    //   cell: (d) => (
    //     <div className="name_box">
    //       <p className="date_name">SD :{d.start_date}</p>
    //       <p className="date_name">ED :{d.end_date}</p>
    //     </div>
    //   ),
    // },

    {
      name: "Total Leads",
      selector: (d) => d.total_leads,
      sortable: true,
      center: true,
      cell: (d) => (
        <div
          style={{
            color: "#673695",
            fontWeight: "600",
            fontSize: "18px",
            marginBottom: "-5px",
          }}
        >
          {d.total_leads}
          <div
            style={{ fontSize: "12px", marginTop: "-2px", color: "#7b96ae" }}
          >
            +{d.total_leads_today}
          </div>
        </div>
      ),
    },
    {
      name: "Approved Leads",
      selector: (d) => d.approved_leads,
      sortable: true,
      center: true,
      cell: (d) => (
        <div
          style={{
            color: "#673695",
            fontWeight: "600",
            fontSize: "18px",
            marginBottom: "-5px",
          }}
        >
          {d.approved_leads}
          <div
            style={{ fontSize: "12px", marginTop: "-2px", color: "#7b96ae" }}
          >
            +{d.approved_leads_today}
          </div>
        </div>
      ),
    },
    {
      name: "App Status",
      selector: (d) => d.app_view_status,
      sortable: true,
      center: true,
      // width: "230px",
      cell: (d) => (
        <div>
          {d.app_view_status == "none" ? (
            <Badge
              pill
              style={{
                width: "50px",
                border: "1px solid LightGray",
                color: "#595757",
              }}
              bg="light"
            >
              {d.app_view_status}
            </Badge>
          ) : d.app_view_status == "show" ? (
            <Badge
              pill
              style={{
                width: "50px",
                border: "1px solid green",
                color: "green",
              }}
              bg="light"
            >
              {d.app_view_status}
            </Badge>
          ) : (
            <Badge
              pill
              style={{
                width: "50px",
                border: "1px solid #FFA500",
                color: "#FFA500",
              }}
              bg="light"
            >
              {d.app_view_status}
            </Badge>
          )}
        </div>
      ),
    },
    {
      name: "Netword Status",
      selector: (d) => d.enable_for,
      sortable: true,
      center: true,
      // width: "230px",
      cell: (d) => (
        <div>
          {d.enable_for == "open_network" ? (
            <Badge
              pill
              style={{
                width: "50px",
                border: "1px solid green",
                color: "green",
              }}
              bg="light"
            >
              open
            </Badge>
          ) : d.enable_for == "closed_network" ? (
            <Badge
              pill
              style={{
                width: "50px",
                border: "1px solid red",
                color: "red",
              }}
              bg="light"
            >
              close
            </Badge>
          ) : (
            <Badge
              pill
              style={{
                width: "50px",
                border: "1px solid LightGray",
                color: "#595757",
              }}
              bg="light"
            >
              none
            </Badge>
          )}{" "}
        </div>
      ),
    },
    {
      name: "Action",
      sortable: true,
      center: true,
      cell: (d) => (
        <div>
          <DropdownButton
            as={ButtonGroup}
            id={`dropdown-button-drop-start`}
            drop={"start"}
            variant="secondary"
            title={``}
          >
            {location.pathname.split("/")[1] === "sow" && (
              <>
                <Dropdown.Item
                  eventKey="6"
                  onClick={() => callCRMAccess(d.project_id)}
                >
                  CRM Access
                </Dropdown.Item>
              </>
            )}
            {/* <Dropdown.Item
              eventKey="5"
              onClick={() => callAddTrainingModal(d.sow_id)}
            >
              AddTraining
            </Dropdown.Item> */}
            {location.pathname.split("/")[1] === "sow" && (
              <>
                <Dropdown.Item
                  disabled={location.pathname.split("/")[1] === "training"}
                  eventKey="4"
                  // onClick={() =>
                  //   handleSowDetails([d.project_title, d.sow_id, d.brand_logo])
                  // }
                  // onClick={() => navigate(`/profile/${d.asm_id}`)}
                  onClick={() => callOtpFunction(d.sow_id, d.add_lead_status)}
                >
                  {d.add_lead_status === "enable" ? "Close" : "Activate"}
                </Dropdown.Item>

                <Dropdown.Item
                  disabled={location.pathname.split("/")[1] === "training"}
                  eventKey="2"
                  onClick={() =>
                    handleSowDetails([d.project_title, d.sow_id, d.brand_logo])
                  }
                  // onClick={() => navigate(`/profile/${d.asm_id}`)}
                >
                  Sow Details
                </Dropdown.Item>
                {token_type == "oh" ||
                token_type == "all" ||
                token_type == "ol" ||
                token_type == "oa" ? (
                  <>
                    <Dropdown.Item
                      disabled={location.pathname.split("/")[1] === "training"}
                      eventKey="0"
                      // onClick={() => handleEdit(d.sow_id)}
                      // onClick={() => navigate(`/profile/${d.asm_id}`)}
                      onClick={() => navigate(`/sow/editsow/${d.sow_id}`)}
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      disabled={location.pathname.split("/")[1] === "training"}
                      eventKey="3"
                      onClick={() => {
                        setSowId([d.sow_id, d.project_id]);
                        setShow4(true);
                      }}
                    >
                      Add SP
                    </Dropdown.Item>
                  </>
                ) : (
                  <></>
                )}
                <Dropdown.Item
                  disabled={location.pathname.split("/")[1] === "training"}
                  eventKey="1"
                  onClick={() => navigate(`/projects/${d.sow_id}`)}
                >
                  SOW Earnings
                </Dropdown.Item>
              </>
            )}
          </DropdownButton>
        </div>
      ),
    },
  ];
  // if (token_type == "oh" || token_type == "all") {
  //   columns.splice(10, 0, {
  //     name: "Options",
  //     center: true,
  //     width: "100px",
  //     cell: (d) => (
  //       <div>
  //         <DropdownButton
  //           as={ButtonGroup}
  //           id={`dropdown-button-drop-start`}
  //           drop={"start"}
  //           variant="secondary"
  //           title={``}
  //         >
  //           <Dropdown.Item
  //             eventKey="2"
  //             onClick={() =>
  //               handleSowDetails([d.project_title, d.sow_id, d.brand_logo])
  //             }
  //             // onClick={() => navigate(`/profile/${d.asm_id}`)}
  //           >
  //             Sow Details
  //           </Dropdown.Item>
  //           <Dropdown.Item
  //             eventKey="0"
  //             onClick={() => handleEdit(d.sow_id)}
  //             // onClick={() => navigate(`/profile/${d.asm_id}`)}
  //           >
  //             Edit
  //           </Dropdown.Item>
  //           <Dropdown.Item
  //             eventKey="1"
  //             onClick={() => navigate(`/projects/${d.sow_id}`)}
  //           >
  //             SOW Earnings
  //           </Dropdown.Item>
  //           <Dropdown.Item
  //             eventKey="3"
  //             onClick={() => {
  //               setSowId(d.sow_id);
  //               setShow3(true);
  //             }}
  //           >
  //             Add SP
  //           </Dropdown.Item>
  //         </DropdownButton>
  //       </div>
  //     ),
  //   });
  // }

  const tableData = {
    columns:
      location.pathname.split("/")[1] === "training"
        ? columns.splice(0, 6).concat(columns[columns.length - 1])
        : columns,
    data:
      location.pathname.split("/")[1] === "training"
        ? context?.training?.data
        : context?.sow?.data,
  };
  // console.log(tableData.data, "columns");
  return (
    <div className="table_data">
      <div className="card_table">
        <div className="menu-container">
          {options_open ? (
            <div className="filter_options" style={{ marginRight: "12rem" }}>
              <ul className="ul_filter">
                {filter.map((fill, index) => (
                  <li
                    key={index}
                    className="li_filter"
                    name={fill}
                    onClick={() => handleFilter(fill === "new" ? "none" : fill)}
                  >
                    <div
                      className={`status_ball1 ${fill}`}
                      style={{ marginLeft: "0" }}
                    ></div>
                    {fill}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <></>
          )}
          {location.pathname.split("/")[1] === "sow" && (
            <div className="add_box">
              <button className="button_blue1" onClick={() => setShow1(true)}>
                <img
                  src={window.location.origin + "/images/add.svg"}
                  alt="ott"
                  className="button_add"
                />
                Add
              </button>
            </div>
          )}
          {location.pathname.split("/")[1] === "sow" && (
            <div
              className="filter_box"
              onClick={() => set_options_open(!options_open)}
            >
              <img
                src={window.location.origin + "/images/svg/filter.svg"}
                alt="profile"
                className="filter_box_image"
              />
              <p className="filter_name">Filter</p>
            </div>
          )}
        </div>
        <DataTableExtensions
          {...tableData}
          export={false}
          filterPlaceholder={`Search`}
        >
          <DataTable
            columns={columns}
            data={tableData}
            defaultSortFieldId={3}
            pagination
            theme="VendorTable"
            highlightOnHover={true}
          />
        </DataTableExtensions>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <div className="add_page">
          <EditSow sow={sowData} />
        </div>
      </Modal>
      <Modal show={show1} onHide={() => setShow1(false)}>
        <div className="add_page">
          <AddSow />
        </div>
      </Modal>
      <Modal show={show2} onHide={() => setShow2(false)}>
        <SowDetails
          brand={sowData[0]}
          setShow={setShow2}
          sow_id={sowData[1]}
          logo={sowData[2]}
          flag={false}
        />
      </Modal>
      <Modal show={show3} onHide={() => setShow3(false)}>
        <MerchantOtp
          hideData={true}
          sowId={sowId1}
          setShow3={setShow3}
          status={status}
        />
      </Modal>
      <Modal show={show4} onHide={() => setShow4(false)}>
        <AddSowSp sowId={sowId} setShow={setShow4} />
      </Modal>
      <Modal show={show5} onHide={() => setShow5(false)}>
        <div className="add_page2">
          <AddTraining sid={sowId1} />
        </div>
      </Modal>
      <Modal show={show6} onHide={() => setShow6(false)}>
        <div className="add_page">
          <CrmAccess pid={pid} />
        </div>
      </Modal>
    </div>
  );
}

export default TableSow;
