import axios from "axios";
import { Interweave } from "interweave";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { vendor_sow_details } from "../../utils";
import { dataToken } from "../config/token";
import "../css/OaSows.css";
import FseTable from "../Table/FseTable";
import SpProfile from "./SpProfile";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TableSowAsm from "./TableSowAsm";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function OaSows() {
  const [data, setData] = useState([]);
  const param = useParams();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const postData = {
      asm_id: param.id,
    };
    axios
      .post(vendor_sow_details, postData, dataToken)
      .then((res) => {
        setData(res.data.sow);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  return (
    <>
      <SpProfile />
      <div className="total_sow_css">
        {/* <Row className="mt-5 ml-3 sow_card_scroll">
          {data?.map((res, index) => {
            return (
              <Col xs={4} key={index}>
                <div className="card_total_css">
                  <div className="d-flex mt-3 head_sow_css">
                    <div className="d-flex align-items-center">
                      <img
                        src={res.brand_logo}
                        alt="ott"
                        className="sow_logo mx-3"
                      />
                      <p className="text-capatilize m-0 sow_head_css">
                        {res.project_title}
                      </p>
                    </div>
                    <span className="sow_css_right">{res.sow_id}</span>
                  </div>
                  <div className="d-flex mt-4">
                    <Row className="row_space_evnly">
                      <Col xs={4}>
                        <div className="d-flex flex-column align-items-center">
                          <div className="d-flex">
                            <img
                              src={
                                window.location.origin + "/images/svg/sow3.svg"
                              }
                              alt="ott"
                              className=" "
                            />
                            <span className="text-capatilize m-0 border_bg">
                              {res.team}
                            </span>
                          </div>
                          <span className="head_base_css">
                            Assigned Network
                          </span>
                        </div>
                      </Col>
                      <Col xs={4}>
                        <div className="d-flex flex-column">
                          <div className="d-flex">
                            <img
                              src={
                                window.location.origin + "/images/svg/sow1.svg"
                              }
                              alt="ott"
                              className=" "
                            />
                            <span className="text-capatilize m-0 border_bg">
                              &#8377;{res.earned}
                            </span>
                          </div>
                          <span className="head_base_css"> Network earned</span>
                        </div>
                      </Col>
                      <Col xs={4}>
                        <div className="d-flex flex-column">
                          <div className="d-flex">
                            <img
                              src={
                                window.location.origin + "/images/svg/sow2.svg"
                              }
                              alt="ott"
                              className=" "
                            />
                            <span className="text-capatilize m-0 border_bg">
                              &#8377;{res.comission}
                            </span>
                          </div>
                          <span className="head_base_css">
                            Commission earned
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="d-flex flex-column mt-4">
                    <span className="about_text_css">About Project:</span>
                    <div className="desc_tect_css">
                      <Interweave content={res.project_desc} />
                    </div>
                    <span
                      className="view_css"
                      onClick={() =>
                        navigate(`/view-sow/${res.sow_id}/${param.id}`)
                      }
                    >
                      View More
                    </span>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row> */}

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Sow" {...a11yProps(0)} />
              <Tab label="FSE List" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <TableSowAsm tableColumnData={data} asmId={param.id}/>
          </TabPanel>
          <TabPanel value={value} index={1}>
          <FseTable/>
          </TabPanel>
        </Box>

        
      </div>
    </>
  );
}

export default OaSows;
